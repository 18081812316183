
import {
  defineComponent, Ref, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Dialog from 'primevue/dialog';
import SpmTable from '@/components/table/SpmTable.vue';
import FileUpload from 'primevue/fileupload';
import Badge from 'primevue/badge';
import { useToast } from 'primevue/usetoast';
import { SpmTableColumns, SpmTableState } from '@/types';
import {
  ExportResult,
  isTemplateDefinition,
  isUsersPagesConfigurationDefinition,
} from '@/types/product-import-types';
import Loader from '@/components/layout/Loader.vue';
// import { UserState } from '@/composables/User';
import { nestPost } from '@/composables/nestApi';
import { formatFileSize } from '@/helpers/Files';

export default defineComponent({
  name: 'ImportModal',

  components: {
    SpmButton,
    SpmTable,
    FileUpload,
    Dialog,
    Badge,
    Loader,
  },

  props: {
    header: {
      type: String,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },

    importType: {
      type: String,
      required: true,
    },

    idUser: {
      type: Number,
      required: false,
    },

    userType: {
      type: String,
      required: false,
    },
  },

  emits: [
    'close-action-modal',
  ],

  setup(props, context: SetupContext) {
    const { t, locale } = useI18n();
    const dialogVisible = ref<boolean>(props.showModal);
    const toast = useToast();
    const files: Ref<File | undefined> = ref();
    const fileStr = ref('');
    const tableData = ref<SpmTableState>();
    const isDataTableVisible = ref(false);
    const componentRef = ref();
    const selectedRowIds = ref();
    const loadingViewData = ref(false);
    const loadingImportData = ref(false);
    // const userId = UserState.user.id;
    // const { userType } = UserState.user;

    const templatesOnlyColumns: SpmTableColumns[] = [
      {
        field: 'id',
        header: '',
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        hidden: true,
      },
      {
        field: 'name',
        header: t('templates.import.columns.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:50%',
        type: 'text',
      },
      {
        field: 'type',
        header: t('templates.import.columns.type'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:20%',
        type: 'text',
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'uid',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'display: none',
        type: 'text',
      },
      {
        field: 'name',
        header: props.importType === 'campaign' ? t('campaigns.bulk.list.columns.name') : t('automatedScenarios.nom_scenario'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:90%',
        type: 'text',
      },
    ];

    const updateDialogVisibility = (needReload = false) => {
      dialogVisible.value = false;
      context.emit('close-action-modal', needReload);
    };

    const updateDataTableVisibility = async (visible: boolean) => {
      isDataTableVisible.value = visible;
    };

    const onSelectedFiles = (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: t('success'),
          detail: t('myLists.importContacts.errors.maxSizeError', ['700000000']),
          life: 3000,
        });
      }
    };

    const onTemplatedUpload = async (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: 'Upload error',
          life: 3000,
        });
      }

      // eslint-disable-next-line prefer-destructuring
      files.value = event.files[0];
    };

    const lookupExportFile = async () => {
      loadingViewData.value = true;
      const lookupExportState: { result: null | ExportResult; error: any } = {
        result: null,
        error: 'Aucun fichier renseigné.',
      };
      if (files.value) {
        const path = `/data/${props.importType}/lookup-export-file`;
        const formData = new FormData();
        formData.append('exportFile', files.value);
        try {
          const { result, error } = await nestPost(
            'data',
            path,
            {
              'Content-Type': 'multipart/form-data',
            },
            formData,
          );
          lookupExportState.result = result;
          lookupExportState.error = error;
        } catch (error: any) {
          lookupExportState.error = error;
        } finally {
          loadingViewData.value = false;
        }
      }
      return lookupExportState;
    };

    const proceedToViewData = async () => {
      const { result, error } = await lookupExportFile();

      if (error) {
        toast.add({
          severity: 'error',
          summary: t('error'),
          detail: t(`import.${error.message}`),
          life: 3000,
        });
      } else if (result && !error) {
        const items = result.elements.map(
          (element) => {
            const { definition } = element;
            if (isTemplateDefinition(definition)) {
              definition.name = definition.label;
            }
            if (isUsersPagesConfigurationDefinition(definition)) {
              try {
                const pageName = JSON.parse(definition.page_name);
                const pageNameValues = Object.values(pageName);
                const pageNameInLocale = pageName[locale.value];

                definition.name = pageNameInLocale || pageNameValues[0] || '';
              } catch (_: any) {
                definition.name = definition.uid;
              }
            }
            return definition;
          },
        );
        tableData.value = {
          items,
          total: items.length,
          error: '',
          selectedItems: items.map(({ uid }) => uid),
        };

        isDataTableVisible.value = true;
      }
    };

    const onRemoveTemplatingFile = () => {
      files.value = undefined;
      fileStr.value = '';
    };

    const processImport = async () => {
      loadingImportData.value = true;
      componentRef.value.sendSelectedRows();
      if (selectedRowIds.value && Array.isArray(selectedRowIds.value) && selectedRowIds.value.length !== 0 && files.value) {
        const path = `/data/${props.idShop}/${props.importType}/import`;
        const formData = new FormData();
        formData.append('exportFile', files.value);
        formData.set('uids', JSON.stringify(selectedRowIds.value));
        if (props.idUser) {
          formData.set('idUser', props.idUser.toString());
        }
        if (props.userType) {
          formData.set('userType', props.userType);
        }
        const { result, error } = await nestPost(
          'data',
          path,
          {
            'Content-Type': 'multipart/form-data',
          },
          formData,
        );
        if (result && !error) {
          toast.add({
            severity: 'success',
            summary: t('success'),
            detail: t('import.success'),
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: t('error'),
            detail: t('import.error'),
            life: 3000,
          });
        }

        updateDialogVisibility(true);
      } else {
        toast.add({
          severity: 'warn',
          summary: t('error'),
          detail: t('import.data.noSelection'),
          life: 3000,
        });
      }
      loadingImportData.value = false;
    };

    const setRowsSelected = (selectedIds: number[]) => {
      selectedRowIds.value = selectedIds;
    };

    return {
      t,
      dialogVisible,
      updateDialogVisibility,
      onSelectedFiles,
      onRemoveTemplatingFile,
      onTemplatedUpload,
      formatFileSize,
      files,
      tableData,
      columns,
      updateDataTableVisibility,
      isDataTableVisible,
      processImport,
      componentRef,
      setRowsSelected,
      templatesOnlyColumns,
      proceedToViewData,
      loadingViewData,
      loadingImportData,
    };
  },
});
