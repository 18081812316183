
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  Ref,
  watch,
} from 'vue';

import { useStore } from '@/store';
import {
  HistoryType,
  TemplateParentTypeEnum,
  TemplateStructureEnum,
  TemplateWidth,
} from '@/types/enums';
import { TEMPLATE_PAGE_IDENTIFIER, MEDIA_URL_PART_THUMB_GENERATION } from '@/components/template-builder/utils/constants';
import ConfigurationPanel from '@/components/template-builder/panels/ConfigurationPanel.vue';
import InformationPanel from '@/components/template-builder/panels/InformationPanel.vue';
import TranslationPanel from '@/components/template-builder/panels/TranslationPanel.vue';
import HistoryPanel from '@/components/template-builder/panels/HistoryPanel.vue';
import CssPanel from '@/components/template-builder/panels/CssPanel.vue';
import CssPanelMediaQueries from '@/components/template-builder/panels/CssPanelMediaQueries.vue';
import LoadSectionPanel from '@/components/template-builder/panels/LoadSectionPanel.vue';
import TemplateLiveView from '@/components/template-builder/TemplateLiveView.vue';
import InputTextModal from '@/components/modals/InputTextModal.vue';
import WidgetsPanel from '@/components/template-builder/panels/WidgetsPanel.vue';
import ColumnWidgetModal from '@/components/modals/ColumnWidgetModal.vue';
import AIModal from '@/components/modals/AIModal.vue';
import SectionWidgetModal from '@/components/modals/SectionWidgetModal.vue';
import useToggleBodyScrollBars from '@/composables/useToggleBodyScrollBars';
import {
  activate,
  checkIfPartExistsWithSameName,
  deactivate,
  hideConfigurationPanel,
  hideTemplateEditor,
  refreshSectionsConfig,
  refreshTranslations,
  rename,
  savePartInDatabase,
  saveTemplatesInLocalStorage,
  setRefreshTemplatesList,
  showConfigurationPanel,
  TemplateEditorState as state,
  toggleAdvancedMode,
  togglePanel,
  updateSectionsInState,
  sendTestNotification,
  getTestPushNotificationData,
  beforeSaveTemplate,
  saveTemplate,
  setTemplateConfigurationKeyInState,
  informationPanelSettingsValidate,
  updateLastRollbackGroupId,
  setTestEmailSent,
  resetNeedToTranslate,
  resetTemplateToDesktop,
  setTemplateWidth,
  refreshSmartProductsListsOriginalStructure,
  refreshAttributeInDOM,
  restoreSmartListsOriginalCode,
  sortTranslationInState,
  removeUnusedTranslations,
} from '@/composables/template-editor/TemplateEditor';

import EditorModal from '@/components/modals/EditorModal.vue';
import { PrimvueMenuModel } from '@/types';
import { useI18n } from 'vue-i18n';
import {
  getTemplateIframeDocument,
  isDisplayTemplate,
  isEmailTemplate,
  isFacebookTemplate,
  isPushTemplate,
  isSmsTemplate,
} from '@/components/template-builder/utils/helpers';
import { asInt, showToastError, showToastSuccess, generateSha1Hash } from '@/helpers';
import { getShopDefaultLang, UserState } from '@/composables/User';
import {
  createHistory,
  getClosestPointOfSave, getHistoryIndexFromGroupId,
  getLastPointOfSaveId,
  getTemplateHistoryGroupList,
  loadHistoryGroups,
} from '@/composables/template-editor/History';
import { TemplatesElementsHistoryGroup } from '@/types/generated-types/graphql';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import {
  EditingTemplateStatusEnum,
  getEditingTemplateFromCampaign,
  setEditingTemplateFromCampaign,
  setCampaignTemplateInformationFromBuilder,
} from '@/composables/AutomatedScenarios';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import { duplicateTemplate } from '@/composables/shop/Templates';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { resetListeners } from '@/components/template-builder/utils/listeners';
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import { removeActiveItemElementDecorations } from '@/components/template-builder/utils/active-item-builder';
import DuplicateModal from '@/components/modals/DuplicateModal.vue';
import { nestGet } from '@/composables/nestApi';
import { updateIframeTranslations, fixMissingTranslations } from './utils/translate';
import ChooseZipFileModal from '@/components/modals/ChooseZipFileModal.vue';

export default defineComponent({
  name: 'TemplateEditor',

  components: {
    DuplicateModal,
    TemplateSelector,
    SendTestModal,
    EditorModal,
    ConfigurationPanel,
    InformationPanel,
    TranslationPanel,
    HistoryPanel,
    CssPanel,
    CssPanelMediaQueries,
    LoadSectionPanel,
    TemplateLiveView,
    InputTextModal,
    WidgetsPanel,
    ConfirmDialog,
    ColumnWidgetModal,
    AIModal,
    SectionWidgetModal,
    ChooseZipFileModal,
  },

  setup() {
    const { t, locale } = useI18n();
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;

    const store = useStore();
    const confirm = useConfirm();
    const selectedTemplateId = computed(() => state.template?.id ?? 0);
    const selectedTemplateType = computed(() => state.template?.type ?? '');
    const selectedTemplateName = computed(() => state.template?.informations.name);
    const selectedTemplateIdCampaign = computed(() => state.template?.idCampaign ?? null);
    const selectedStructure = computed(() => store.getters['liveEditor/getSelectedStructure']);
    const isConfigurationPanelVisible = computed(() => state.isConfigurationPanelVisible);
    const isConfigurationPanelClosable = computed(() => selectedTemplateType.value !== TemplateParentTypeEnum.SMS
      && selectedTemplateType.value !== TemplateParentTypeEnum.PUSHNOTIFICATIONS);
    const isLeftToolbarVisible = computed(() => {
      let returnValue = false;
      if (state.isLeftToolbarVisible && (state.leftToolbar.show.configurationPanel || state.leftToolbar.show.designPanel) && selectedStructure.value) {
        returnValue = true;
      } else if (state.isLeftToolbarVisible && !state.leftToolbar.show.configurationPanel && !state.leftToolbar.show.designPanel) {
        returnValue = true;
      }

      return returnValue;
    });
    const isWidgetPanelClosable = computed(() => selectedTemplateType.value !== TemplateParentTypeEnum.EMAIL);
    const isTranslationPanelClosable = computed(() => !state.template?.informations.imported);
    const advancedModeEnabled = computed(() => state.advancedModeEnabled);
    const showTemplateSelector = ref(false);
    const showDuplicationModal = ref(false);

    const isChooseZipFileModalVisible = computed(() => store.getters['liveEditor/getIsChooseZipFileModalVisible']);

    const width = computed({
      get() {
        return state.templateWidth;
      },
      set(newWidth: string) {
        setTemplateWidth(newWidth);
      },
    });

    const historyList: Ref<TemplatesElementsHistoryGroup[]> = ref([]);
    const selectedHistory: Ref<TemplatesElementsHistoryGroup|null> = ref(null);
    const lastHistoryGroupId: ComputedRef<number|null> = computed(() => state.template?.configuration.lastIdTemplateElementsHistoryGroup);
    const currentHistoryGroupId: ComputedRef<number|null> = computed(() => state.template?.configuration.currentIdTemplateElementsHistoryGroup ?? lastHistoryGroupId.value);
    const firstHistoryGroupId: ComputedRef<number|null> = computed(() => {
      let lastHistoryPoint: TemplatesElementsHistoryGroup|null = null;

      if (historyList.value.length > 0) {
        lastHistoryPoint = historyList.value[historyList.value.length - 1];
      }

      return lastHistoryPoint?.id_template_elements_history_group ?? null;
    });

    const lastPointToRollback = ref();

    // key used to rerender live preview on history change
    const livePreviewKey = ref(0);

    const displayRenameTemplateModal: Ref<boolean> = ref(false);
    const valueRenameTemplateModal: Ref<string> = ref('');

    const displaySaveSectionModal: ComputedRef<boolean> = computed(() => store.state.liveEditor.saveSectionModalPayload.visible);
    const valueSaveSectionModal: Ref<string> = ref('');

    const informationPanelValidation = ref<any>({});
    const newListName = ref();

    /* Check if this template was edited from a campaign.d. */
    const openingFromCampaign: ComputedRef<boolean> = computed(() => selectedTemplateIdCampaign.value !== null
      && getEditingTemplateFromCampaign() !== null);

    const setLastHistory = (historyGroupId: number | null) => {
      if (historyGroupId) {
        const currentHistory: TemplatesElementsHistoryGroup[] = historyList.value
          .filter((history: TemplatesElementsHistoryGroup) => history.id_template_elements_history_group === historyGroupId);

        if (currentHistory.length) {
          [selectedHistory.value] = currentHistory;
        }
      }
    };

    const handleChangeHistory = async (index: number) => {
      const filteredHistoryList = historyList.value.slice(index); // delete history after index
      await loadHistoryGroups(filteredHistoryList);
      refreshSectionsConfig(JSON.parse(historyList.value[index].sections_order_after ?? '').map((id: string) => id.toString()));
      refreshTranslations();
      selectedHistory.value = historyList.value[index];
      setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', selectedHistory.value.id_template_elements_history_group);
      setLastHistory(selectedHistory.value.id_template_elements_history_group);
      livePreviewKey.value += 1;
    };

    const handlePreviousHistory = () => {
      const currentIndex = historyList.value
        .findIndex((current: TemplatesElementsHistoryGroup) => current.id_template_elements_history_group === selectedHistory.value?.id_template_elements_history_group);

      if (currentIndex === undefined) handleChangeHistory(0);
      // eslint-disable-next-line prefer-destructuring
      else if (currentIndex < (historyList.value.length - 1)) handleChangeHistory((currentIndex + 1));
    };

    const handleNextHistory = () => {
      const currentIndex = historyList.value
        .findIndex((current: TemplatesElementsHistoryGroup) => current.id_template_elements_history_group === selectedHistory.value?.id_template_elements_history_group);
      if (currentIndex) handleChangeHistory((currentIndex - 1));
    };

    useToggleBodyScrollBars();

    const prepareStructureConfiguration = () => {
      store.dispatch('liveEditor/prepareStructureConfiguration', {
        type: TemplateStructureEnum.PAGE,
        identifier: TEMPLATE_PAGE_IDENTIFIER,
      });
    };

    const getPageConfig = () => {
      if (isConfigurationPanelVisible.value && selectedStructure.value?.type === TemplateStructureEnum.PAGE) {
        hideConfigurationPanel();
        return;
      }
      prepareStructureConfiguration();
      showConfigurationPanel();
    };

    const handleSaveSectionClose = () => {
      store.commit('liveEditor/hideSaveSectionModal');
    };

    const toEraseSectionId = ref();

    const saveSection = async (sectionName: string) => {
      // We check if a template_part exists with the same name
      const activeItemSelector = computed(() => store.getters['liveEditor/getSaveSectionSelector']);
      const eraseId = await checkIfPartExistsWithSameName('section', sectionName, activeItemSelector.value);

      if (typeof eraseId === 'string' && eraseId !== '') {
        // Show error
        await showToastError(t(eraseId));
      } else if (eraseId) {

        toEraseSectionId.value = eraseId;

        // If used, we ask confirmation to erase the old one
        confirm.require({
          group: 'saveSection',
          message: t('templateBuilder.confirm.saveNewSection.duplicateName.message', [sectionName]),
          header: t('templateBuilder.confirm.saveNewSection.duplicateName.header'),
          icon: 'far fa-exclamation-triangle',
          acceptLabel: t('yes'),
          acceptClass: 'p-button-danger',
          rejectLabel: t('no'),
          rejectClass: 'p-button-secondary',
          accept: async () => {
            const newSection = await savePartInDatabase('section', sectionName, activeItemSelector.value, eraseId);
            store.commit('liveEditor/hideSaveSectionModal');
            store.dispatch('templateEditor/updateSection', { section: newSection });
            toEraseSectionId.value = null;
          },
          reject: () => {
            store.commit('liveEditor/hideSaveSectionModal');
            toEraseSectionId.value = null;
          },
        });
      } else {
        // No part with same name
        const newSection = await savePartInDatabase('section', sectionName, activeItemSelector.value);
        store.commit('liveEditor/hideSaveSectionModal');
        store.dispatch('templateEditor/addSection', { section: newSection });
      }

      valueSaveSectionModal.value = '';
    };

    const sendTestTemplate = async (params: Suggestion[]) => {
      if (state.template) {
        store.commit('general/showTheSpinner');

        // Send query to NestJS
        const getData = {
          type: selectedTemplateType.value,
          params,
        };

        const dataQuery = btoa(encodeURIComponent(JSON.stringify(getData)));
        const result = await nestGet('v4', `/template/test/${state.template.id}/${dataQuery}`, {}, '');

        if (result && result.success) {
          if (isEmailTemplate(selectedTemplateType.value)) {
            await showToastSuccess('emailSent');
          } else if (isSmsTemplate(selectedTemplateType.value)) {
            await showToastSuccess('smsSent');
          }
        } else {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        store.commit('general/hideTheSpinner');
      }
    };

    const executeBeforeTemplateSave = async () => {
      if (!isSmsTemplate(selectedTemplateType.value) && !isPushTemplate(selectedTemplateType.value) && !isFacebookTemplate(selectedTemplateType.value)) {
        await resetTemplateToDesktop();
      }

      return beforeSaveTemplate(t);
    };

    // Function executer when the user click on the save & exit button or the save & stay button
    const executeTemplateSave = async (exit = false, treatSwitchToCampaign = true, validateInformationPanel = true, historyType = HistoryType.POINT_OF_SAVE) => {
      store.commit('general/showTheSpinner');
      let validation: Record<string, any> = { success: true };

      if (validateInformationPanel) {
        // Validate InformationPanel values
        validation = await informationPanelSettingsValidate(true);
        informationPanelValidation.value = validation.validate;
      }

      if (!validation.success) {
        // Open InformationPanel to see errors
        if (!state.isLeftToolbarVisible || !state.leftToolbar.show.informationPanel) {
          await togglePanel('informationPanel');
        }
      } else if (await executeBeforeTemplateSave()) {
        // Update state
        updateSectionsInState();

        await createHistory(historyType);
        if (await saveTemplate()) {
          if (exit) {
            if (openingFromCampaign.value && treatSwitchToCampaign) {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
              if (editingTemplateFromCampaign !== null && editingTemplateFromCampaign.status === EditingTemplateStatusEnum.IN_TEMPLATE_EDITOR) {
                editingTemplateFromCampaign.status = EditingTemplateStatusEnum.SAVE_QUIT_TEMPLATE_EDITOR;
                setEditingTemplateFromCampaign(editingTemplateFromCampaign);
                setCampaignTemplateInformationFromBuilder(state.template?.informations);
              }
            }

            deactivate(state.template?.id ?? 0);
          } else {
            // Refresh smart products lists and listeners
            await refreshSmartProductList();
            resetListeners();
          }
        }
      }

      store.commit('general/hideTheSpinner');
    };

    // Check if template has synced elements and ask to continue or not if any
    const checkHasSyncedElementsInTemplate = (exit = false) => {
      const template = getTemplateIframeDocument();

      if (template) {
        const hasSyncedElement = !state.template?.createdFromCampaign && !!template.querySelector('[data-spm_element_sync]') && !!template.querySelector('[data-spm_element_hash]');

        if (hasSyncedElement) {
          confirm.require({
            group: 'syncedElements',
            message: t('templateBuilder.confirm.saveTemplateWithSyncedElements.message'),
            header: t('templateBuilder.confirm.saveTemplateWithSyncedElements.header'),
            icon: 'far fa-exclamation-triangle',
            acceptLabel: t('yes'),
            acceptClass: 'p-button-success',
            rejectLabel: t('no'),
            rejectClass: 'p-button-secondary',
            accept: async () => {
              await executeTemplateSave(exit);
            },
          });
        } else {
          executeTemplateSave(exit);
        }
      }
    };

    const btnSaveComputed = computed(() => {
      const button = {
        label: t('templateBuilder.save'),
        class: 'p-button-secondary',
        items: [
          {
            label: t('templateBuilder.saveAndLeave'),
            command: async () => {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
              let primaryTemplateToSwitch: any = null;

              if (openingFromCampaign.value && editingTemplateFromCampaign) {
                const templatesLinkedToThisCampaign = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === editingTemplateFromCampaign.id_campaign);
                const idTemplatesLinkedToThisCampaign = templatesLinkedToThisCampaign.map((c: any) => c.id);
                const primaryTemplate = editingTemplateFromCampaign.idTemplate;

                // on est dans un template de redirection
                if (idTemplatesLinkedToThisCampaign.includes(state.template?.id) && state.template?.id !== primaryTemplate) {
                  primaryTemplateToSwitch = state.templatesLinkedToCampaign.filter((c: any) => c.id === primaryTemplate);
                  primaryTemplateToSwitch = primaryTemplateToSwitch.length ? primaryTemplateToSwitch[0] : null;
                }
              } else {
                // vérifier si on est dans un template de redirection
                primaryTemplateToSwitch = state.templates.filter((c: any) => {
                  if (c.configuration.nextIdTemplate) {
                    const [keyNextIdTemplate, valueNextIdTemplate] = Object.entries(c.configuration.nextIdTemplate)[0];
                    const valueNextIdTemplateNumber = valueNextIdTemplate && Array.isArray(valueNextIdTemplate) ? valueNextIdTemplate.map(Number) : [];
                    if (valueNextIdTemplateNumber && Array.isArray(valueNextIdTemplateNumber) && valueNextIdTemplateNumber.includes(Number(state.template?.id))) {
                      return true;
                    }
                  }
                  return false;
                });
                primaryTemplateToSwitch = primaryTemplateToSwitch.length ? primaryTemplateToSwitch[0] : null;
              }

              if (primaryTemplateToSwitch) {
                await executeTemplateSave(true, false);
                await activate(primaryTemplateToSwitch.id, '', primaryTemplateToSwitch.type ?? '');
              } else {
                await executeTemplateSave(true);
              }
              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
              setRefreshTemplatesList(true);
            },
          },
          {
            label: t('templateBuilder.saveAndStay'),
            command: async () => {
              const editingTemplateFromCampaign = getEditingTemplateFromCampaign();

              if (openingFromCampaign.value && editingTemplateFromCampaign) {
                const templatesLinkedToThisCampaign = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === editingTemplateFromCampaign.id_campaign);
                const idTemplatesLinkedToThisCampaign = templatesLinkedToThisCampaign.map((c: any) => c.id);
                const primaryTemplate = editingTemplateFromCampaign.idTemplate;

                // on est dans un template de redirection
                if (idTemplatesLinkedToThisCampaign.includes(state.template?.id) && state.template?.id !== primaryTemplate) {
                  await executeTemplateSave(false, false);
                  return true;
                }
              }

              await executeTemplateSave(false);
              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
              return true;
            },
          },
          {
            label: t('templateBuilder.leaveWithoutSaving'),
            command: async () => {
              if (state.testEmailSent) {
                const rollbackPointIndex = getHistoryIndexFromGroupId(historyList.value, state.lastRollbackId);
                const filteredHistoryList = [historyList.value[rollbackPointIndex]]; // Keep only point of save
                await loadHistoryGroups(filteredHistoryList);
                refreshSectionsConfig(JSON.parse(historyList.value[rollbackPointIndex].sections_order_after ?? '').map((id: string) => id.toString()));
                refreshTranslations();
                setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', +state.lastRollbackId);
                await saveTemplate();
                setTestEmailSent(false);
              }

              if (!openingFromCampaign.value) {
                state.templates.map(
                  async (s) => {
                    deactivate(selectedTemplateId.value ?? 0);
                    if (state.hasAny) {
                      await activate(state.templates[state.index].id, '', state.templates[state.index].type);
                      return true;
                    }
                    return false;
                  },
                );
              } else {
                const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
                const idCampaign = editingTemplateFromCampaign?.id_campaign ?? 0;
                if (idCampaign > 0) {
                  state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === idCampaign).map(
                    async (s) => {
                      deactivate(selectedTemplateId.value ?? 0);
                      if (state.hasAny) {
                        await activate(state.templates[state.index].id, '', state.templates[state.index].type);
                        return true;
                      }
                      return false;
                    },
                  );
                }
              }
            },
          },
        ],
      };

      if (!openingFromCampaign.value) {
        button.items.splice(2, 0, {
          label: t('templateBuilder.minimize'),
          command: async () => hideTemplateEditor(),
        });
      }
      return button;
    });

    const toolbarRightButtons: PrimvueMenuModel[] = [
      btnSaveComputed.value,
    ];

    const contentHeaderButtonsLeft = computed(() => ([
      {
        multipleType: 'buttons',
        items: [
          {
            icon: 'far fa-fw fa-info',
            class: () => `${state.leftToolbar.show.informationPanel && 'active'}`,
            tooltip: t('templateBuilder.toolbar.tooltip.information'),
            command: async () => {
              await togglePanel('informationPanel');
            },
          },
          {
            icon: 'far fa-fw fa-cogs',
            class: () => `${state.leftToolbar.show.configurationPanel && 'active'}`,
            hide: !state.leftToolbar.show.configurationPanel || isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value),
            command: () => {
              togglePanel('configurationPanel').then(() => {
                removeActiveItemElementDecorations();
                store.commit('liveEditor/resetSelectedStructure');
              });
            },
          },
          {
            icon: 'far fa-fw fa-th-large',
            class: () => `${state.leftToolbar.show.widgetsPanel && 'active'}`,
            command: async () => {
              await togglePanel('widgetsPanel');
            },
            hide: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) || state.template?.informations.imported,
          },
          {
            icon: 'far fa-fw fa-pager',
            class: () => `${state.leftToolbar.show.loadSectionPanel && 'active'}`,
            command: () => {
              if (!state.leftToolbar.show.loadSectionPanel) {
                store.commit('liveEditor/showLoadSectionPanel');
              } else {
                store.commit('liveEditor/hideLoadSectionPanel');
              }
            },
            hide: !isEmailTemplate(selectedTemplateType.value) || state.template?.informations.imported,
          },
          {
            icon: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) ? 'far fa-fw fa-th-large' : 'far fa-fw fa-paint-brush',
            class: () => `${state.leftToolbar.show.designPanel && 'active'}`,
            command: async () => {
              // If template is not SMS or Push, or if another panel is opened, we let the user click the button
              if ((!isSmsTemplate(selectedTemplateType.value) && !isPushTemplate(selectedTemplateType.value)) || !state.leftToolbar.show.designPanel) {
                if (isEmailTemplate(selectedTemplateType.value)) {
                  getPageConfig();
                }

                await togglePanel('designPanel');
              }
            },
            hide: isFacebookTemplate(selectedTemplateType.value) || state.template?.informations.imported,
          },
          {
            icon: 'far fa-fw fa-text',
            class: () => `${state.leftToolbar.show.translationPanel && 'active'}`,
            command: async () => {
              await togglePanel('translationPanel');
            },
          },
          {
            icon: 'fab fa-fw fa-css3',
            class: () => `${(state.leftToolbar.show.cssPanel || state.leftToolbar.show.cssPanelMediaQueries) && 'active'}`,
            hide: !state.template?.informations.imported
              && ((!isEmailTemplate(selectedTemplateType.value) && !isDisplayTemplate(selectedTemplateType.value)) || !advancedModeEnabled.value),
            items: [
              {
                label: t('templateBuilder.toolbar.openCssPanel'),
                command: async () => {
                  await togglePanel('cssPanel');
                },
              },
              {
                label: t('templateBuilder.toolbar.openCssPanelMediaQueries'),
                command: async () => {
                  await togglePanel('cssPanelMediaQueries');
                },
              },
            ],
          },
        ],
      },
      {
        multipleType: 'buttons',
        hide: historyList.value.length === 0,
        items: [
          {
            icon: 'far fa-regular fa-clock',
            class: () => `${(state.leftToolbar.show.historyPanel && 'active') || (historyList.value.length === 0 && 'disabled')}`,
            command: async () => {
              if (historyList.value.length !== 0) {
                historyList.value = await getTemplateHistoryGroupList();
                await togglePanel('historyPanel');
              }
            },
          },
          {
            icon: 'far fa-fw fa-undo',
            class: () => `${firstHistoryGroupId.value === currentHistoryGroupId.value && 'disabled'}`,
            disabled: () => firstHistoryGroupId.value === currentHistoryGroupId.value,
            command: () => {
              if (firstHistoryGroupId.value !== currentHistoryGroupId.value) {
                handlePreviousHistory();
              }
            },
          },
          {
            icon: 'far fa-fw fa-redo',
            class: () => `${lastHistoryGroupId.value === currentHistoryGroupId.value && 'disabled'}`,
            disabled: () => lastHistoryGroupId.value === currentHistoryGroupId.value,
            command: () => {
              if (lastHistoryGroupId.value !== currentHistoryGroupId.value) {
                handleNextHistory();
              }
            },
          },
        ],
      },
      {
        multipleType: 'buttons',
        items: [
          {
            icon: 'far fa-fw fa-paper-plane',
            hide: isDisplayTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value),
            command: async () => {
              // Save template before sending a test message
              await executeTemplateSave(false, false, false, HistoryType.INTERMEDIATE_POINT_OF_SAVE);
              // say that the test email has been sent
              setTestEmailSent(true);

              updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));

              if (isPushTemplate(selectedTemplateType.value)) {
                // If push notification template, we ask for browser authorization and we sent the notification
                sendTestNotification(getTestPushNotificationData());
              } else {
                // Validate InformationPanel values
                const validation = await informationPanelSettingsValidate();
                informationPanelValidation.value = validation.validate;

                if (!validation.success) {
                  // Open InformationPanel to see errors
                  if (!state.isLeftToolbarVisible || state.leftToolbar.show.informationPanel === false) {
                    await togglePanel('informationPanel');
                  }
                } else {
                  // If email or SMS, we open the modal
                  store.commit('general/showSendTestModal');
                }
              }
            },
          },
          {
            icon: 'far fa-cog',
            hide: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value)
              || state.template?.informations.imported,
            items: [
              {
                label: t('templateBuilder.toolbar.toggleAdvancedMode'),
                icon: !state.advancedModeEnabled ? 'far fa-square' : 'far fa-check-square',
                iconPos: 'left',
                command: () => {
                  toggleAdvancedMode();
                },
              },
            ],
          },
        ],
      },
      {
        multipleType: 'buttons',
        hide: isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value) || isFacebookTemplate(selectedTemplateType.value),
        items: [
          {
            icon: 'far fa-fw fa-desktop',
            class: () => `${width.value === TemplateWidth.DESKTOP && 'active'}`,
            command: () => { width.value = TemplateWidth.DESKTOP; },
          },
          {
            icon: 'far fa-fw fa-tablet',
            class: () => `${width.value === TemplateWidth.TABLET && 'active'}`,
            command: () => { width.value = TemplateWidth.TABLET; },
          },
          {
            icon: 'far fa-fw fa-mobile',
            class: () => `${width.value === TemplateWidth.MOBILE && 'active'}`,
            command: () => { width.value = TemplateWidth.MOBILE; },
          },
        ],
      },
    ]));

    const closeDuplicationModal = () => {
      showDuplicationModal.value = false;
    };

    const duplicateConfirmationDialog = (newName: string) => {
      showDuplicationModal.value = false;
      duplicateTemplate(idShop, selectedTemplateId.value, null, null, newName).then((result) => {
        if (result.success && result.id) {
          showToastSuccess(t('templates.confirm.duplicate.success.message'));
          updateSectionsInState();
          activate(result.id, '', selectedTemplateType.value);
          setRefreshTemplatesList(true);
        } else {
          showToastError(t('templates.confirm.duplicate.error.message'));
        }
      });
    };

    const tabs: ComputedRef<PrimvueMenuModel[]> = computed(() => {
      let buttons: PrimvueMenuModel[] = [];
      if (!openingFromCampaign.value) {
        buttons = state.templates.map(
          (s) => {
            const isActiveTemplate = (s.id === selectedTemplateId.value);

            return {
              label: s.informations.name,
              class: `${isActiveTemplate ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
              command: !isActiveTemplate ? () => { updateSectionsInState(); activate(s.id, s.informations.name, s.type); } : undefined,
              items: [
                {
                  label: t('templateBuilder.rename'),
                  command: () => {
                    valueRenameTemplateModal.value = s.informations.name;
                    displayRenameTemplateModal.value = true;
                  },
                },
                {
                  label: t('templateBuilder.duplicate'),
                  command: () => {
                    newListName.value = t('myLists.manage.actions.cloneText') + s.informations.name;
                    showDuplicationModal.value = true;
                  },
                },
                {
                  label: t('templateBuilder.close'),
                  command: () => {
                    deactivate(selectedTemplateId.value ?? 0);
                    if (state.hasAny) {
                      activate(state.templates[state.index].id, '', state.templates[state.index].type);
                    }
                  },
                },
              ],
            };
          },
        );
        buttons.push({
          icon: 'far fa-plus',
          class: 'create-new-btn p-button-primary footer-btn',
          command: () => {
            showTemplateSelector.value = true;
          },
        });
      } else {
        const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
        const idCampaign = editingTemplateFromCampaign?.id_campaign ?? 0;
        if (idCampaign > 0) {
          buttons = state.templatesLinkedToCampaign.filter((c: any) => c.idCampaign === idCampaign).map(
            (s) => {
              const isActiveTemplate = (s.id === selectedTemplateId.value);

              return {
                label: s.informations.name,
                class: `${isActiveTemplate ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
                command: !isActiveTemplate ? () => { updateSectionsInState(); activate(s.id, s.informations.name, s.type); } : undefined,
                items: [
                  {
                    label: t('templateBuilder.rename'),
                    command: () => {
                      valueRenameTemplateModal.value = s.informations.name;
                      displayRenameTemplateModal.value = true;
                    },
                  },
                  {
                    label: t('templateBuilder.duplicate'),
                    command: () => {
                      newListName.value = t('myLists.manage.actions.cloneText') + s.informations.name;
                      showDuplicationModal.value = true;
                    },
                  },
                  {
                    label: t('templateBuilder.close'),
                    command: () => {
                      deactivate(selectedTemplateId.value ?? 0);
                      if (state.hasAny) {
                        activate(state.templates[state.index].id, '', state.templates[state.index].type);
                      }
                    },
                  },
                ],
              };
            },
          );
        }
      }
      return buttons;
    });

    watch(() => selectedStructure.value, (values) => {
      if (values && values.type !== TemplateStructureEnum.PAGE && !isConfigurationPanelVisible.value) {
        showConfigurationPanel();
      }

      if (values && values.type !== TemplateStructureEnum.PAGE && !isLeftToolbarVisible.value) {
        togglePanel('configurationPanel');
      }
    });

    watch(() => state.leftToolbar.show, () => {
      if (state.leftToolbar.show.designPanel) { // If we show designPanel, prepare structure configuration
        prepareStructureConfiguration();
      }
    }, { deep: true });

    watch(() => state.leftToolbar.show.translationPanel, () => {
      if (!state.leftToolbar.show.translationPanel) {
        refreshAttributeInDOM(locale.value);

        // Restore original code of all smart lists
        restoreSmartListsOriginalCode();

        const defaultLanguage = getShopDefaultLang();
        updateIframeTranslations(state.template?.translations.filter((translation) => translation.language === defaultLanguage) ?? []);

        resetNeedToTranslate();

        // Store original code of lists
        refreshSmartProductsListsOriginalStructure();

        // Recalculate smart list with default language
        refreshSmartProductList();

        resetListeners();
      } else {
        // Restore original code of all smart lists
        restoreSmartListsOriginalCode();
        removeUnusedTranslations();
        fixMissingTranslations();
        sortTranslationInState();
        // Store original code of lists
        refreshSmartProductsListsOriginalStructure();

        // Recalculate smart list with default language
        refreshSmartProductList();
      }
    });

    watch(lastHistoryGroupId, async (newValue) => {
      historyList.value = await getTemplateHistoryGroupList();
      setLastHistory(newValue);
    });

    onBeforeMount(async () => {
      historyList.value = await getTemplateHistoryGroupList();

      updateLastRollbackGroupId(getLastPointOfSaveId(historyList.value));
      if (!state.template?.configuration.currentIdTemplateElementsHistoryGroup) {
        // In case we open a template directly from database, we set the last point of save as the current history point
        const lastPointOfSave = getClosestPointOfSave(historyList.value);
        const lastHistoryPointOfSave: TemplatesElementsHistoryGroup = historyList.value[lastPointOfSave];

        if (lastHistoryPointOfSave) {
          setTemplateConfigurationKeyInState('currentIdTemplateElementsHistoryGroup', lastHistoryPointOfSave.id_template_elements_history_group);
        }
      }
      setLastHistory(currentHistoryGroupId.value);

      if (historyList.value.length === 0) {
        // Create first point of save of template if history list is empty
        await createHistory(HistoryType.POINT_OF_SAVE);
      }
    });

    onMounted(() => {
      window.addEventListener('unload', saveTemplatesInLocalStorage);
    });

    onBeforeUnmount(() => {
      saveTemplatesInLocalStorage();
      window.removeEventListener('unload', saveTemplatesInLocalStorage);
    });

    const closeTemplateSelector = () => {
      showTemplateSelector.value = false;
    };

    const selectTemplateFromTemplateSelector = async (idTemplate: number, label: string, type: string) => {
      activate(idTemplate, label, type).then(() => {
        showTemplateSelector.value = false;
        store.commit('general/hideTheSpinner');
      });
    };

    /**
     * Execute functions after iframe is loaded
     */
    const iframeLoaded = async () => {
      if (isSmsTemplate(selectedTemplateType.value) || isPushTemplate(selectedTemplateType.value)) {
        await togglePanel('designPanel');
        prepareStructureConfiguration();
      } else if (state.template?.informations.imported) {
        await togglePanel('translationPanel');
      } else if (isEmailTemplate(selectedTemplateType.value) && !state.template?.informations.imported) {
        await togglePanel('widgetsPanel');
      }
    };

    return {
      width,
      selectedTemplateId,
      selectedTemplateName,
      selectedTemplateType,
      selectedStructure,
      state,
      isLeftToolbarVisible,
      toolbarRightButtons,
      contentHeaderButtonsLeft,
      tabs,
      livePreviewKey,
      historyList,
      selectedHistory,
      displayRenameTemplateModal,
      valueRenameTemplateModal,
      displaySaveSectionModal,
      valueSaveSectionModal,
      rename,
      handleChangeHistory,
      saveSection,
      handleSaveSectionClose,
      sendTestTemplate,
      showTemplateSelector,
      closeTemplateSelector,
      selectTemplateFromTemplateSelector,
      iframeLoaded,
      isConfigurationPanelClosable,
      informationPanelValidation,
      newListName,
      t,
      showDuplicationModal,
      closeDuplicationModal,
      duplicateConfirmationDialog,
      isChooseZipFileModalVisible,
      isWidgetPanelClosable,
      isTranslationPanelClosable,
      generateSha1Hash,
      MEDIA_URL_PART_THUMB_GENERATION,
      toEraseSectionId,
    };
  },
});
