<template>
  <div class="block p-buttonset">
    <template v-if="!isRemoved">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex justify-content-center">
          <label
            class="block my-3"
          >
            {{ t(configs.label) }}
          </label>
        </div>
        <div class="flex justify-content-center align-items-center">
          <ToggleDisplaySettings
            v-if="!Object.prototype.hasOwnProperty.call(displayConfigs, 'displayToggle') || displayConfigs.displayToggle"
            :configs="displayConfigs"
            :parser-values="parserValues"
            :default-value="true"
            @display-settings="(value) => displayProperties = value"
          />
        </div>
      </div>
      <div
        class="p-fluid mb-2"
        :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
      >
        <InputText
          v-model="selectedImage"
          class="input-text"
          data-test-id="image-input"
          :placeholder="t('templateBuilder.fields.imageUrl')"
          @change="handleImageChange"
        />
      </div>
      <div
        class="text-center"
        :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
      >
        <div
          v-if="selectedImage"
          class="mt-2 p-2 selected-image"
        >
          <div class="preview-container">
            <img
              :src="selectedImage"
              alt="alt"
              class="product-image"
            >
            <div class="action-buttons">
              <Button
                :title="t('templateBuilder.fields.chooseImage')"
                icon="far fa-exchange"
                class="mt-2 mr-2"
                data-test-id="image-button"
                @click="displayImageModal=true"
              />
              <Button
                :title="t('templateBuilder.fields.editImage')"
                icon="far fa-pencil"
                class="mt-2 mr-2"
                @click="editImageModal=true"
              />
              <Button
                :title="t('templateBuilder.fields.removeImage')"
                icon="far fa-trash"
                class="mt-2"
                @click="handleRemove"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isForSection && !noOptions"
        class="p-fluid mt-2"
        :class="{
          'hidden': !displayProperties,
          'flex flex-column gap-1 w-full': displayProperties,
        }"
      >
        <div class="flex">
          <label
            class="block my-3"
          >
            {{ t(configs.options.label) }}
          </label>
        </div>
        <BaseDropdown
          v-model="selectedSectionOption"
          :options="sectionOptions"
        />
      </div>
      <div
        v-if="!noOptions"
        class="p-fluid mt-2"
        :class="{
          'hidden': !displayProperties,
          'flex flex-column gap-1 w-full': displayProperties,
        }"
      >
        <div
          v-for="(config, index) in configs.options.list"
          :key="index"
          class="flex flex-column"
        >
          <BackgroundImageOption
            :config="config"
            :parser-values="parserValues"
            :display-properties="displayProperties"
            @on-change="handleOptionChange"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-content-between flex-wrap">
        <div class="flex justify-content-center">
          <label
            class="block my-3"
          >
            {{ t(configs.label) }}
          </label>
        </div>
        <div class="flex justify-content-center align-items-center">
          <ToggleDisplaySettings
            v-if="!Object.prototype.hasOwnProperty.call(displayConfigs, 'displayToggle') || displayConfigs.displayToggle"
            :configs="displayConfigs"
            :parser-values="parserValues"
            @display-settings="(value) => displayProperties = value"
          />
        </div>
      </div>
      <div
        class="text-center mb-2"
        :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
      >
        <Button
          :label="t('templateBuilder.fields.chooseImage')"
          class="mb-2"
          data-test-id="image-button"
          @click="displayImageModal=true"
        />
      </div>
    </template>
    <FileManager
      v-if="displayImageModal"
      :display-dialog="displayImageModal"
      extensions="image/*"
      @on-close-dialog="displayImageModal=false"
      @on-choose-file="onChooseImage"
    />
    <EditImageModal
      :display-dialog="editImageModal"
      :image="selectedImage"
      @on-close-dialog="editImageModal=false"
      @on-save-image="onSaveImage"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
  computed,
  onBeforeMount,
} from 'vue';

import { useStore } from '@/store';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import BackgroundImageOption from '@/components/template-builder/fields/partials/BackgroundImageOption.vue';

import {
  FieldConfig,
  File,
  ParserFieldObject,
  Property,
  BackgroundImageOptionTypeEnum,
} from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  TemplateEditorState as state,
} from '@/composables/template-editor/TemplateEditor';

import { useI18n } from 'vue-i18n';
import { removeImage } from '../callbacks';
import { removeDynamicStyle } from '../utils/parser';
import { isEmailTemplate } from '../utils/helpers';

export default defineComponent({
  name: 'BackgroundImage',

  components: {
    FileManager,
    EditImageModal,
    Button,
    InputText,
    ToggleDisplaySettings,
    BackgroundImageOption,
    BaseDropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },

    tabItemLabel: {
      type: String,
      required: true,
    },

    fieldGroupLabel: {
      type: String,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();

    const displayImageModal = ref(false);
    const editImageModal = ref(false);

    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(props.configs, 'displayToggle') || props.configs.displayToggle === true));
    const displayConfigs = ref(props.configs);

    const srcProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'background-image')[0]);
    const selectedImage: Ref<string> = ref(srcProperty.value.value.match(/url\("([^"]+)"\)/) ? srcProperty.value.value.match(/url\("([^"]+)"\)/)[1] : '');
    const savedSelectedImage: Ref<string> = ref(selectedImage.value);

    const isForSection = ref(props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR);
    const noOptions = ref(props.configs.options.type === BackgroundImageOptionTypeEnum.NO_OPTION);

    const selectedSectionOption = ref(props.configs.options.defaultValue ?? '');
    const lastSelectedSectionOption = ref(selectedSectionOption.value);
    const sectionOptions = computed(() => {
      if (props.configs.options.options) {
        return props.configs.options.options.map((option: any) => ({
          label: t(option.label),
          value: option.value,
        }));
      }
      return [];
    });

    const isRemoved = ref(selectedImage.value.trim() === '');

    const saveProperties = (imageValue: string) => {
      // Remove last selectedSectionOption
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        srcProperty.value.value = 'none';
        let lastSelectedSelector = props.parserValues.selector;
        if (lastSelectedSectionOption.value === '.container') {
          lastSelectedSelector = `${lastSelectedSelector} ${selectedSectionOption.value}`;
        }
        context.emit('on-change-properties', {
          selector: lastSelectedSelector,
          properties: [srcProperty.value],
        });
      }

      // Change with new value
      let selectedSelector = props.parserValues.selector;
      srcProperty.value.value = `url("${imageValue}")`;
      if (imageValue.trim() === '') {
        srcProperty.value.value = 'none';

        const selectedStructure = store.getters['liveEditor/getSelectedStructure'];
        if (selectedStructure && props.configs.linkedItem) {
          const linkedParserValues = selectedStructure.parserValues[props.tabItemLabel].items[props.fieldGroupLabel][props.configs.linkedItem];
          if (linkedParserValues) {
            const backgroundProperty = ref(linkedParserValues.properties && linkedParserValues.properties.filter((property: any) => property.name === 'background')[0]);
            if (backgroundProperty.value) {
              backgroundProperty.value.value = backgroundProperty.value.value.replace(/url\("([^"]+)"\)/, '');
              context.emit('on-change-properties', {
                selector: selectedSelector,
                properties: [backgroundProperty.value],
              });
            }
          }
        }
      }

      // Update selector only if in section
      if (selectedSectionOption.value === '.container' && props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        selectedSelector = `${selectedSelector} ${selectedSectionOption.value}`;
      }

      context.emit('on-change-properties', {
        selector: selectedSelector,
        properties: [srcProperty.value],
      });
    };

    const handleImageChange = () => {
      savedSelectedImage.value = selectedImage.value;
      if (selectedImage.value.trim() === '' && isEmailTemplate(state.template?.type)) {
        removeDynamicStyle(props.parserValues.selector, { 'background-image': 'background-image' });
      }
      saveProperties(selectedImage.value);
      isRemoved.value = selectedImage.value.trim() === '';
    };

    const handleRemove = () => {
      selectedImage.value = '';
      removeImage(props.parserValues.selector);
      handleImageChange();
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleImageChange();
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleImageChange();
    };

    const handleOptionChange = (property: Property) => {
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property],
      });
    };

    watch(() => displayProperties.value, () => {
      if (!displayProperties.value) {
        savedSelectedImage.value = selectedImage.value;
        saveProperties('');
      } else {
        // setTimeout to avoids undesirable visual effects
        setTimeout(async () => {
          saveProperties(savedSelectedImage.value);
        }, 100);
      }
    }, { deep: true });

    watch(() => selectedSectionOption.value, (newValue, oldValue) => {
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        lastSelectedSectionOption.value = oldValue;
        saveProperties(selectedImage.value);
      }
    }, { deep: true });

    onBeforeMount(() => {
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        const backgroundProperty = props.parserValues.properties.find((property) => property.name === 'backgroundImageCustomSelector');
        if (backgroundProperty && backgroundProperty.value.includes('.container')) {
          selectedSectionOption.value = '.container';
        } else {
          selectedSectionOption.value = 'full-width';
        }
      }
    });

    return {
      t,
      displayImageModal,
      editImageModal,
      selectedImage,
      displayProperties,
      isForSection,
      selectedSectionOption,
      sectionOptions,
      displayConfigs,
      isRemoved,
      noOptions,

      onChooseImage,
      onSaveImage,
      handleImageChange,
      handleRemove,
      handleOptionChange,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 11rem;
.selected-image {
  width: 100%;
  margin: auto;
  border: 1px solid $solitude !important;
  text-align: center;

  .preview-container {
    position: relative;
    text-align: center;

    .product-image {
      width: $img-size;
      object-fit: cover;
      object-position: bottom;
      margin: auto;
    }

    .action-buttons {
      display: block;

      & .p-button {
        border: solid 1px $solitude;
        background: $white;
        color: $heather;
        border-radius: 3px;
        box-shadow: none !important;
      }
    }
  }
}
.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}
</style>
