
import { defineComponent, onMounted } from 'vue';
import { UserState } from '@/composables/User';
import { showToastError, showToastSuccess } from '@/helpers';
import { useToast } from 'primevue/usetoast';
import * as CryptoJS from 'crypto-js';
import axios, { AxiosResponse } from 'axios';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { triggerBlobDownload } from '@/helpers/helpers';

export default defineComponent({
  name: 'DownloadFile',

  props: {
    encodedString: {
      type: String,
      required: true,
    },
  },

  setup(props: {encodedString: string}) {
    const toast = useToast();
    const idShop = UserState.activeShop?.id;
    const router = useRouter();
    const {
      t,
    } = useI18n();

    const decodeString = (hexString: string): {
      id_shop: number;
      id_export: number;
      filename?: string;
      exportType?: string;
    } => {
      // Convert from hexadecimal back to binary
      const encryptedData = Buffer.from(hexString, 'hex').toString('binary');

      const keyHex = '000102030405060708090a0b0c0d0e0f'; // 32 bytes (256 bits) key in hexadecimal
      const ivHex = '4656507136555473693371716133326a47';
      // Convert key and IV from hexadecimal to WordArray
      const key = CryptoJS.enc.Hex.parse(keyHex);
      const iv = CryptoJS.enc.Hex.parse(ivHex);

      // Decrypt using AES-256-CBC
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
        iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      });

      // Convert decrypted bytes to UTF-8 string
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

      // Parse JSON if needed
      return JSON.parse(decryptedData);
    };

    const getData = async (url: string, apiToken: string, params: any = {}) => {
      // if (idShop !== decodedObject.id_shop) {
      //   await showToastError(t('download.error'));
      //   return;
      // }

      let response: AxiosResponse<Blob> | undefined;

      try {
        return await axios.post(
          url,
          params,
          {
            responseType: 'blob',
            headers: {
              'api-token': apiToken,
            },
          },
        );
      } catch (err: any) {
        try {
          const errorData = JSON.parse(await err.response.data.text());
          await showToastError(`${t('download.error')}: ${errorData.message}`);
          return;
        } catch (parseError) {
          await showToastError(t('download.error'));
          return;
        }
      }
    };

    const downloadFile = async () => {
      try {
        const nestJsUrl = process.env.VUE_APP_NESTJS_API_URL;
        const {
          id_shop,
          id_export,
          filename: originalFilename,
          exportType,
        } = decodeString(props.encodedString);
        const dataUrl = `${nestJsUrl}/data/${id_shop}/exported-files/${id_export}/download`;
        const apiToken = 'Ae1i2kndasdcasdfe23sad';

        const response = await getData(dataUrl, apiToken);

        if (!response) {
          await showToastError(`${t('download.error')}: ${response}`);
          return;
        }

        const filename = response.headers['content-disposition']
          ?.split('filename=')[1]
          ?.replace(/['"]/g, '') || originalFilename || 'downloaded-file.zip';

        // Trigger the download
        triggerBlobDownload(response.data, filename);
        const returnPath: Record<string, string> = {
          'export-campaign': 'campaigns-bulk',
          'export-template': 'template-manage',
          'export-automation': 'workflow',
          'export-stats': 'users.stats',
        };
        showToastSuccess(t('download.success'));

        if (exportType) {
          router.push({
            name: returnPath[exportType],
          });
        }
      } catch (error) {
        console.error('Error:', error);
        await showToastError(t('download.error'));
      }
    };

    onMounted(async () => {
      toast.add({
        severity: 'info',
        summary: '',
        detail: t('download.pending'),
        life: 3000,
      });
      await downloadFile();
    });
  },
});
