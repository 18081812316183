import {
  Get, Request, Update, List,
} from '@/composables/GraphQL';
import {
  Shops, ShopsUpdateInputItem, OperatorType,
} from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import { nestPost } from '@/composables/nestApi';

export interface ShopsLangCustom {
  lang: string;
  default: boolean;
  url: string;
  urlCart: string;
}

interface ShopInfo {
  name: string;
  solutionEcommerce: string;
  idCategoryShop: number;
  currency: string;
  urlClient: string;
  defaultLang: string;
  langs: Array<ShopsLangCustom>;
  currentBuild?: number;
  currentVersion?: string;
}

interface ShopsArgs {
  shopId: number;
}

export default async function fetchShopInformation({ shopId }: ShopsArgs): Promise<ShopInfo|null> {
  const { item, err } = await Get<Shops>({
    id: shopId,
    name: 'Shops',
    keyName: 'id_shop',
    fields: [
      'name',
      'solution_ecommerce',
      'id_category_shop',
      'currency',
      'url_client',
      'shopsLang{lang, default, url, url_cart}',
      'url',
      'current_build',
      'current_version',
    ],
  });

  if (err) {
    return null;
  }

  if (!item) {
    return null;
  }

  const name = item.name ?? '';
  const solutionEcommerce = item.solution_ecommerce ?? '';
  const urlClient = item.url_client ?? '';
  const idCategoryShop = item.id_category_shop ?? 0;
  const currency = item.currency ?? '';
  const currentBuild = item.current_build ?? 0;
  const currentVersion = item.current_version ?? '';

  const length = item.shopsLang ? item.shopsLang.length : 0;
  let defaultLang: string | undefined = '';
  for (let i = 0; i < length; i++) {
    if (item.shopsLang && item.shopsLang[i]?.default === true) {
      defaultLang = item.shopsLang[i]?.lang ?? '';
    }
  }

  const langs = item.shopsLang ? item.shopsLang.map((lang) => (lang ? {
    lang: lang?.lang ?? '',
    default: lang?.default ?? false,
    url: lang?.url ?? '',
    urlCart: lang?.url_cart ?? '',
  } : {
    lang: '',
    default: false,
    url: '',
    urlCart: '',
  })) : [];

  return {
    name,
    solutionEcommerce,
    urlClient,
    idCategoryShop,
    currency,
    langs,
    defaultLang,
    currentBuild,
    currentVersion,
  };
}

export const updateShop = async (shopInput: ShopsUpdateInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Update<ShopsUpdateInputItem>({
    name: 'Shops',
    input: shopInput,
    type: 'ShopsUpdateInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
};

export const HasTerminatedOldNewsletters = async () => {
  const query = 'query($status: String)'
    + '{ HasTerminatedOldNewsletters(status: $status) }';

  const variables = {
    status: 'terminated',
  };

  return Request<{ content: string; status: number; headers: string[] }>({
    name: 'HasTerminatedOldNewsletters',
    query,
    variables,
  });
};

export const generateApiKey = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789&#([-_@)]=}{!:;.?,/';
  const charactersLength = characters.length;
  const apiKey: string[] = [];

  for (let i = 0; i < 35; i++) {
    let randomIndex = Math.floor(Math.random() * charactersLength);
    let chosenCharacter = characters[randomIndex];

    while (apiKey.includes(chosenCharacter)) {
      randomIndex = Math.floor(Math.random() * charactersLength);
      chosenCharacter = characters[randomIndex];
    }

    apiKey.push(chosenCharacter);
  }

  return apiKey.join('');
};

export const generateApiIdentifiant = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  const apiIdentifiant: string[] = [];

  for (let i = 0; i < 8; i++) {
    let randomIndex = Math.floor(Math.random() * charactersLength);
    let chosenCharacter = characters[randomIndex];

    while (apiIdentifiant.includes(chosenCharacter)) {
      randomIndex = Math.floor(Math.random() * charactersLength);
      chosenCharacter = characters[randomIndex];
    }

    apiIdentifiant.push(chosenCharacter);
  }

  return apiIdentifiant.join('');
};

export const getMonthlyPagesViewed = async () => {
  const idShop = UserState.activeShop?.id ?? 0;
  const pv = await nestPost('payments', '/get-shop-pv', {}, { idShop });

  if (pv && pv.success) {
    return pv.pv;
  }
  return false;
};

export const getUserShops = async (idUser: any) => {
  const { items, err } = await List<Shops>({
    name: 'Shops',
    settings: {
      filter: [
        {
          field: 'id_user',
          operator: OperatorType.Equals,
          value: idUser,
        },
      ],
      order: [
        { field: 'id_shop', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop', 'name'],
  });

  if (err === '') {
    return items;
  }

  return [];
};
