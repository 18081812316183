
import {
  defineComponent,
  ref, watch,
} from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { useI18n } from 'vue-i18n';

import {
  SpmTableAction,
  SpmTableColumns,
  SpmTableFilter,
  SpmTableFilterOption,
  SpmTableSort,
} from '@/types';
import SpmTable from '@/components/table/SpmTable.vue';
import {
  OperatorType,
  Templates,
} from '@/types/generated-types/graphql';
import {
  updateTemplateColumn,
  deleteTemplate, activate as activateTemplate, TemplateEditorState, setRefreshTemplatesList,
} from '@/composables/template-editor/TemplateEditor';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { duplicateTemplate, TemplateTypeEnum } from '@/composables/shop/Templates';
import { findPermission, UserState, hasAccessToFeatures } from '@/composables/User';
import { showToastError, showToastSuccess } from '@/helpers';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import { store } from '@/store';
import Dialog from 'primevue/dialog';
import CryptoJS from 'crypto-js';
import { loadImageUntilSuccess } from '@/composables/loadImage';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import InputText from 'primevue/inputtext';
import OverlayPanel from 'primevue/overlaypanel';
import { registerExportRequest } from '@/composables/useExport';
import ImportModal from '@/components/modals/ImportModal.vue';

import ImportTemplateModal from '@/components/template-lists/ImportTemplateModal.vue';
import TemplateImportMethodModal from '@/components/template-lists/TemplateImportMethodModal.vue';

import { TEMPLATE_IMPORT_METHODS } from '@/configs/templates';

export default defineComponent({
  name: 'MyTemplates',
  components: {
    TemplateSelector,
    SpmButton,
    SpmTable,
    ConfirmPopup,
    Toast,
    Dialog,
    InputText,
    OverlayPanel,
    ImportModal,
    ImportTemplateModal,
    TemplateImportMethodModal,
  },

  setup() {
    const { t } = useI18n();
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const visibleTemplatePreview = ref(false);
    const urlTemplateToPreview = ref('');
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const newListName = ref('');
    const confirm = useConfirm();
    const toast = useToast();
    const spmTableKey = ref(0);
    const showTemplateSelector = ref(false);
    const renameOverlayRef = ref();
    const templateName = ref();
    const currentRowData = ref();
    const childComponentRef = ref();
    const isImportModalVisible = ref(false);
    const displayImportMethodSelectionModal = ref(false);
    const displayImportTemplateModal = ref(false);
    const { id, userType } = UserState.user;

    const typeOptions: SpmTableFilterOption[] = Object.values(TemplateTypeEnum)
      .map((key: string) => ({ value: key, label: t(`templateSelector.dialogTypeTemplate.types.${key}.label`) }));

    const dataExportPermission = hasAccessToFeatures('data.export');

    // Actions
    const showActiveTemplateEditor = async (template: Templates) => {
      if (template.type) {
        store.commit('general/showTheSpinner');
        activateTemplate(template.id_template, template.label, template.type).then(() => {
          store.commit('general/hideTheSpinner');
        });
      }
    };

    const askDeleteTemplate = (idTemplate: number, event: any) => {
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: t('templates.confirm.delete.message'),
        icon: 'fal fa-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: () => {
          deleteTemplate(idTemplate).then((deleted: boolean | string) => {
            if (deleted === true) {
              toast.add({
                severity: 'success', summary: t('templates.confirm.delete.success.header'), detail: t('templates.confirm.delete.success.message'), life: 3000,
              });

              spmTableKey.value += 1;
            } else {
              toast.add({
                severity: 'error', summary: t('templates.confirm.delete.error.header'), detail: t(deleted), life: 3000,
              });
            }
          });
        },
      });
    };

    const askDuplicateTemplate = (idTemplate: number, newTemplateName: string, event: any) => {
      newListName.value = t('myLists.manage.actions.cloneText') + newTemplateName;
      confirm.require({
        group: 'customDialog',
        target: event.currentTarget,
        message: t('templates.confirm.duplicate.message'),
        icon: 'fal fa-info-circle',
        acceptLabel: t('validate'),
        acceptClass: 'p-button-success',
        rejectLabel: t('cancel'),
        rejectClass: 'p-button-secondary',
        accept: () => {
          duplicateTemplate(idShop.value, idTemplate, null, null, newListName.value).then((result) => {
            if (result.success && result.id) {
              showToastSuccess(t('templates.confirm.duplicate.success.message'));
              setRefreshTemplatesList(true);
            } else {
              showToastError(t('templates.confirm.duplicate.error.message'));
            }
          });
        },
      });
    };

    const showRenameOverlay = (template: Templates, event: Event) => {
      currentRowData.value = template;
      templateName.value = template.label;
      renameOverlayRef.value.toggle(event);
    };

    const initialSorting: SpmTableSort[] = [
      {
        field: 'date_modification',
        type: 'DESC',
      },
    ];

    const persistentFilters = ref<SpmTableFilter[]>([
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: idShop.value,
      },
      {
        field: 'created_from_campaign',
        operator: OperatorType.IsNull,
        value: '',
      },
      {
        field: 'id_campaign',
        operator: OperatorType.IsNull,
        value: '',
      },
      {
        field: 'deleted',
        operator: OperatorType.Equals,
        value: false,
      },
    ]);

    const actions: SpmTableAction[] = [
      {
        icon: 'far fa-edit',
        label: t('templates.edit'),
        callback: (template: Templates) => showActiveTemplateEditor(template),

        show: () => findPermission('my_templates.edit'),
      },
      {
        icon: 'far fa-clone',
        label: t('templates.duplicate'),
        callback: (template: Templates, event: any) => (event && template ? askDuplicateTemplate(template.id_template, template.label, event) : false),
        show: () => findPermission('my_templates.duplicate'),
      },
      {
        icon: 'fa-light fa-input-text',
        label: t('templates.confirm.rename.title'),
        callback: (template: Templates, event: any) => (event && template ? showRenameOverlay(template, event) : false),
        show: () => findPermission('my_templates.rename'),
      },
      {
        icon: 'far fa-trash',
        label: t('templates.delete'),
        callback: (template: Templates, event: any) => (event && template ? askDeleteTemplate(template.id_template, event) : false),
        show: () => findPermission('my_templates.delete'),
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_template', header: '', sortable: false, filterable: false, editable: false, style: 'display:none', type: 'preview',
      },
      {
        field: 'label', header: t('templates.name'), sortable: true, filterable: true, editable: true, style: '', type: 'text',
      },
      {
        field: 'date_creation',
        header: t('templates.date_add'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'date_modification',
        header: t('templates.date_modif'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'type',
        header: t('templates.type'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:20%',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: typeOptions, hideFilterMenu: true },
      },
      {
        field: '', header: t('templates.actions'), sortable: false, filterable: false, editable: false, style: 'width:10%', type: 'action', actions,
      },
    ];

    const registerExportRequestCommand = async (idsTobeExported: number[]) => {
      console.log('IDS TO BE EXPORTED ==> ', idsTobeExported);
      registerExportRequest(idsTobeExported, idShop.value, id, userType, 'template').then(async (result: any) => {
        await showToastSuccess(t('templates.export.exportSuccess'));
        childComponentRef.value.unselectAllRows();
      }).catch(async (err: any) => {
        await showToastError(t('templates.import.exportFailure'));
      });
    };

    const groupActions = [
      {
        code: 'export',
        label: t('campaigns.automation.list.groupedActions.export.menuItem'),
        command: (event: {originalEvent: Event; item: any; navigate: undefined}) => {
          const command = () => {
            childComponentRef.value.toggleDialog(
              '',
              t('campaigns.automation.list.groupedActions.export.text'),
              t('campaigns.automation.list.groupedActions.export.formTitle'),
              '',
              t('yes'),
              t('no'),
              'custom',
              null,
              '',
              registerExportRequestCommand,
            );
          };
          if (dataExportPermission && !dataExportPermission.access) {
            store.commit('general/setIsFeatureUnavailableModalVisible', true);
            store.commit('general/setFeatureUnavailableMinPlan', dataExportPermission.minimumPlan);
            store.commit('general/setOnAuthorizedClickFunction', command);
          } else {
            command();
          }
        },
      },
    ];

    const onColumnValueEdit = async (field: string, data: any) => {
      await updateTemplateColumn(field, data);
    };

    /* Action to execute when closing the template selector */
    const closeTemplateSelector = () => {
      showTemplateSelector.value = false;
    };

    const selectTemplate = async (idTemplate: number, label: string, type: string) => {
      activateTemplate(idTemplate, label, type).then(() => {
        showTemplateSelector.value = false;
        setRefreshTemplatesList(true);
        store.commit('general/hideTheSpinner');
      });
    };

    const showTemplateSelectorHandler = () => {
      showTemplateSelector.value = true;
    };

    const hideImportModal = (needReload: boolean) => {
      isImportModalVisible.value = false;
      if (needReload) {
        setRefreshTemplatesList(true);
      }
    };

    const showImportModal = () => {
    };

    const preview = async (event: Event, idTemplate: number, typeTemplate: any) => {
      event?.stopPropagation();
      const targetElement = event.target as HTMLElement;
      targetElement.classList.remove('fa-magnifying-glass');
      targetElement.classList.add('fa-spinner', 'fa-spin');

      urlTemplateToPreview.value = '';
      const cryptedImageKey = CryptoJS.SHA1(`salt-${idTemplate}`).toString();

      const potentialImageUrl = `${baseUrl + cryptedImageKey}${isDisplayTemplate(typeTemplate) ? '-isdisplay' : ''}.png&w=400&${new Date().getTime()}`;
      try {
        await loadImageUntilSuccess(potentialImageUrl);
        urlTemplateToPreview.value = potentialImageUrl;
        visibleTemplatePreview.value = true;
      } catch (error) {
        console.error("Erreur lors du chargement de l'image", error);
      }

      targetElement.classList.remove('fa-spinner', 'fa-spin');
      targetElement.classList.add('fa-magnifying-glass');
    };

    watch(() => TemplateEditorState.refreshTemplatesList, () => {
      if (TemplateEditorState.refreshTemplatesList) {
        spmTableKey.value += 1;
        setRefreshTemplatesList(false);
      }
    });

    const closeRenameOverlay = () => {
      renameOverlayRef.value.hide();
    };

    const renameTemplate = async () => {
      currentRowData.value.label = templateName.value;
      await updateTemplateColumn('label', currentRowData.value);
      closeRenameOverlay();
    };

    const handleSelectedImportMethod = (importMethod: string) => {
      const { SPM_FILE, THIRD_PARTY } = TEMPLATE_IMPORT_METHODS;
      switch (importMethod) {
        case SPM_FILE:
          isImportModalVisible.value = true;
          break;
        case THIRD_PARTY:
          displayImportTemplateModal.value = true;
          break;
        default:
          break;
      }
    };

    return {
      spmTableKey,
      initialSorting,
      columns,
      persistentFilters,
      t,
      onColumnValueEdit,
      showTemplateSelectorHandler,
      showTemplateSelector,
      selectTemplate,
      closeTemplateSelector,
      preview,
      findPermission,
      urlTemplateToPreview,
      visibleTemplatePreview,
      newListName,
      closeRenameOverlay,
      renameTemplate,
      templateName,
      renameOverlayRef,
      childComponentRef,
      groupActions,
      idShop,
      isImportModalVisible,
      displayImportMethodSelectionModal,
      hideImportModal,
      displayImportTemplateModal,
      handleSelectedImportMethod,
    };
  },
});
