<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="choose-items-dialog"
    :header="t(header)"
    :modal="true"
    :dismissable-mask="displayCancelButton"
    :position="position"
    @update:visible="handleClose"
  >
    <InputText
      v-model="fieldValue"
      data-test-id="item-input"
      :style="{ minWidth: '30rem' }"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="error"
      field="fieldValue"
      class="text-left"
    />
    <template #footer>
      <Button
        v-if="displayCancelButton"
        data-test-id="input-text-button-cancel"
        :label="t(cancelButtonText)"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
      <Button
        data-test-id="input-text-button-ok"
        :label="t(validateButtonText)"
        :loading="loading"
        icon="far fa-check"
        class="p-button-primary"
        @click="handleValidation"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'InputTextModal',

  components: {
    Dialog,
    InputText,
    Button,
    FieldErrors,
  },

  props: {
    position: {
      type: String,
      required: false,
      default: 'center',
    },

    displayDialog: {
      type: Boolean,
      required: true,
    },

    displayCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeOnValidate: {
      type: Boolean,
      required: false,
      default: true,
    },

    header: {
      type: String,
      required: true,
    },

    cancelButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.cancel',
    },

    validateButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.validate',
    },

    inputValue: {
      type: String,
      required: false,
      default: '',
    },

    showLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    customValidation: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ['on-close-dialog', 'on-validate'],

  setup(props, context) {
    const { t } = useI18n();
    const error = ref();
    const componentFieldErrorsKey = ref(0);
    const displayModal: ComputedRef<boolean> = computed(() => props.displayDialog);
    const fieldValue: Ref<string> = ref(props.inputValue);

    const loading = ref(false);

    const state = reactive({
      fieldValue,
    });

    let rules: Record<string, any> = {
      fieldValue: { required },
    };
    if (props.customValidation) {
      rules = {
        fieldValue: props.customValidation,
      };
    }

    watch(() => props.inputValue, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        fieldValue.value = newValue;
      }
    });

    const handleClose = () => {
      context.emit('on-close-dialog');
      fieldValue.value = props.inputValue;
      error.value = null;
    };

    const handleValidation = async () => {
      error.value = null;

      const v$ = useVuelidate(rules, state);
      const success = await v$.value.$validate();

      if (!success) {
        error.value = v$;
        componentFieldErrorsKey.value += 1;
        return;
      }

      if (!props.showLoading) {
        context.emit('on-validate', fieldValue.value);

        if (props.closeOnValidate) {
          handleClose();
        }
      } else {
        loading.value = true;
        context.emit('on-validate', fieldValue.value, () => {
          loading.value = false;
          handleClose();
        });
      }
    };

    return {
      t,
      fieldValue,
      error,
      componentFieldErrorsKey,
      displayModal,
      loading,
      handleClose,
      handleValidation,
    };
  },
});
</script>
