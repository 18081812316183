
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import useDateFormat from '@/composables/useDateFormat';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import {
  CampaignsBulkStatusEnum,
  SpmTableAction,
  SpmTableColumns,
  SpmTableFilter,
  SpmTableFilterOption,
  SpmTableSort,
  TypeCampaignEnum,
} from '@/types';
import {
  CampaignsBulk,
  CampaignsBulkDuplicateInputItem,
  CampaignsBulkUpdateInputItem,
  OperatorType,
} from '@/types/generated-types/graphql';
import {
  activateCampaign,
  AutomatedScenarioState as stateAutomatedScenario,
  setAnalyticsMode,
  setRefreshScenariosList,
  updateCampaignBulk,
  getAutomatedCampaignOperators,
} from '@/composables/AutomatedScenarios';
import { registerExportRequest } from '@/composables/useExport';
import { asInt, showToastError, showToastSuccess } from '@/helpers';
import { findPermission, UserState, hasAccessToFeatures } from '@/composables/User';
import { duplicateBulkCampaign, getBulkCampaignStatus } from '@/composables/workflows/CampaignBulk';
import CampaignsBulkBaseSelector from '@/components/automated-scenarios/CampaignsBulkBaseSelector.vue';
import moment from 'moment';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import { store } from '@/store';
import InputText from 'primevue/inputtext';
import { getNowFormattedByTimezone } from '@/helpers/Date';
import { getNotConfiguredServices } from '@/composables/shop/MyShopParameters';
import { useRouter } from 'vue-router';
import Dialog from 'primevue/dialog';
import { getShopsCredits } from '@/composables/credits/Credits';
import ImportModal from '@/components/modals/ImportModal.vue';

export default defineComponent({
  name: 'BulkCampaignsList',

  components: {
    ImportModal,
    CampaignsBulkBaseSelector,
    SpmTable,
    ConfirmPopup,
    ConfirmDialog,
    SpmButton,
    InputText,
    Dialog,
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const spmTableKey = ref(0);
    const openWorkflowSelector = ref(false);
    const isGlobalLoading = ref(false);
    const newListName = ref('');
    const createCampaignLoading = ref(false);
    const showNeedOfferModal = ref(false);
    const childComponentRef = ref();
    const isImportModalVisible = ref(false);
    const { id, userType } = UserState.user;
    const dataExportPermission = hasAccessToFeatures('data.export');

    const confirm = useConfirm();
    const { toLocaleDateBigInt } = useDateFormat();
    const statusOptions: SpmTableFilterOption[] = Object.values(CampaignsBulkStatusEnum)
      // Do not keep ARCHIVED, ARCHIVED_BY_USER and DELETED options
      .filter((key: string) => !([CampaignsBulkStatusEnum.ARCHIVED, CampaignsBulkStatusEnum.DELETED] as string[]).includes(key))
      .map((key: string) => ({ value: key, label: t(`campaigns.bulk.list.status.${key}`) }));

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'status',
        value: CampaignsBulkStatusEnum.ARCHIVED,
        operator: OperatorType.NotEquals,
      },
      {
        field: 'status',
        value: CampaignsBulkStatusEnum.DELETED,
        operator: OperatorType.NotEquals,
      },
      {
        field: 'status',
        value: CampaignsBulkStatusEnum.ARCHIVED_BY_USER,
        operator: OperatorType.NotEquals,
      },
      {
        field: 'id_shop',
        value: idShop.value,
        operator: OperatorType.Equals,
      },
    ];

    const initialSorting: SpmTableSort[] = [
      {
        field: 'date_modification',
        type: 'DESC',
      },
    ];

    const registerExportRequestCommand = async (idsTobeExported: number[]) => {
      registerExportRequest(idsTobeExported, idShop.value, id, userType, 'campaign').then(async (result: any) => {
        await showToastSuccess(t('campaigns.bulk.export.exportSuccess'));
        childComponentRef.value.unselectAllRows();
      }).catch(async (err: any) => {
        await showToastError(t('campaigns.bulk.exportFailure'));
      });
    };

    const groupActions = [
      {
        code: 'export',
        label: t('campaigns.bulk.list.groupedActions.export.menuItem'),
        command: (event: {originalEvent: Event; item: any; navigate: undefined}) => {
          const command = () => {
            childComponentRef.value.toggleDialog(
              '',
              t('campaigns.bulk.list.groupedActions.export.text'),
              t('campaigns.bulk.list.groupedActions.export.formTitle'),
              '',
              t('yes'),
              t('no'),
              'custom',
              null,
              '',
              registerExportRequestCommand,
            );
          };
          if (dataExportPermission && !dataExportPermission.access) {
            store.commit('general/setIsFeatureUnavailableModalVisible', true);
            store.commit('general/setFeatureUnavailableMinPlan', dataExportPermission.minimumPlan);
            store.commit('general/setOnAuthorizedClickFunction', command);
          } else {
            command();
          }
        },
      },
    ];

    const idActiveShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const tmpActionCreateCampaign = async () => {
      const serviceParameterSuccessAction = () => {
        store.commit('general/showTheSpinner');

        const name = `${t('campaignPrefix')} - ${getNowFormattedByTimezone()}`;

        const workflowToDuplicateInput: CampaignsBulkDuplicateInputItem = {
          id_campaign_bulk: 1,
          id_shop_destination: idActiveShop.value,
          base_campaign: 1,
          name,
        };

        duplicateBulkCampaign(workflowToDuplicateInput).then((result) => {
          if (result.success && result.id) {
            const id = asInt(result.id);
            showToastSuccess(t('automatedScenarios.create_action.success'));
            setRefreshScenariosList(true);
            activateCampaign(id, TypeCampaignEnum.BULK).then(() => {
              store.commit('general/hideTheSpinner');
            });
          } else {
            showToastError(t('automatedScenarios.duplicate.error'));
            store.commit('general/hideTheSpinner');
          }
        }).catch(() => {
          showToastError(t('automatedScenarios.duplicate.error'));
          store.commit('general/hideTheSpinner');
        });
      };

      // Check active offer/plan
      const activePlan = UserState.activeOffer
        || (UserState.hasOfferV3 && UserState.offerV3 && ['2', '3'].includes(UserState.offerV3.bundle_options) && UserState.offerV3.max_newsletter > 0);
      const newsletterCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'newsletter', true);

      if (!activePlan && (!newsletterCredits || newsletterCredits === 0)) {
        // Display popin for switching to MyOffer page
        showNeedOfferModal.value = true;
        return;
      }

      createCampaignLoading.value = true;
      const notConfiguredServices = await getNotConfiguredServices(idShop.value, [
        'createScenarioCampaign',
      ]);
      if (notConfiguredServices.length) {
        store.commit('general/setNotConfiguredServices', notConfiguredServices);
        store.commit('general/setIsServicesParametersModalVisible', true);
        store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
      } else {
        serviceParameterSuccessAction();
      }
      createCampaignLoading.value = false;
    };

    const duplicateConfirmationDialog = (campaignsBulk: CampaignsBulk, event: any) => {
      newListName.value = `${t('campaignPrefix')} - ${getNowFormattedByTimezone()}`;
      confirm.require({
        group: 'customDialog',
        target: event.currentTarget,
        message: t('campaigns.bulk.list.actions.duplicate.message'),
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('validate'),
        rejectLabel: t('cancel'),
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          const serviceParameterSuccessAction = () => {
            isGlobalLoading.value = true;

            const workflowToDuplicateInput: CampaignsBulkDuplicateInputItem = {
              id_campaign_bulk: campaignsBulk.id_campaign_bulk,
              id_shop_destination: idShop.value,
              base_campaign: 0,
              name: newListName.value,
            };
            duplicateBulkCampaign(workflowToDuplicateInput).then((result) => {
              if (result.success && result.id) {
                showToastSuccess(t('campaigns.bulk.list.actions.duplicate.success'));
                spmTableKey.value += 1;
              } else {
                showToastError(t('campaigns.bulk.list.actions.duplicate.error'));
              }
              isGlobalLoading.value = false;
            }).catch(() => {
              showToastError(t('campaigns.bulk.list.actions.duplicate.error'));
              isGlobalLoading.value = false;
            });
          };

          isGlobalLoading.value = true;
          const operators = await getAutomatedCampaignOperators(campaignsBulk.id_campaign_bulk, TypeCampaignEnum.BULK);
          const notConfiguredServices = await getNotConfiguredServices(idShop.value, ['editScenarioCampaign', ...operators]);
          isGlobalLoading.value = false;
          if (notConfiguredServices.length) {
            store.commit('general/setNotConfiguredServices', notConfiguredServices);
            store.commit('general/setIsServicesParametersModalVisible', true);
            store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
          } else {
            serviceParameterSuccessAction();
          }
        },
      });
    };

    const removeConfirmationDialog = async (campaignsBulk: CampaignsBulk, event: any) => {
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: t('templates.confirm.delete.message'),
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          isGlobalLoading.value = true;

          /*  If the campaign status in the client is not the same as the server,
              we prevent the action, and we refresh the list of campaigns */
          const currentStatus = await getBulkCampaignStatus(campaignsBulk.id_campaign_bulk);
          if (currentStatus && (currentStatus === (campaignsBulk.status as CampaignsBulkStatusEnum))) {
            const input: CampaignsBulkUpdateInputItem = {
              id_campaign_bulk: campaignsBulk.id_campaign_bulk,
              id_shop: UserState.activeShop?.id ?? 0,
              status: CampaignsBulkStatusEnum.DELETED,
              date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            await updateCampaignBulk([input]).then((result: any) => {
              showToastSuccess('Votre campagne a été supprimée avec succès.');
              setRefreshScenariosList(true);
              isGlobalLoading.value = false;
            }).catch((err: Error) => {
              showToastError(err.message);
              isGlobalLoading.value = false;
            });
          } else {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
            setRefreshScenariosList(true);
            isGlobalLoading.value = false;
          }
        },
      });
    };

    const archiveConfirmationDialog = async (campaignsBulk: CampaignsBulk, event: any) => {
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: 'Vous êtes sûr de vouloir archiver cette campagne ?',
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          isGlobalLoading.value = true;

          /*  If the campaign status in the client is not the same as the server,
              we prevent the action, and we refresh the list of campaigns */
          const currentStatus = await getBulkCampaignStatus(campaignsBulk.id_campaign_bulk);
          if (currentStatus && (currentStatus === (campaignsBulk.status as CampaignsBulkStatusEnum))) {
            const input: CampaignsBulkUpdateInputItem = {
              id_campaign_bulk: campaignsBulk.id_campaign_bulk,
              id_shop: UserState.activeShop?.id ?? 0,
              status: CampaignsBulkStatusEnum.ARCHIVED_BY_USER,
              date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            await updateCampaignBulk([input]).then((result: any) => {
              showToastSuccess('Votre campagne a été archivée avec succès.');
              setRefreshScenariosList(true);
              isGlobalLoading.value = false;
            }).catch((err: Error) => {
              showToastError(err.message);
              isGlobalLoading.value = false;
            });
          } else {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
            setRefreshScenariosList(true);
            isGlobalLoading.value = false;
          }
        },
      });
    };

    const suspendConfirmationDialog = async (campaignsBulk: CampaignsBulk, event: any) => {
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: 'Vous êtes sûr de vouloir suspendre l\'envoi de cette campagne ?',
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          isGlobalLoading.value = true;
          /*  If the campaign status in the client is not the same as the server,
              we prevent the action, and we refresh the list of campaigns */
          const currentStatus = await getBulkCampaignStatus(campaignsBulk.id_campaign_bulk);
          if (currentStatus && (currentStatus === (campaignsBulk.status as CampaignsBulkStatusEnum)) && [CampaignsBulkStatusEnum.SENDING].includes(currentStatus)) {
            const input: CampaignsBulkUpdateInputItem = {
              id_campaign_bulk: campaignsBulk.id_campaign_bulk,
              id_shop: UserState.activeShop?.id ?? 0,
              status: CampaignsBulkStatusEnum.SUSPENDED_BY_USER,
              date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            await updateCampaignBulk([input]).then((result: any) => {
              showToastSuccess('Votre campagne a été suspendue avec succès.');
              setRefreshScenariosList(true);
              isGlobalLoading.value = false;
            }).catch((err: Error) => {
              showToastError(err.message);
              isGlobalLoading.value = false;
            });
          } else {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
            setRefreshScenariosList(true);
            isGlobalLoading.value = false;
          }
        },
      });
    };

    const confirmEditScheduledDialog = async (campaignsBulk: CampaignsBulk) => {
      // Check active offer/plan
      const activePlan = UserState.activeOffer
        || (UserState.hasOfferV3 && UserState.offerV3 && ['2', '3'].includes(UserState.offerV3.bundle_options) && UserState.offerV3.max_newsletter > 0);
      const newsletterCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'newsletter', true);

      if (!activePlan && (!newsletterCredits || newsletterCredits === 0)) {
        // Display popin for switching to MyOffer page
        showNeedOfferModal.value = true;
        return;
      }

      confirm.require({
        message: t('campaigns.bulk.messages.warning_edit_scheduled'),
        header: 'Confirmation',
        group: 'editScheduled',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('understood'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          await activateCampaign(campaignsBulk.id_campaign_bulk, TypeCampaignEnum.BULK);
        },
      });
    };

    // Actions
    const actions: SpmTableAction[] = [
      {
        /* Visualize */
        icon: 'far fa-eye',
        label: t('campaigns.bulk.list.actions.preview'),
        callback: (campaignsBulk: CampaignsBulk) => {
          if (campaignsBulk) {
            store.commit('general/showTheSpinner');
            getBulkCampaignStatus(campaignsBulk.id_campaign_bulk).then(async (currentStatus: any) => {
              /*  If the campaign status in the client is not the same as the server,
               we prevent the action, and we refresh the list of campaigns */
              if (currentStatus && (currentStatus === (campaignsBulk.status as CampaignsBulkStatusEnum))) {
                activateCampaign(campaignsBulk.id_campaign_bulk, TypeCampaignEnum.BULK, campaignsBulk.date_send).then(async () => {
                  store.commit('general/hideTheSpinner');
                  await setAnalyticsMode(true);
                });
              } else {
                await showToastError(t('errorMessages.GENERIC_ERROR'));
                setRefreshScenariosList(true);
                store.commit('general/hideTheSpinner');
              }
            });
          }
        },

        show: (campaignsBulk: CampaignsBulk) => [
          CampaignsBulkStatusEnum.SENT,
          CampaignsBulkStatusEnum.SENDING,
          CampaignsBulkStatusEnum.SUSPENDED_BY_USER,
          CampaignsBulkStatusEnum.SUSPENDED,
          CampaignsBulkStatusEnum.ARCHIVED,
        ].includes(campaignsBulk.status as CampaignsBulkStatusEnum) && findPermission('bulk_campaigns.view'),
      },
      {
        /* Stop Sending */
        icon: 'far fa-hand-paper',
        class: 'p-button-danger',
        label: 'Arrêter l\'envoi',
        callback: (campaignsBulk: CampaignsBulk, event: any) => {
          if (event && campaignsBulk) {
            return suspendConfirmationDialog(campaignsBulk, event);
          }
          return false;
        },

        show: (campaignsBulk: CampaignsBulk) => [
          CampaignsBulkStatusEnum.SENDING,
        ].includes(campaignsBulk.status as CampaignsBulkStatusEnum) && findPermission('bulk_campaigns.stop_send'),
      },
      {
        /* Edit */
        icon: 'far fa-edit',
        label: 'Editer',
        callback: async (campaignsBulk: CampaignsBulk) => {
          if (campaignsBulk) {
            const serviceParameterSuccessAction = () => {
              store.commit('general/showTheSpinner');
              getBulkCampaignStatus(campaignsBulk.id_campaign_bulk).then(async (currentStatus: any) => {
                /*  If the campaign status in the client is not the same as the server,
                we prevent the action, and we refresh the list of campaigns */
                if (currentStatus && (currentStatus === (campaignsBulk.status as CampaignsBulkStatusEnum))) {
                  if (![CampaignsBulkStatusEnum.DRAFT, CampaignsBulkStatusEnum.BEING_EDITED, CampaignsBulkStatusEnum.SCHEDULED].includes(currentStatus)) {
                    showToastError(t('errorMessages.GENERIC_ERROR'));
                    setRefreshScenariosList(true);
                    store.commit('general/hideTheSpinner');
                    return;
                  }

                  if (currentStatus === CampaignsBulkStatusEnum.SCHEDULED) {
                    confirmEditScheduledDialog(campaignsBulk).then(() => {
                      store.commit('general/hideTheSpinner');
                    });
                    return;
                  }
                  activateCampaign(campaignsBulk.id_campaign_bulk, TypeCampaignEnum.BULK).then(() => {
                    store.commit('general/hideTheSpinner');
                  });
                } else {
                  await showToastError(t('errorMessages.GENERIC_ERROR'));
                  setRefreshScenariosList(true);
                  store.commit('general/hideTheSpinner');
                }
              });
            };

            // Check active offer/plan
            const activePlan = UserState.activeOffer
              || (UserState.hasOfferV3 && UserState.offerV3 && ['2', '3'].includes(UserState.offerV3.bundle_options) && UserState.offerV3.max_newsletter > 0);
            const newsletterCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'newsletter', true);

            if (!activePlan && (!newsletterCredits || newsletterCredits === 0)) {
              // Display popin for switching to MyOffer page
              showNeedOfferModal.value = true;
              return;
            }

            const operators = await getAutomatedCampaignOperators(campaignsBulk.id_campaign_bulk, TypeCampaignEnum.BULK);
            const notConfiguredServices = await getNotConfiguredServices(idShop.value, ['editScenarioCampaign', ...operators]);
            if (notConfiguredServices.length) {
              store.commit('general/setNotConfiguredServices', notConfiguredServices);
              store.commit('general/setIsServicesParametersModalVisible', true);
              store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
            } else {
              serviceParameterSuccessAction();
            }
          }
        },

        show: (campaignsBulk: CampaignsBulk) => [
          CampaignsBulkStatusEnum.DRAFT, CampaignsBulkStatusEnum.BEING_EDITED, CampaignsBulkStatusEnum.SCHEDULED,
        ].includes(campaignsBulk.status as CampaignsBulkStatusEnum) && findPermission('bulk_campaigns.edit'),
      },
      {
        /* Duplicate */
        icon: 'far fa-clone',
        label: 'Dupliquer',
        callback: async (campaignsBulk: CampaignsBulk, event: any) => {
          if (event && campaignsBulk) {
            return duplicateConfirmationDialog(campaignsBulk, event);
          }
          return false;
        },

        show: () => findPermission('bulk_campaigns.duplicate'),
      },
      {
        /* Archived */
        icon: 'far fa-archive',
        label: 'Archiver',
        callback: (campaignsBulk: CampaignsBulk, event: any) => {
          if (event && campaignsBulk) {
            return archiveConfirmationDialog(campaignsBulk, event);
          }
          return false;
        },

        show: (campaignsBulk: CampaignsBulk) => [
          CampaignsBulkStatusEnum.SENT, CampaignsBulkStatusEnum.SUSPENDED_BY_USER, CampaignsBulkStatusEnum.SUSPENDED,
        ].includes(campaignsBulk.status as CampaignsBulkStatusEnum) && findPermission('bulk_campaigns.archive'),
      },
      {
        /* Delete */
        icon: 'far fa-trash',
        label: 'Supprimer',
        callback: (campaignsBulk: CampaignsBulk, event: any) => {
          if (event && campaignsBulk) {
            return removeConfirmationDialog(campaignsBulk, event);
          }
          return false;
        },

        show: (campaignsBulk: CampaignsBulk) => [
          CampaignsBulkStatusEnum.DRAFT, CampaignsBulkStatusEnum.SCHEDULED,
        ].includes(campaignsBulk.status as CampaignsBulkStatusEnum) && findPermission('bulk_campaigns.delete'),
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_campaign_bulk', header: '', sortable: false, filterable: false, editable: false, style: 'display:none', type: 'text',
      },
      {
        field: 'date_send', header: '', sortable: false, filterable: false, editable: false, style: 'display:none', type: 'text',
      },
      {
        field: 'name',
        header: t('campaigns.bulk.list.columns.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:70%',
        type: 'text',
      },
      {
        field: 'date_modification',
        header: t('campaigns.bulk.list.columns.last_update'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'status',
        header: t('campaigns.bulk.list.columns.status'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: statusOptions, hideFilterMenu: true },
      },
      {
        field: '',
        header: t('actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:15%',
        type: 'action',
        actions,
      },
    ];

    const hideImportModal = (needReload: boolean) => {
      isImportModalVisible.value = false;
      if (needReload) {
        setRefreshScenariosList(true);
      }
    };

    const showImportModal = () => {
      isImportModalVisible.value = true;
    };

    const onColumnValueEdit = async (field: string, data: any) => {
      const input: CampaignsBulkUpdateInputItem = {
        id_campaign_bulk: data.id_campaign_bulk,
        id_shop: UserState.activeShop?.id ?? 0,
        [field]: data[field],
        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      await updateCampaignBulk([input]).then((result: any) => {
        showToastSuccess('Le nom de la campagne a été modifié avec succès');
      }).catch((err: Error) => {
        showToastError(err.message);
      });
    };

    const goToMyOffer = async () => {
      await router.push({ name: 'shop.MyOffer' });
    };

    watch(() => stateAutomatedScenario.refreshScenariosList, () => {
      if (stateAutomatedScenario.refreshScenariosList) {
        spmTableKey.value += 1;
        setRefreshScenariosList(false);
      }
    });

    onMounted(() => {
      setRefreshScenariosList(false);
    });

    return {
      t,
      spmTableKey,
      CampaignsBulkStatusEnum,
      columns,
      persistentFilters,
      initialSorting,
      toLocaleDateBigInt,
      openWorkflowSelector,
      onColumnValueEdit,
      findPermission,
      isGlobalLoading,
      newListName,
      tmpActionCreateCampaign,
      createCampaignLoading,
      idShop,
      childComponentRef,
      groupActions,
      showImportModal,
      isImportModalVisible,
      hideImportModal,
      showNeedOfferModal,
      goToMyOffer,
    };
  },
});
