// eslint-disable-next-line import/no-cycle
import { Templates, TemplatesElements, UsersPagesConfiguration } from './generated-types/graphql';

export interface ExportRequestBody {
  idShop: number;
  type: string;
  idsTobeExported: number[];
  idUser: number;
  userType: string;
}

export interface WorkflowDefinition {
  uid: string;
  name: string;
  legend?: string;
  parameters?: string;
  flowchart?: Buffer | string;
}

export interface TemplateDefinition extends Templates {
  uid: string;
  name?: string;
  template_elements: TemplatesElements[];
  redirections?: ExportResultElement[];
}

export interface UsersPagesConfigurationDefinition extends UsersPagesConfiguration {
  uid: string;
}

export function isTemplateDefinition(
  definition:
    WorkflowDefinition |
    TemplateDefinition |
    UsersPagesConfigurationDefinition,
): definition is TemplateDefinition {
  return (definition as TemplateDefinition).template_elements !== undefined;
}

export function isUsersPagesConfigurationDefinition(
  definition:
    WorkflowDefinition |
    TemplateDefinition |
    UsersPagesConfigurationDefinition,
): definition is UsersPagesConfigurationDefinition {
  return (definition as UsersPagesConfigurationDefinition).page_name !== undefined;
}

export function isWorkflowDefinition(
  definition:
    WorkflowDefinition |
    TemplateDefinition |
    UsersPagesConfigurationDefinition,
): definition is WorkflowDefinition {
  return (definition as WorkflowDefinition).flowchart !== undefined;
}

export interface ExportResultElement {
  definition: WorkflowDefinition | TemplateDefinition;
  templates?: TemplateDefinition[];
}

export interface ExportResult {
  elements: ExportResultElement[];
  fromShop: number;
  type: string;
  date: string;
}
