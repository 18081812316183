import {
  Get,
  List,
  ListResult,
} from '@/composables/GraphQL';

import { SpmTableFilter, SpmTableSort } from '@/types';

import {
  OperatorType,
  ShopsCustomers,
  ShopsCustomersAddresses,
  ShopsCustomersGroups,
  ShopsCustomersGroupsList,
  ShopsCustomersShopsLists,
  ShopsLists,
  EmailsRejectsShops,
  ShopsCustomersPhones,
  ShopsOrders,
  ShopsCustomersTags,
  ShopsOrdersStatus,
} from '@/types/generated-types/graphql';

import {
  Cart,
  CartProduct,
  ContactAddress,
  ContactFile,
  ContactGroup,
  ContactReject,
  DataListResult,
  Order,
  OrderProduct,
} from '@/types/data-explorer-types';
import LIST_ROWS from '@/components/data-explorer/utils/constants';

const isContactAnonymous = (email: string) => {
  if (email.length !== 32) {
    return false;
  }

  const hexRegex = /^[a-f0-9]{32}$/i;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return hexRegex.test(email) && !emailRegex.test(email);
};

export const getCustomer = async (idShopCustomer: number): Promise<ContactFile | null> => {
  const { item, err } = await Get<ShopsCustomers>({
    name: 'ShopsCustomers',
    id: idShopCustomer,
    keyName: 'id_shop_customer',
    fields: [
      'id_shop_customer',
      'id_customer_shop',
      'first_name',
      'last_name',
      'email',
      'gender',
      'birthday',
      'opt_in',
      'newsletter',
      'country',
      'lang',
      'active',
      'type',
      'deleted',
      'date_creation',
      'date_modification',
      'unsub_type',
      'date',
    ],
  });

  if (!err && item) {
    const contactFile: ContactFile = {
      isAnonymous: isContactAnonymous(item.email),
      generalInformation: {
        data: {
          active: item.active,
          birthday: item.birthday || '',
          country: item.country || '',
          date: item.date,
          dateCreation: item.date_creation,
          dateModification: item.date_modification,
          email: item.email,
          firstName: item.first_name || '',
          gender: item.gender,
          idCustomerShop: item.id_customer_shop || '',
          idShopCustomer: item.id_shop_customer,
          lang: item.lang || '',
          lastName: item.last_name || '',
          newsletter: item.newsletter || '',
          type: item.type,
          unSubType: item.unsub_type,
          mobile: '',
          tags: [],
        },
        dataLoaded: true,
      },
      addresses: {
        data: [],
        dataLoaded: false,
      },
      groups: {
        data: [],
        dataLoaded: false,
      },
      rejects: {
        data: [],
        dataLoaded: false,
      },
      shops: {
        data: [],
        dataLoaded: false,
      },
      orders: {
        data: [],
        dataLoaded: false,
        total: 0,
        currentPage: 0,
      },
      carts: {
        data: [],
        dataLoaded: false,
        total: 0,
        currentPage: 0,
      },
    };
    if (!contactFile.isAnonymous) {
      const customersPhones = await List<ShopsCustomersPhones>(
        {
          name: 'ShopsCustomersPhones',
          settings: {
            limit: 1,
            offset: 0,
            order: [
              { field: 'id_shop_customer_address', type: 'DESC' },
            ],
            filter: [
              { field: 'id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer },
              { field: 'mobile_count', operator: OperatorType.GreaterThan, value: 0 },
            ],
          },
          fields: [
            'mobile1',
            'mobile2',
          ],
        },
      );
      if (!customersPhones.err && customersPhones.items.length) {
        contactFile.generalInformation.data.mobile = customersPhones.items[0].mobile1 || customersPhones.items[0].mobile2 || '';
      }

      const customersTags = await List<ShopsCustomersTags>({
        name: 'ShopsCustomersTags',
        settings: {
          filter: [
            { field: 'id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer },
          ],
          limit: 0,
          offset: 0,
          order: [
            { field: 'id_shop', type: 'ASC' },
          ],
        },
        fields: ['id_shop', 'id_shop_customer', 'shopsTags{tag}'],
      });

      if (!customersTags.err && customersTags.items.length) {
        customersTags.items.forEach((customerTag) => {
          if (customerTag.shopsTags) {
            customerTag.shopsTags.forEach((tag) => {
              if (tag) {
                contactFile.generalInformation.data.tags.push(tag.tag ?? '');
              }
            });
          }
        });
        contactFile.generalInformation.data.tags = contactFile.generalInformation.data.tags.filter((tag) => tag !== '');
      }
    }

    return contactFile;
  }

  return null;
};

const mapAddress = (items: ShopsCustomersAddresses[]): ContactAddress[] => items.map((item) => ({
  active: item.active || '',
  address1: item.address1 || '',
  address2: item.address2 || '',
  city: item.city || '',
  country: item.country || '',
  idShopCustomerAddress: item.id_shop_customer_address,
  latitude: item.latitude || null,
  longitude: item.longitude || null,
  phone1: item.phone1 || '',
  phone2: item.phone2 || '',
  postcode: item.postcode || '',
  region: item.region || '',
  idAddressShop: item.id_address_shop || -1,
  idShopCustomer: item.id_shop_customer,
}));

const getAddresses = async (filter: SpmTableFilter[], order: SpmTableSort[] = [], limit = 0): Promise<ListResult<ShopsCustomersAddresses>> => {
  const customersAddresses = await List<ShopsCustomersAddresses>(
    {
      name: 'ShopsCustomersAddresses',
      settings: {
        limit,
        offset: 0,
        order,
        filter,
      },
      fields: [
        'id_shop_customer_address',
        'address1',
        'address2',
        'phone1',
        'phone2',
        'postcode',
        'region',
        'city',
        'country',
        'latitude',
        'longitude',
        'active',
        'id_address_shop',
        'id_shop_customer',
      ],
    },
  );
  return customersAddresses;
};

export const getContactAddresses = async (idShopCustomer: number): Promise<DataListResult> => {
  const customersAddresses = await getAddresses(
    [
      { field: 'id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer },
      { field: 'active', operator: OperatorType.Equals, value: '1' },
    ],
  );
  if (!customersAddresses.err && customersAddresses.items.length) {
    return {
      total: 0,
      lists: mapAddress(customersAddresses.items),
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getAddressByIdShopAddress = async (idAddressShop: number): Promise<ContactAddress|null> => {
  const customersAddress = await getAddresses([{ field: 'id_address_shop', operator: OperatorType.Equals, value: idAddressShop }]);
  if (!customersAddress.err && customersAddress.items.length) {
    const addresses = mapAddress(customersAddress.items);
    return addresses[0];
  }
  return null;
};

export const getContactShops = async (idShopCustomer: number, locale: string): Promise<DataListResult> => {
  const shopCustomersLists = await List<ShopsCustomersShopsLists>(
    {
      name: 'ShopsCustomersShopsLists',
      settings: {
        limit: 1,
        offset: 0,
        order: [],
        filter: [
          { field: 'sc.id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer },
          { field: 'locale', operator: OperatorType.Equals, value: locale },
        ],
      },
      fields: [
        'id_shop_customer',
        'id_shop_lists',
      ],
    },
  );

  if (!shopCustomersLists.err && shopCustomersLists.items.length) {
    const idShopLists = shopCustomersLists.items[0]?.id_shop_lists;
    if (idShopLists?.length) {
      const { items, err } = await List<ShopsLists>({
        name: 'ShopsLists',
        settings: {
          filter: [
            {
              field: 'active',
              operator: OperatorType.Equals,
              value: 1,
            },
            {
              field: 'id_shop_list',
              operator: OperatorType.In,
              value: idShopLists ?? '',
            },
            {
              field: 'valid',
              operator: OperatorType.Equals,
              value: 1,
            },
          ],
          order: [
            { field: 'id_shop_list', type: 'ASC' },
          ],
          limit: 0,
          offset: 0,
        },
        fields: ['id_shop_list', 'name'],
      });

      if (items && items.length) {
        return {
          total: 0,
          lists: items.map((item) => (item.name)),
        };
      }
    }
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getContactGroups = async (idShopCustomer: number): Promise<DataListResult> => {
  const resShopsCustomersGroups = await List<ShopsCustomersGroups>(
    {
      name: 'ShopsCustomersGroups',
      settings: {
        limit: 0,
        offset: 0,
        order: [
          { field: 'id_shop_customer_group', type: 'ASC' },
        ],
        filter: [{ field: 'id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer }],
      },
      fields: [
        'id_shop_customer_group',
        'id_shop_customer',
        'id_shop',
        'group',
        'date_modification',
        'date',
      ],
    },
  );
  if (!resShopsCustomersGroups.err && resShopsCustomersGroups.items.length) {
    const groups = await List<ShopsCustomersGroupsList>(
      {
        name: 'ShopsCustomersGroupsList',
        settings: {
          limit: 0,
          offset: 0,
          order: [],
          filter: [
            { field: 'id_group', operator: OperatorType.Contains, value: resShopsCustomersGroups.items.map((item) => item.group).join(',') },
          ],
        },
        fields: [
          'id_group',
          'name',
          'id_shop',
        ],
      },
    );
    if (!groups.err && groups.items) {
      const contactGroups: ContactGroup[] = groups.items.map((item) => ({
        idGroup: item.id_group,
        name: item.name || '',
      }));
      return {
        total: 0,
        lists: contactGroups,
      };
    }
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getContactRejects = async (idShopCustomer: number): Promise<DataListResult> => {
  const emailsRejects = await List<EmailsRejectsShops>(
    {
      name: 'EmailsRejectsShops',
      settings: {
        limit: 3,
        offset: 0,
        order: [
          { field: 'date', type: 'DESC' },
        ],
        filter: [{ field: 'id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer }],
      },
      fields: [
        'id_email_reject',
        'id_shop_customer',
        'id_shop',
        'reject_type',
        'reject_from',
        'reject_message',
        'ems_category',
        'email',
        'date',
      ],
    },
  );
  if (!emailsRejects.err && emailsRejects.items.length) {
    const rejects: ContactReject[] = emailsRejects.items.map((item) => ({
      date: item.date,
      idEmailReject: item.id_email_reject,
      rejectFrom: item.reject_from || '',
      rejectMessage: item.reject_message || '',
      rejectType: item.reject_type,
    }));
    return {
      total: 0,
      lists: rejects,
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

const mapOrders = (items: ShopsOrders[]): Order[] => items.map((item) => ({
  idShopOrder: item.id_shop_order,
  idOrder: item.id_order,
  orderReference: item.order_reference ?? '',
  amount: item.amount,
  dateCreation: item.date_creation,
  dateModification: item.date_modification,
  idAddressDelivery: item.id_address_delivery,
  idAddressInvoice: item.id_address_invoice,
  date: item.date,
  currency: item.currency ?? 'EUR',
  status: item.status ?? '',
  amountHT: item.amount_without_tax,
  voucherAmount: item.voucher_amount || null,
  firstname: item.first_name || '',
  lastname: item.last_name || '',
  idCustomerShop: item.id_customer_shop || '',
  email: item.email || '',
  idShopCustomer: item.id_shop_customer,
  dateCart: item.date_cart,
  idCart: item.id_cart,
  isValid: item.is_valid,
  shipping: item.shipping,
  shippingHT: item.shipping_without_tax,
  carrierName: item.name ?? '',
  shippingNumber: item.shipping_number ?? '',
  voucher: item.voucher ?? '',
  isAnonymous: isContactAnonymous(item.email || ''),
  deliveryAddress: {
    data: [],
    dataLoaded: false,
  },
  invoiceAddress: {
    data: [],
    dataLoaded: false,
  },
  products: {
    data: [],
    dataLoaded: false,
  },
  statusLoaded: false,
}));

const getOrders = async (filter: SpmTableFilter[], limit = 0, offset = 0): Promise<ListResult<ShopsOrders>> => {
  const orders = await List<ShopsOrders>(
    {
      name: 'ShopsOrdersCustom',
      settings: {
        limit,
        offset,
        order: [
          { field: 'so.date', type: 'DESC' },
        ],
        filter,
      },
      fields: [
        'id_shop_order',
        'id_order',
        'order_reference',
        'amount',
        'date_creation',
        'date_modification',
        'id_address_delivery',
        'id_address_invoice',
        'date',
        'currency',
        'voucher_amount',
        'amount_without_tax',
        'first_name',
        'last_name',
        'id_customer_shop',
        'email',
        'id_shop_customer',
        'is_valid',
        'shipping_number',
        'voucher',
        'shipping',
        'shipping_without_tax',
        'id_cart',
        'date_cart',
        'name',
        'voucher',
        'status',
      ],
    },
  );
  return orders;
};

export const getAddressOrders = async (idAddress: number, field: string, offset = 0): Promise<DataListResult> => {
  const shopOrders = await getOrders([{ field, operator: OperatorType.Equals, value: idAddress }], LIST_ROWS, offset);
  if (!shopOrders.err && shopOrders.items.length) {
    return {
      total: shopOrders.total,
      lists: mapOrders(shopOrders.items),
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getContactOrders = async (idShopCustomer: number, offset = 0): Promise<DataListResult> => {
  const shopOrders = await getOrders([{ field: 'so.id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer }], LIST_ROWS, offset);
  if (!shopOrders.err && shopOrders.items.length) {
    return {
      total: shopOrders.total,
      lists: mapOrders(shopOrders.items),
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getOrder = async (idShopOrder: number, field = 'id_shop_order'): Promise<Order|null> => {
  const shopOrders = await getOrders([{ field: `so.${field}`, operator: OperatorType.Equals, value: idShopOrder }], 1);
  if (!shopOrders.err && shopOrders.items.length) {
    const orders = mapOrders(shopOrders.items);
    return orders[0];
  }
  return null;
};

export const getOrderStatus = async (status: any, locale: string): Promise<string> => {
  const ordersStatus = await List<ShopsOrdersStatus>(
    {
      name: 'ShopsOrdersStatus',
      settings: {
        limit: 1,
        offset: 0,
        order: [],
        filter: [
          { field: 'id_status', operator: OperatorType.Equals, value: status },
          { field: 'lang', operator: OperatorType.Equals, value: locale.toLowerCase() },
        ],
      },
      fields: [
        'name',
      ],
    },
  );
  if (!ordersStatus.err && ordersStatus.items.length) {
    const orderStatus = ordersStatus.items[0];
    return orderStatus.name;
  }
  return '';
};

export const getOrdersProducts = async (idShopOrder: number, locale: string): Promise<DataListResult> => {
  const ordersProducts = await List<any>(
    {
      name: 'ShopsOrdersProducts',
      settings: {
        limit: 0,
        offset: 0,
        order: [],
        filter: [
          { field: 'sop.id_shop_order', operator: OperatorType.Equals, value: idShopOrder },
          { field: 'sp.lang', operator: OperatorType.Equals, value: locale.toLowerCase() },
        ],
      },
      fields: [
        'id_shop_order_product',
        'id_shop_product',
        'id_shop_product_attribute',
        'id_product',
        'id_combination',
        'id_manufacturer',
        'price',
        'qty',
        'name',
        'spLink',
        'spImage_link',
        'reference',
        'spaLink',
        'spaImage_link',
        'spaReference',
        'combination_name',
      ],
    },
  );

  if (!ordersProducts.err && ordersProducts.items.length) {
    const products = ordersProducts.items.map((item) => {
      const orderProduct: OrderProduct = {
        idShopOrderProduct: item.id_shop_order_product,
        idShopProduct: item.id_shop_product,
        idShopProductAttribute: item.id_shop_product_attribute,
        idProduct: item.id_product,
        idCombination: item.id_combination,
        price: item.price,
        quantity: item.qty,
        productReference: item.reference,
        productName: item.name,
        productLink: item.spLink,
        productImageLink: item.spImage_link,
        combinationName: item.combination_name,
        combinationLink: item.spaLink,
        combinationImageLink: item.spaImage_link,
        combinationReference: item.spaReference,
      };
      return orderProduct;
    });
    return {
      total: 0,
      lists: products,
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

const getCarts = async (filter: SpmTableFilter[], limit = 0, offset = 0): Promise<ListResult<any>> => {
  const orders = await List<any>(
    {
      name: 'ShopsCartsCustom',
      settings: {
        limit,
        offset,
        order: [
          { field: 'sc.date', type: 'DESC' },
        ],
        filter,
      },
      fields: [
        'id_shop_cart',
        'id_shop_customer',
        'id_cart',
        'amount',
        'amount_without_tax',
        'voucher_amount',
        'currency',
        'voucher',
        'is_valid',
        'date_modification',
        'date',
        'hash',
        'first_name',
        'last_name',
        'id_customer_shop',
        'email',
      ],
    },
  );
  return orders;
};

const mapCarts = (items: any[]): Cart[] => items.map((item) => ({
  idShopCart: item.id_shop_cart,
  idShopCustomer: item.id_shop_customer,
  idCart: item.id_cart,
  amount: item.amount,
  amountHT: item.amount_without_tax,
  voucherAmount: item.voucher_amount || 0,
  currency: item.currency || 'EUR',
  voucher: item.voucher || '',
  isValid: item.is_valid,
  dateModification: item.date_modification,
  date: item.date,
  hash: item.hash,
  firstname: item.first_name || '',
  lastname: item.last_name || '',
  idCustomerShop: item.id_customer_shop,
  email: item.email,
  products: {
    data: [],
    dataLoaded: false,
  },
  isAnonymous: isContactAnonymous(item.email || ''),
}));

export const getCart = async (idCart: string): Promise<Cart|null> => {
  const shopCarts = await getCarts([{ field: 'sc.id_cart', operator: OperatorType.Equals, value: idCart }], 1);
  if (!shopCarts.err && shopCarts.items.length) {
    const carts = mapCarts(shopCarts.items);
    return carts[0];
  }
  return null;
};

export const getContactCarts = async (idShopCustomer: number, offset = 0): Promise<DataListResult> => {
  const shopCarts = await getCarts([{ field: 'scu.id_shop_customer', operator: OperatorType.Equals, value: idShopCustomer }], LIST_ROWS, offset);
  if (!shopCarts.err && shopCarts.items.length) {
    return {
      total: shopCarts.total,
      lists: mapCarts(shopCarts.items),
    };
  }
  return {
    total: 0,
    lists: [],
  };
};

export const getCartProducts = async (idShopCart: number, locale: string): Promise<DataListResult> => {
  const cartProducts = await List<any>(
    {
      name: 'ShopsCartsProductsCustom',
      settings: {
        limit: 0,
        offset: 0,
        order: [],
        filter: [
          { field: 'scp.id_shop_cart', operator: OperatorType.Equals, value: idShopCart },
          { field: 'sp.lang', operator: OperatorType.Equals, value: locale.toLowerCase() },
        ],
      },
      fields: [
        'id_shop_cart_product',
        'id_shop_product',
        'id_shop_product_attribute',
        'id_product',
        'id_combination',
        'id_manufacturer',
        'price',
        'qty',
        'name',
        'spLink',
        'spImage_link',
        'reference',
        'spaLink',
        'spaImage_link',
        'spaReference',
        'combination_name',
      ],
    },
  );

  if (!cartProducts.err && cartProducts.items.length) {
    const products = cartProducts.items.map((item) => {
      const cartProduct: CartProduct = {
        idShopCartProduct: item.id_shop_cart_product,
        idShopProduct: item.id_shop_product,
        idShopProductAttribute: item.id_shop_product_attribute,
        idProduct: item.id_product,
        idCombination: item.id_combination,
        price: item.price,
        quantity: item.qty,
        productReference: item.reference,
        productName: item.name,
        productLink: item.spLink,
        productImageLink: item.spImage_link,
        combinationName: item.combination_name,
        combinationLink: item.spaLink,
        combinationImageLink: item.spaImage_link,
        combinationReference: item.spaReference,
      };
      return cartProduct;
    });
    return {
      total: 0,
      lists: products,
    };
  }
  return {
    total: 0,
    lists: [],
  };
};
