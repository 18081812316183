
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import {
  defineComponent,
  reactive, ref, watch,
} from 'vue';
import { GlobalSettingsData, Period, DateRangeWorkflow } from '@/types/automated-scenarios';
import { asInt, showToastError } from '@/helpers';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import {
  AutomatedScenarioState as state,
  setGlobalSettingsData,
} from '@/composables/AutomatedScenarios';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { MarketingWorkflowTypeEnum } from '@/types';

export default defineComponent({
  name: 'SettingsPanelAutomation',

  components: {
    FieldErrors,
    PeriodSelector,
    InputText,
    ButtonGroup,
    Calendar,
    Button,
    Card,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: {settingPanelValidationErrors: any }) {
    const { t } = useI18n();
    const locale = useI18n().locale.value;
    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const settingsFields: GlobalSettingsData = reactive({
      name: (state.scenario.settingsData as GlobalSettingsData).name,
      type_workflow: (state.scenario.settingsData as GlobalSettingsData).type_workflow,
      unique: (state.scenario.settingsData as GlobalSettingsData).unique,
      type_unique: (state.scenario.settingsData as GlobalSettingsData).type_unique,
      type_retrigger: (state.scenario.settingsData as GlobalSettingsData).type_retrigger,
      time_unit_select: (state.scenario.settingsData as GlobalSettingsData).time_unit_select,
      program_date_ranges: (state.scenario.settingsData as GlobalSettingsData).program_date_ranges,
      date_ranges: (state.scenario.settingsData as GlobalSettingsData).date_ranges,
    });

    const optionsFields = {
      type_workflow: [
        { value: MarketingWorkflowTypeEnum.MARKETING, label: t('automatedScenarios.globalSettings.type_workflow.options.marketing') },
        { value: MarketingWorkflowTypeEnum.TRANSACTIONAL, label: t('automatedScenarios.globalSettings.type_workflow.options.transactional') },
      ],

      unique: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      type_unique: [
        { value: 1, label: t('automatedScenarios.globalSettings.type_unique.options.visitor') },
        { value: 0, label: t('automatedScenarios.globalSettings.type_unique.options.session') },
      ],

      type_retrigger: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      program_date_ranges: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],
    };

    const units_retrigger = [
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
      { value: 'MONTH', label: t('automatedScenarios.fields.durations.month(s)') },
    ];

    const period_rettriger = ref<Period>({
      unit: settingsFields.time_unit_select.unit,
      value: asInt(settingsFields.time_unit_select.value),
    });

    const dateRanges: DateRangeWorkflow[] = reactive(JSON.parse(settingsFields.date_ranges));

    const dateStart = ref();
    const dateEnd = ref();

    const minDate = ref(new Date(new Date().getFullYear(), 0, 1));

    const addDateRange = () => {
      if (dateStart.value && dateEnd.value) {
        let m1 = dateStart.value.getMonth() + 1;
        let d1 = dateStart.value.getDate();
        let m2 = dateEnd.value.getMonth() + 1;
        let d2 = dateEnd.value.getDate();

        if (m1 < 10) {
          m1 = `0${m1}`;
        }

        if (d1 < 10) {
          d1 = `0${d1}`;
        }

        if (m2 < 10) {
          m2 = `0${m2}`;
        }

        if (d2 < 10) {
          d2 = `0${d2}`;
        }

        const date_start = `${m1}-${d1}`;
        const date_end = `${m2}-${d2}`;
        const found = dateRanges.find((element) => element.date_start === date_start && element.date_end === date_end);

        if (found) {
          showToastError(t('automatedScenarios.globalSettings.program_date_ranges.actions.range_already_exist'));
        } else {
          dateRanges.push({
            date_start,
            date_end,
          });
        }
      }
      dateStart.value = null;
      dateEnd.value = null;
    };
    const removeDateRange = (id: number) => {
      dateRanges.splice(id, 1);
    };
    const formatDateToDisplay = (date: string) => {
      const dateFormatted = new Date();
      const dateSplit: string[] = date.split('-');

      dateFormatted.setMonth(asInt(dateSplit[0]) - 1);

      return `${asInt(dateSplit[1])} ${dateFormatted.toLocaleString(locale, {
        month: 'long',
      })}`;
    };

    watch([settingsFields, period_rettriger, dateRanges], () => {
      settingsFields.time_unit_select = period_rettriger.value;
      settingsFields.date_ranges = JSON.stringify(dateRanges);
      setGlobalSettingsData(settingsFields);
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      settingsFields,
      optionsFields,
      units_retrigger,
      period_rettriger,
      minDate,
      dateStart,
      dateEnd,
      dateRanges,
      addDateRange,
      removeDateRange,
      formatDateToDisplay,
    };
  },
});
