
import {
  defineComponent,
  ref,
  Ref,
} from 'vue';

import Button from 'primevue/button';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';

import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ZipFileUploader from '@/components/template-builder/fields/ZipFileUploader.vue';

import { TemplateTypeEnum } from '@/composables/shop/Templates';
import {
  activate,
  createHtmlTemplateFromZipFile,
  setRefreshTemplatesList,
} from '@/composables/template-editor/TemplateEditor';
import { UserState } from '@/composables/User';

import { showToastError } from '@/helpers';

import { ZipFile } from '@/types/zip-file-uploader-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

import useVuelidate from '@vuelidate/core';
import { importedHtmlValidator } from '@/helpers/CustomValidator';

export default defineComponent({
  name: 'ImportTemplateModal',
  components: {
    Button,
    ZipFileUploader,
    Message,
    FieldErrors,
    Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible'],

  setup(props, { emit }) {
    const listTypes = [TemplateTypeEnum.EMAIL];

    const { t } = useI18n();
    const store = useStore();

    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const templateType = ref('');

    const uploadExternalImage = ref(0);

    const zipTemplate: Ref<ZipFile | null> = ref(null);

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const handleZipFileUploaded = (zipFile: ZipFile) => {
      zipTemplate.value = zipFile;
    };

    const handleZipFileRemoved = () => {
      zipTemplate.value = null;
      componentFieldErrorsKey.value = 0;
      error.value = null;
      uploadExternalImage.value = 0;
    };

    const chooseTemplatesType = (type: string) => {
      templateType.value = type;
    };

    const handleHideDialog = () => {
      templateType.value = '';
      zipTemplate.value = null;
      componentFieldErrorsKey.value = 0;
      error.value = null;
      uploadExternalImage.value = 0;
      emit('update:visible', false);
    };

    const validate = async (html: string): Promise<ErrorConfigForm> => {
      const rules = {
        html: {
          importedHtmlValidator: importedHtmlValidator(),
        },
      };

      const v$ = useVuelidate(rules, { html });
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleChooseZipTemplate = async () => {
      if (zipTemplate.value) {
        const { html } = zipTemplate.value;
        const validation = await validate(html);
        if (!validation.success) {
          error.value = validation.validate;
          componentFieldErrorsKey.value += 1;
          return;
        }
        try {
          store.commit('general/showTheSpinner');
          const { id, type, label } = await createHtmlTemplateFromZipFile(idShop.value, zipTemplate.value, templateType.value, uploadExternalImage.value);
          activate(id, label, type).then(() => {
            store.commit('general/hideTheSpinner');
            setRefreshTemplatesList(true);
            handleHideDialog();
          });
        } catch (err) {
          showToastError(t('templateBuilder.panels.fileManager.uploadError'));
          store.commit('general/hideTheSpinner');
        }
      }
    };

    return {
      t,
      listTypes,
      templateType,
      uploadExternalImage,
      zipTemplate,
      componentFieldErrorsKey,
      error,

      chooseTemplatesType,
      handleHideDialog,
      handleZipFileUploaded,
      handleZipFileRemoved,
      handleChooseZipTemplate,
    };
  },
});
