
import {
  defineComponent,
  reactive,
} from 'vue';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import { useI18n } from 'vue-i18n';

import { TEMPLATE_IMPORT_METHODS_LIST } from '@/configs/templates';

export default defineComponent({
  name: 'TemplateImportMethodModal',
  components: {
    Button,
    Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible', 'selected-import-method'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const importMethodList = reactive(TEMPLATE_IMPORT_METHODS_LIST);

    const chooseMethod = (importMethod: string) => {
      emit('selected-import-method', importMethod);
    };

    return {
      t,
      importMethodList,
      chooseMethod,
    };
  },
});
