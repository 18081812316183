<template>
  <div class="configuration-wrapper">
    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <label class="block mb-2">
              {{ t('automatedScenarios.globalSettings.name.label') }}
            </label>
            <div class="p-fluid flex">
              <InputText
                v-model="settingsFields.name"
                class="input-text"
              />
            </div>
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="name"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="settingsFields.type_workflow"
              :options="optionsFields.type_workflow"
              title="automatedScenarios.globalSettings.type_workflow.label"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="type_workflow"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="settingsFields.unique"
              :options="optionsFields.unique"
              title="automatedScenarios.globalSettings.unique.label"
              tooltip-desc="automatedScenarios.globalSettings.unique.description"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="unique"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="settingsFields.unique === 1"
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="settingsFields.type_unique"
              :options="optionsFields.type_unique"
              title="automatedScenarios.globalSettings.type_unique.label"
              tooltip-desc="automatedScenarios.globalSettings.type_unique.description"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="type_unique"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="settingsFields.unique === 1 && settingsFields.type_unique === 1"
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="settingsFields.type_retrigger"
              :options="optionsFields.type_retrigger"
              title="automatedScenarios.globalSettings.type_retrigger.label"
              tooltip-desc="automatedScenarios.globalSettings.type_retrigger.description"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="type_retrigger"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="settingsFields.unique === 1 && settingsFields.type_unique === 1 && settingsFields.type_retrigger === 1"
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <PeriodSelector
              v-model="period_rettriger"
              :units="units_retrigger"
              title="automatedScenarios.globalSettings.period_rettriger.label"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="time_unit_select"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              v-model="settingsFields.program_date_ranges"
              :options="optionsFields.program_date_ranges"
              title="automatedScenarios.globalSettings.program_date_ranges.label"
              tooltip-desc="automatedScenarios.globalSettings.program_date_ranges.description"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="program_date_ranges"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <Card
      v-if="settingsFields.program_date_ranges === 1"
      class="card-date-ranges"
    >
      <template #title>
        <label>{{ t('automatedScenarios.globalSettings.program_date_ranges.card.title') }}</label>
      </template>
      <template #content>
        <div class="col-12">
          <div class="p-inputgroup calendar_date_range">
            <Calendar
              v-model="dateStart"
              :min-date="minDate"
              :max-date="dateEnd"
              autocomplete="off"
            />
            <div class="middle-icon-container">
              <i
                class="far fa-arrow-alt-right"
              />
            </div>
            <Calendar
              v-model="dateEnd"
              :min-date="dateStart"
              autocomplete="off"
            />
            <Button
              icon="far fa-plus"
              class="p-button-success"
              :disabled="dateStart === null || dateEnd === null"
              :label="t('automatedScenarios.globalSettings.program_date_ranges.card.add_button')"
              @click="addDateRange"
            />
          </div>
        </div>
        <table
          v-if="dateRanges.length"
          class="list-date-ranges"
        >
          <tr>
            <th>{{ t('automatedScenarios.globalSettings.program_date_ranges.card.date_start') }}</th>
            <th>{{ t('automatedScenarios.globalSettings.program_date_ranges.card.date_end') }}</th>
            <th />
          </tr>
          <tr
            v-for="(dateRange, index) of dateRanges"
            :key="index"
          >
            <td>{{ formatDateToDisplay(dateRange.date_start) }} </td>
            <td>{{ formatDateToDisplay(dateRange.date_end) }} </td>
            <td>
              <i
                class="far fa-trash"
                @click="removeDateRange(index)"
              />
            </td>
          </tr>
        </table>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import {
  defineComponent,
  reactive, ref, watch,
} from 'vue';
import { GlobalSettingsData, Period, DateRangeWorkflow } from '@/types/automated-scenarios';
import { asInt, showToastError } from '@/helpers';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import {
  AutomatedScenarioState as state,
  setGlobalSettingsData,
} from '@/composables/AutomatedScenarios';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { MarketingWorkflowTypeEnum } from '@/types';

export default defineComponent({
  name: 'SettingsPanelAutomation',

  components: {
    FieldErrors,
    PeriodSelector,
    InputText,
    ButtonGroup,
    Calendar,
    Button,
    Card,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: {settingPanelValidationErrors: any }) {
    const { t } = useI18n();
    const locale = useI18n().locale.value;
    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const settingsFields: GlobalSettingsData = reactive({
      name: (state.scenario.settingsData as GlobalSettingsData).name,
      type_workflow: (state.scenario.settingsData as GlobalSettingsData).type_workflow,
      unique: (state.scenario.settingsData as GlobalSettingsData).unique,
      type_unique: (state.scenario.settingsData as GlobalSettingsData).type_unique,
      type_retrigger: (state.scenario.settingsData as GlobalSettingsData).type_retrigger,
      time_unit_select: (state.scenario.settingsData as GlobalSettingsData).time_unit_select,
      program_date_ranges: (state.scenario.settingsData as GlobalSettingsData).program_date_ranges,
      date_ranges: (state.scenario.settingsData as GlobalSettingsData).date_ranges,
    });

    const optionsFields = {
      type_workflow: [
        { value: MarketingWorkflowTypeEnum.MARKETING, label: t('automatedScenarios.globalSettings.type_workflow.options.marketing') },
        { value: MarketingWorkflowTypeEnum.TRANSACTIONAL, label: t('automatedScenarios.globalSettings.type_workflow.options.transactional') },
      ],

      unique: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      type_unique: [
        { value: 1, label: t('automatedScenarios.globalSettings.type_unique.options.visitor') },
        { value: 0, label: t('automatedScenarios.globalSettings.type_unique.options.session') },
      ],

      type_retrigger: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      program_date_ranges: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],
    };

    const units_retrigger = [
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
      { value: 'MONTH', label: t('automatedScenarios.fields.durations.month(s)') },
    ];

    const period_rettriger = ref<Period>({
      unit: settingsFields.time_unit_select.unit,
      value: asInt(settingsFields.time_unit_select.value),
    });

    const dateRanges: DateRangeWorkflow[] = reactive(JSON.parse(settingsFields.date_ranges));

    const dateStart = ref();
    const dateEnd = ref();

    const minDate = ref(new Date(new Date().getFullYear(), 0, 1));

    const addDateRange = () => {
      if (dateStart.value && dateEnd.value) {
        let m1 = dateStart.value.getMonth() + 1;
        let d1 = dateStart.value.getDate();
        let m2 = dateEnd.value.getMonth() + 1;
        let d2 = dateEnd.value.getDate();

        if (m1 < 10) {
          m1 = `0${m1}`;
        }

        if (d1 < 10) {
          d1 = `0${d1}`;
        }

        if (m2 < 10) {
          m2 = `0${m2}`;
        }

        if (d2 < 10) {
          d2 = `0${d2}`;
        }

        const date_start = `${m1}-${d1}`;
        const date_end = `${m2}-${d2}`;
        const found = dateRanges.find((element) => element.date_start === date_start && element.date_end === date_end);

        if (found) {
          showToastError(t('automatedScenarios.globalSettings.program_date_ranges.actions.range_already_exist'));
        } else {
          dateRanges.push({
            date_start,
            date_end,
          });
        }
      }
      dateStart.value = null;
      dateEnd.value = null;
    };
    const removeDateRange = (id: number) => {
      dateRanges.splice(id, 1);
    };
    const formatDateToDisplay = (date: string) => {
      const dateFormatted = new Date();
      const dateSplit: string[] = date.split('-');

      dateFormatted.setMonth(asInt(dateSplit[0]) - 1);

      return `${asInt(dateSplit[1])} ${dateFormatted.toLocaleString(locale, {
        month: 'long',
      })}`;
    };

    watch([settingsFields, period_rettriger, dateRanges], () => {
      settingsFields.time_unit_select = period_rettriger.value;
      settingsFields.date_ranges = JSON.stringify(dateRanges);
      setGlobalSettingsData(settingsFields);
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      settingsFields,
      optionsFields,
      units_retrigger,
      period_rettriger,
      minDate,
      dateStart,
      dateEnd,
      dateRanges,
      addDateRange,
      removeDateRange,
      formatDateToDisplay,
    };
  },
});
</script>

<style lang="scss">
.card-date-ranges {
  .p-card-title {
    label {
      font-size: 17px;
    }
  }

  .p-card-body {
    border-radius: 0.3rem;
  }

  .calendar_date_range {
    .middle-icon-container {
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: #BABFC3;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1.3rem;
      text-align: center;
      vertical-align: middle;
      display: table;
      background-color: #f2f2f2;
      i {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .p-button {
      box-shadow: none !important;
    }
  }

  .list-date-ranges {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 1rem;
    tr {
      border-bottom: 1px solid #ddd;

      &:nth-child(2n) {
        background-color: #f2f2f2;
      }

      &:nth-child(2n+1) {
        background-color: #fff;
      }

      th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }

      th:last-child {
        border: none !important;
      }

      td:last-child {
        text-align: center;

        i {
          cursor: pointer;
          color: $error-red;
        }
      }
    }

  }
}
</style>
