
import Button from 'primevue/button';
import {
  defineComponent, ref, Ref, SetupContext, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ToolbarItemsModel,
} from '@/types';
import EditorModal from '@/components/modals/EditorModal.vue';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import { TemplatesCustom } from '@/types/generated-types/graphql';
import { fetchRecursiveTemplate, fetchUserTemplates } from '@/composables/template/TemplateHelper';
import Loader from '@/components/layout/Loader.vue';
import { duplicateTemplate, TemplateTypeEnum } from '@/composables/shop/Templates';
import { UserState } from '@/composables/User';
import { asInt } from '@/helpers';
import PreviewModal from '@/components/modals/PreviewModal.vue';
import Dialog from 'primevue/dialog';
import { store } from '@/store';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import { getCountOperatorsByType, mappingTemplateTypeToOperatorType } from '@/composables/AutomatedScenarios';

export default defineComponent({
  name: 'TemplateSelector',

  components: {
    PreviewModal,
    Button,
    EditorModal,
    SelectButton,
    InputText,
    Loader,
    Dialog,
    ProgressSpinner,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    typeTemplates: {
      type: String,
      required: true,
      default: '',
    },

    originCall: {
      type: String,
      required: true,
      default: '',
    },

    idCampaign: {
      type: Number,
      required: true,
    },

    duplicate: {
      type: Boolean,
      required: false,
      default: false,
    },

    baseLinkData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
  },

  setup(props: { typeTemplates: string; originCall: string|null; idCampaign: number|null; duplicate: boolean; baseLinkData: any}, context: SetupContext) {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const imageLoadTime = ref<Record<number, number>>({});
    const imagesLoaded = ref<Record<number, boolean>>({});

    const displayDialog = ref(true);

    const listTypes = [TemplateTypeEnum.EMAIL, TemplateTypeEnum.SMS,
      TemplateTypeEnum.PUSHNOTIFICATIONS, TemplateTypeEnum.POPUP, TemplateTypeEnum.EMBED];

    const list: Ref<TemplatesCustom[]> = ref([]);
    const templatesType = ref(props.typeTemplates);

    const searchTemplatesInput = ref('');
    const displayPreviewModal = ref(false);
    const imagesPreview = ref([{}]);
    const isListLoading = ref(false);
    let limit = 12;
    const templateCategorySelected = ref({ category: 'recent', title: t('templateSelector.categories.recent') });
    const templateCategoriesOptions = ref([
      { category: 'recent', title: t('templateSelector.categories.recent') },
      { category: 'gallery', title: t('templateSelector.categories.gallery') },
      { category: 'self', title: t('templateSelector.categories.self') },
    ]);

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const getListTemplates = async (loadMore: boolean) => {
      let {
        // eslint-disable-next-line prefer-const
        originCall,
      } = props;
      if (templatesType.value !== 'none') {
        isListLoading.value = true;
        const result = await fetchUserTemplates(searchTemplatesInput.value, templateCategorySelected.value.category, templatesType.value, originCall, loadMore ? limit : 12);
        if (result) {
          list.value = result;
          isListLoading.value = false;
        }
      }
    };

    const imageLoaded = (id_template: number) => {
      // verifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        imagesLoaded.value[id_template] = true;
        imageLoadTime.value[id_template] = new Date().getTime();
      }
    };

    const imageFailed = (id_template: number) => {
      // Vérifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        setTimeout(() => {
          imageLoadTime.value[id_template] = new Date().getTime();
        }, 3000); // Tente de recharger l'image toutes les 3 secondes
      }
    };

    const handleSearchTemplates = async (loadMore: boolean) => {
      limit += 12;
      await getListTemplates(loadMore);
    };

    const handleChooseTemplate = (idTemplate: number, label: string) => {
      store.commit('general/showTheSpinner');
      let name: any = null;
      // duplicate template
      if (props.duplicate) {
        let {
          // eslint-disable-next-line prefer-const
          originCall, idCampaign, baseLinkData,
        } = props;

        if (originCall === 'myTemplates' || (originCall === 'templateBuilder' && !idCampaign)) {
          originCall = null;
          idCampaign = null;
          if (typeof baseLinkData === 'object'
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'namePrimaryTemplate')
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'countRedirectTemplate')) {
            name = `${baseLinkData.namePrimaryTemplate} - ${t(`templates.channels.${templatesType.value}`)} #${baseLinkData.countRedirectTemplate + 1}`;
          }
        } else {
          // choose template from campaign
          // eslint-disable-next-line no-lonely-if
          if (typeof baseLinkData === 'object'
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'namePrimaryTemplate')
              && Object.prototype.hasOwnProperty.call(baseLinkData, 'countRedirectTemplate')) {
            name = `${baseLinkData.namePrimaryTemplate} - ${t(`templates.channels.${templatesType.value}`)} #${baseLinkData.countRedirectTemplate + 1}`;
          } else {
            // generate dynamic name
            const boxTypeToCount = mappingTemplateTypeToOperatorType[templatesType.value];
            const countOperatorType = getCountOperatorsByType(boxTypeToCount);
            name = `${t(`templates.channels.${templatesType.value}`)} #${countOperatorType + 1}`;
          }
        }

        duplicateTemplate(idShop.value, idTemplate, originCall, idCampaign, name).then((result) => {
          if (result && result.success && result.id) {
            context.emit('on-choose-template', asInt(result.id), '', templatesType.value);
          }
        });
      } else {
        context.emit('on-choose-template', idTemplate, label);
      }
    };

    const handlePreviewImage = async (idTemplate: number) => {
      const getTemplates = await fetchRecursiveTemplate(idTemplate);
      imagesPreview.value = [];
      if (getTemplates && getTemplates.length) {
        getTemplates.forEach((templateData) => {
          if (templateData.imageKey) {
            imagesPreview.value.push({
              itemImageSrc: `${baseUrl + templateData.imageKey}${isDisplayTemplate(templatesType.value) ? '-isdisplay' : ''}.png&w=400`,
              thumbnailImageSrc: `${baseUrl + templateData.imageKey}${isDisplayTemplate(templatesType.value) ? '-isdisplay' : ''}.png&w=400`,
              title: templateData?.label,
            });
          }
        });
        displayPreviewModal.value = true;
      }
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    const chooseTemplatesType = async (type: string) => {
      templatesType.value = type;
      await getListTemplates(false);
    };

    const handleCloseChoosingTemplatesType = () => {
      context.emit('on-close-dialog');
    };

    watch(templateCategorySelected, async () => {
      await getListTemplates(false);
    });

    onMounted(async () => {
      await getListTemplates(false);
    });

    return {
      t,
      displayPreviewModal,
      imagesPreview,
      templatesType,
      listTypes,
      handleCloseChoosingTemplatesType,
      chooseTemplatesType,
      handleClosePreview,
      handlePreviewImage,
      handleChooseTemplate,
      handleSearchTemplates,
      templateCategorySelected,
      templateCategoriesOptions,
      list,
      baseUrl,
      searchTemplatesInput,
      isListLoading,
      displayDialog,
      imageLoadTime,
      imageLoaded,
      imageFailed,
      closeModal,
    };
  },

  methods: { isDisplayTemplate },
});
