<template>
  <ImportModal
    v-if="isImportModalVisible"
    :header="t('templates.import.header')"
    :show-modal="isImportModalVisible"
    :id-shop="idShop"
    import-type="automation"
    @close-action-modal="hideImportModal"
  />
  <div>
    <div class="flex justify-content-between flex-wrap mb-3">
      <div class="flex align-items-center justify-content-center">
        <h3>
          {{ t('campaigns.automation.list.title') }}
        </h3>
      </div>
      <div class="flex align-items-center justify-content-center">
        <span class="p-buttonset flex align-items-center justify-content-center gap-2">
          <router-link
            v-if="findPermission('marketing_pressure.view')"
            :to="{ name: 'marketing-pressure' }"
            class="mr-2"
          >
            <SpmButton
              :label="t('automatedScenarios.actions.marketingPressure.title')"
              icon="fa-regular fa-gear"
              class-style="p-button p-button-secondary"
            />
          </router-link>
          <SpmButton
            :label="t('automatedScenarios.actions.import')"
            icon="fa-regular fa-file-import"
            container-class="mr-1"
            class-style="p-button p-button-secondary"
            @click="showImportModal"
          />
          <SpmButton
            v-if="findPermission('automated_scenarios.create')"
            :label="t('campaigns.automation.list.actions.create')"
            icon="far fa-plus-circle"
            class-style="p-button p-button-success"
            @click="handleCreateScenario"
          />
        </span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 col-fixed">
        <ConfirmPopup group="basicGroup" />
        <ConfirmPopup
          class="small-spm-confirm-popup"
          group="customDialog"
        >
          <template #message="slotProps">
            <div class="flex pl-4 pt-4">
              <div class="pb-2">
                <label>
                  <i
                    :class="slotProps.message.icon"
                    aria-hidden="true"
                  />
                  {{ slotProps.message.message }}
                </label>
              </div>
            </div>
            <div class="flex px-4 py-2">
              <div
                class="pb-2"
                style="width: 100%"
              >
                <InputText
                  v-model="newListName"
                  type="text"
                  style="width:100%"
                />
              </div>
            </div>
          </template>
        </ConfirmPopup>
        <SpmTable
          :key="spmTableKey"
          ref="childComponentRef"
          :table-columns="columns"
          index="id_marketing_workflow"
          name="MarketingWorkflows"
          :is-global-loading="isGlobalLoading"
          :initial-sorting="initialSorting"
          :persistent-filters="persistentFilters"
          :grouped-actions-options="groupActions"
          :custom-selector="true"
          grouped-actions-key="id_marketing_workflow"
          :id-shop="idShop"
          @cell-edit-complete="onColumnValueEdit"
        >
          <template #name_marketing_workflow="slotProps">
            <div
              class="mr-5"
              style="float:left"
            >
              <span class="font-bold capitalize">
                {{ slotProps.data[slotProps.column.props.field] }}
              </span>
            </div>
          </template>
          <template #type_workflow="slotProps">
            {{ t(`automatedScenarios.${slotProps.data[slotProps.column.props.field]}`) }}
          </template>
          <template #status="slotProps">
            <div
              class="flex align-items-center status"
              :class="slotProps.data[slotProps.column.props.field]"
            >
              <span class="badge-dot mr-2" />
              <span>{{ t(`campaigns.automation.list.status.${slotProps.data[slotProps.column.props.field]}`) }}</span>
            </div>
          </template>
        </SpmTable>
      </div>
    </div>
  </div>
  <WorkflowSelector
    v-if="openWorkflowSelector"
    @on-close-dialog="openWorkflowSelector = false"
  />
  <Dialog
    v-model:visible="showEmailServiceDialog"
    class="service-parameter"
    modal
    :header="t('storeParameters.services.emailServiceParameter.subtitle')"
    :style="{ width: '100rem' }"
    @update:visible="handleCloseEmailServiceDialog"
  >
    <EmailServiceParameter
      :visible="showEmailServiceDialog"
      :shop-id="idShop"
      :retrieve-data="retrieveEmailServicesData"
      @close="handleCloseEmailServiceDialog"
    />
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmPopup from 'primevue/confirmpopup';
import useDateFormat from '@/composables/useDateFormat';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import {
  MarketingWorkflowStatusEnum,
  MarketingWorkflowTypeEnum,
  SpmTableAction,
  SpmTableColumns,
  SpmTableFilter,
  SpmTableFilterOption,
  SpmTableSort,
  TypeCampaignEnum,
} from '@/types';
import {
  MarketingWorkflows, MarketingWorkflowsUpdateInputItem,
  OperatorType,
  ShopMarketingWorkflowsDuplicateInputItem,
} from '@/types/generated-types/graphql';
import {
  activateCampaign,
  AutomatedScenarioState as stateAutomatedScenario,
  setRefreshScenariosList,
  updateCampaignAutomationListColumn,
  getAutomatedCampaignOperators,
} from '@/composables/AutomatedScenarios';
import { registerExportRequest } from '@/composables/useExport';
import { duplicateWorkflow } from '@/composables/workflows/Workflow';
import { showToastError, showToastSuccess } from '@/helpers';
import { findPermission, UserState, hasAccessToFeatures } from '@/composables/User';
import WorkflowSelector from '@/components/automated-scenarios/WorkflowSelector.vue';
import moment from 'moment/moment';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { store } from '@/store';
import InputText from 'primevue/inputtext';
import { retrieveServicesData, getNotConfiguredServices } from '@/composables/shop/MyShopParameters';
import Dialog from 'primevue/dialog';
import EmailServiceParameter from '@/views/shop/tabs/ServicesParameters/services/EmailServiceParameter.vue';
import ImportModal from '@/components/modals/ImportModal.vue';

export default defineComponent({
  name: 'AutomatedScenarioList',

  components: {
    ImportModal,
    SpmButton,
    WorkflowSelector,
    SpmTable,
    ConfirmPopup,
    InputText,
    Dialog,
    EmailServiceParameter,
  },

  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const spmTableKey = ref(0);
    const openWorkflowSelector = ref(false);
    const isGlobalLoading = ref(false);
    const newListName = ref('');
    const showEmailServiceDialog = ref(false);
    const childComponentRef = ref();
    const isImportModalVisible = ref(false);
    const { id, userType } = UserState.user;
    const dataExportPermission = hasAccessToFeatures('data.export');

    const confirm = useConfirm();
    const { toLocaleDateString } = useDateFormat();
    const statusOptions: SpmTableFilterOption[] = Object.values(MarketingWorkflowStatusEnum)
      // Do not keep ARCHIVED and DELETED options
      .filter((key: string) => !([MarketingWorkflowStatusEnum.ARCHIVED, MarketingWorkflowStatusEnum.DELETED] as string[]).includes(key))
      .map((key: string) => ({ value: key, label: t(`campaigns.automation.list.status.${key}`) }));

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'status',
        value: MarketingWorkflowStatusEnum.ARCHIVED,
        operator: OperatorType.NotEquals,
      },
      {
        field: 'status',
        value: MarketingWorkflowStatusEnum.DELETED,
        operator: OperatorType.NotEquals,
      },
      {
        field: 'id_shop',
        value: idShop.value,
        operator: OperatorType.Equals,
      },
    ];

    const initialSorting: SpmTableSort[] = [
      {
        field: 'date',
        type: 'DESC',
      },
    ];

    const typeOptions: SpmTableFilterOption[] = Object.values(MarketingWorkflowTypeEnum).map((key: string) => ({ value: key, label: t(`automatedScenarios.${key}`) }));

    const confirmationDialog = (marketingWorkflow: MarketingWorkflows, event: any) => {
      const workflow = ref(marketingWorkflow);
      const isActivated = workflow.value.status === MarketingWorkflowStatusEnum.ACTIVE;
      confirm.require({
        group: 'basicGroup',
        target: event.target,
        message: isActivated ? t('campaigns.automation.list.actions.disable.message') : t('campaigns.automation.list.actions.activate.message'),
        header: t('confirmation'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          isGlobalLoading.value = true;

          const input: MarketingWorkflowsUpdateInputItem = {
            id_marketing_workflow: workflow.value.id_marketing_workflow,
            id_shop: UserState.activeShop?.id ?? 0,
            status: isActivated ? MarketingWorkflowStatusEnum.DISABLED : MarketingWorkflowStatusEnum.ACTIVE,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
          };
          await updateCampaignAutomationListColumn([input]).then((result: any) => {
            showToastSuccess(isActivated ? t('campaigns.automation.list.actions.disabled') : t('campaigns.automation.list.actions.activated'));
            workflow.value.status = isActivated ? MarketingWorkflowStatusEnum.DISABLED : MarketingWorkflowStatusEnum.ACTIVE;
            isGlobalLoading.value = false;
          }).catch((err: Error) => {
            showToastError(err.message);
            isGlobalLoading.value = false;
          });
        },
      });
    };

    const duplicateConfirmationDialog = (marketingWorkflow: MarketingWorkflows, event: any) => {
      newListName.value = t('myLists.manage.actions.cloneText') + marketingWorkflow.name_marketing_workflow;
      confirm.require({
        group: 'customDialog',
        target: event.currentTarget,
        message: t('campaigns.automation.list.actions.duplicate.message'),
        header: t('confirmation'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('validate'),
        rejectLabel: t('cancel'),
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          const serviceParameterSuccessAction = () => {
            isGlobalLoading.value = true;

            const workflowToDuplicateInput: ShopMarketingWorkflowsDuplicateInputItem = {
              id_marketing_workflow: marketingWorkflow.id_marketing_workflow,
              id_shop_destination: idShop.value,
              name: newListName.value,
            };

            duplicateWorkflow(workflowToDuplicateInput).then((result) => {
              if (result.success && result.id) {
                showToastSuccess(t('campaigns.automation.list.actions.duplicate.success'));
                spmTableKey.value += 1;
              } else {
                showToastError(t('campaigns.automation.list.actions.duplicate.error'));
              }
              isGlobalLoading.value = false;
            }).catch(() => {
              showToastError(t('campaigns.automation.list.actions.duplicate.error'));
              isGlobalLoading.value = false;
            });
          };

          isGlobalLoading.value = true;
          const operators = await getAutomatedCampaignOperators(marketingWorkflow.id_marketing_workflow, TypeCampaignEnum.AUTOMATION, ['boxsendfbmessenger']);
          const notConfiguredServices = await getNotConfiguredServices(idShop.value, ['editScenarioCampaign', ...operators]);
          isGlobalLoading.value = false;
          if (notConfiguredServices.length) {
            store.commit('general/setNotConfiguredServices', notConfiguredServices);
            store.commit('general/setIsServicesParametersModalVisible', true);
            store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
          } else {
            serviceParameterSuccessAction();
          }
        },
      });
    };

    const removeConfirmationDialog = (marketingWorkflow: MarketingWorkflows, event: any) => {
      const workflow = ref(marketingWorkflow);
      confirm.require({
        group: 'basicGroup',
        target: event.currentTarget,
        message: t('campaigns.automation.list.actions.delete.message'),
        header: t('confirmation'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
          isGlobalLoading.value = true;

          const input: MarketingWorkflowsUpdateInputItem = {
            id_marketing_workflow: workflow.value.id_marketing_workflow,
            id_shop: UserState.activeShop?.id ?? 0,
            status: MarketingWorkflowStatusEnum.DELETED,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
          };
          await updateCampaignAutomationListColumn([input]).then((result: any) => {
            showToastSuccess(t('campaigns.automation.list.actions.delete.success'));
            setRefreshScenariosList(true);
            isGlobalLoading.value = false;
          }).catch((err: Error) => {
            showToastError(err.message);
            isGlobalLoading.value = false;
          });
        },
      });
    };

    // eslint-disable-next-line consistent-return
    const duplicateAction = async (workflow: MarketingWorkflows, event: any) => duplicateConfirmationDialog(workflow, event);

    const registerExportRequestCommand = async (idsTobeExported: number[]) => {
      await registerExportRequest(idsTobeExported, idShop.value, id, userType, 'automation').then(async (result: any) => {
        await showToastSuccess(t('campaigns.automation.export.exportSuccess'));
        childComponentRef.value.unselectAllRows();
      }).catch(async (err: any) => {
        await showToastError(t('campaigns.automation.exportFailure'));
      });
    };

    const hideImportModal = (needReload: boolean) => {
      isImportModalVisible.value = false;
      if (needReload) {
        setRefreshScenariosList(true);
      }
    };

    const showImportModal = () => {
      isImportModalVisible.value = true;
    };

    const groupActions = [
      {
        code: 'export',
        label: t('campaigns.automation.list.groupedActions.export.menuItem'),
        command: (event: {originalEvent: Event; item: any; navigate: undefined}) => {
          const command = () => {
            childComponentRef.value.toggleDialog(
              '',
              t('campaigns.automation.list.groupedActions.export.text'),
              t('campaigns.automation.list.groupedActions.export.formTitle'),
              '',
              t('yes'),
              t('no'),
              'custom',
              null,
              '',
              registerExportRequestCommand,
            );
          };
          if (dataExportPermission && !dataExportPermission.access) {
            store.commit('general/setIsFeatureUnavailableModalVisible', true);
            store.commit('general/setFeatureUnavailableMinPlan', dataExportPermission.minimumPlan);
            store.commit('general/setOnAuthorizedClickFunction', command);
          } else {
            command();
          }
        },
      },
    ];

    // Actions
    const actions: SpmTableAction[] = [
      {
        icon: 'far fa-times',
        class: 'p-button-danger',
        label: t('campaigns.automation.list.actions.disactivate'),
        callback: (workflow: MarketingWorkflows, event: any) => (event && workflow ? confirmationDialog(workflow, event) : false),
        show: (workflow: MarketingWorkflows) => findPermission('automated_scenarios.activate') && workflow.status === MarketingWorkflowStatusEnum.ACTIVE,
      },
      {
        icon: 'far fa-check',
        label: t('campaigns.automation.list.actions.activate.title'),
        callback: (workflow: MarketingWorkflows, event: any) => (event && workflow ? confirmationDialog(workflow, event) : false),
        show: (workflow: MarketingWorkflows) => findPermission('automated_scenarios.activate') && workflow.status === MarketingWorkflowStatusEnum.DISABLED,
      },
      {
        icon: 'far fa-edit',
        label: t('campaigns.automation.list.actions.edit'),
        callback: async (workflow: MarketingWorkflows) => {
          if (workflow.id_marketing_workflow) {
            const serviceParameterSuccessAction = () => {
              store.commit('general/showTheSpinner');
              activateCampaign(workflow.id_marketing_workflow, TypeCampaignEnum.AUTOMATION).then(() => {
                store.commit('general/hideTheSpinner');
              });
            };

            const operators = await getAutomatedCampaignOperators(workflow.id_marketing_workflow, TypeCampaignEnum.AUTOMATION, ['boxsendfbmessenger']);
            const notConfiguredServices = await getNotConfiguredServices(idShop.value, ['editScenarioCampaign', ...operators]);
            if (notConfiguredServices.length) {
              store.commit('general/setNotConfiguredServices', notConfiguredServices);
              store.commit('general/setIsServicesParametersModalVisible', true);
              store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
            } else {
              serviceParameterSuccessAction();
            }
          }
        },

        show: () => findPermission('automated_scenarios.edit'),
      },
      {
        label: t('campaigns.automation.list.actions.duplicate.title'),
        icon: 'far fa-clone',
        callback: duplicateAction,
        show: () => findPermission('automated_scenarios.duplicate'),
      },
      {
        icon: 'far fa-trash',
        label: t('campaigns.automation.list.actions.delete.title'),
        callback: (workflow: MarketingWorkflows, event: any) => (event && workflow ? removeConfirmationDialog(workflow, event) : false),
        show: () => findPermission('automated_scenarios.delete'),
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_marketing_workflow', header: '', sortable: false, filterable: false, editable: false, style: 'display:none', type: 'text',
      },

      {
        field: 'name_marketing_workflow',
        header: t('campaigns.bulk.list.columns.name'),
        sortable: true,
        filterable: true,
        editable: true,
        style: 'width:60%',
        type: 'text',
      },
      {
        field: 'date',
        header: t('campaigns.bulk.list.columns.last_update'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'type_workflow',
        header: t('campaigns.bulk.list.columns.type'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        filterSettings: {
          type: 'multiSelect', options: typeOptions, hideFilterMenu: true, showFilterInput: false,
        },
      },
      {
        field: 'status',
        header: t('campaigns.bulk.list.columns.status'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        filterSettings: {
          type: 'multiSelect', options: statusOptions, hideFilterMenu: true, showFilterInput: false,
        },
      },
      {
        field: '',
        header: t('actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:15%',
        type: 'action',
        actions,
      },
    ];

    const onColumnValueEdit = async (field: string, data: any) => {
      const input: MarketingWorkflowsUpdateInputItem = {
        id_marketing_workflow: data.id_marketing_workflow,
        id_shop: UserState.activeShop?.id ?? 0,
        [field]: data[field],
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      await updateCampaignAutomationListColumn([input]).then((result: any) => {
        showToastSuccess(t('campaigns.automation.list.nameUpdated'));
      }).catch((err: Error) => {
        showToastError(err.message);
      });
    };

    const handleCloseEmailServiceDialog = () => {
      showEmailServiceDialog.value = false;
    };

    const retrieveEmailServicesData = (field: any) => retrieveServicesData(idShop.value, field);

    const handleCreateScenario = async () => {
      openWorkflowSelector.value = true;
    };

    watch(() => stateAutomatedScenario.refreshScenariosList, () => {
      if (stateAutomatedScenario.refreshScenariosList) {
        spmTableKey.value += 1;
        setRefreshScenariosList(false);
      }
    });

    onMounted(() => {
      setRefreshScenariosList(false);
    });

    return {
      t,
      isGlobalLoading,
      spmTableKey,
      columns,
      persistentFilters,
      initialSorting,
      openWorkflowSelector,
      findPermission,
      idShop,
      onColumnValueEdit,
      newListName,
      showEmailServiceDialog,
      handleCloseEmailServiceDialog,
      retrieveEmailServicesData,
      handleCreateScenario,
      childComponentRef,
      groupActions,
      showImportModal,
      isImportModalVisible,
      hideImportModal,
    };
  },
});
</script>

<style scoped lang="scss">
.last-update {
  color: #BFBCB6;
  font-size: 0.90rem;
}
.type {
  text-align: center;
  color: $white;
  margin: 0 auto;
  &.marketing {
    background-color: #FBC02D;
  }
  &.transactional {
    background-color: $brand-color-primary;
  }
}

.status {
  &.draft {
    color: #f59e0b;

    .badge-dot {
      background-color: #f59e0b;
    }
  }

  &.active {
    color: #10b981;

    .badge-dot {
      background-color: #10b981;
    }
  }

  &.disabled {
    color: #db5858;

    .badge-dot {
      background-color: #db5858;
    }

  }

  &.being_edited {
    color: #3b82f6;

    .badge-dot {
      background-color: #3b82f6;
    }
  }
}
</style>

<style lang="scss">
.p-column-filter-row {
  width: max-content !important;
}
.service-parameter {
  .p-dialog-content {
    text-align: unset !important;
  }
}
</style>
