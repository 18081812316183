/* eslint-disable */
import {reactive, readonly, toRaw, watch} from 'vue';
import {i18n} from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  CampaignsBulkUpdateInputItem,
  MarketingWorkflowsUpdateInputItem,
  Maybe,
  OperatorType,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {asInt, showToastError} from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {Get, List, Update} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  BoxPersistence,
  ComponentData,
  DateRangeWorkflow,
  GlobalBulkSettingsData,
  GlobalSettingsData,
  Meta,
  PersistenceList,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import Metadata, {getComponentMetadata, getComponentMetadataById} from '@/components/automated-scenarios/metadata';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  getHistoryByCampaign,
  insertCampaignHistory,
  updateCampaignHistory,
} from '@/composables/automated-scenarios/AutomatedScenariosHistory';
import {
  CampaignsBulkStatusEnum,
  IntervalDateEnum,
  IntervalNextDateEnum,
  MarketingWorkflowTypeEnum,
  TypeCampaignEnum,
} from '@/types';
import deepcopy from "deepcopy";
import {getaAvailableOperators} from "@/composables/automated-scenarios/OperatorsCompatibilitesAndDependencies";
import {UserState} from "@/composables/User";
import {getBulkCampaignStatus} from "@/composables/workflows/CampaignBulk";
import {getLocalStorageElement, removeLocalStorageElement, setLocalStorageElement} from '@/helpers/LocalStorage';
import {
  getOperatorAnalyticsData,
  setWorkflowAnalyticsData
} from "@/composables/automated-scenarios/AutomatedScenariosAnalytics";
import {
  checkIfTemplateOrRedirectContainsVoucher,
  getTemplateAndRedirect,
  sendingChannelBoxes
} from "@/composables/automated-scenarios/AutomatedScenariosSendingChannel";

import getFiltreCustomerSegments from '@/components/automated-scenarios/forms/filtres/segments/FiltreCustomerSegments';
import getFiltrePurchaseHistorySegments
  from '@/components/automated-scenarios/forms/filtres/segments/FiltrePurchaseHistorySegments';
import getFiltreCurrentOrderSegments
  from '@/components/automated-scenarios/forms/filtres/segments/FiltreCurrentOrderSegments';
import getFiltreCurrentCartSegments
  from '@/components/automated-scenarios/forms/filtres/segments/FiltreCurrentCartSegments';
import getFiltreBoxNavigationTriggerSegments
  from '@/components/automated-scenarios/forms/filtres/segments/FiltreBoxNavigationTriggerSegments';

import CryptoJS from 'crypto-js';
import { nestGet } from "@/composables/nestApi";

let translation: any;
(async () => {
  translation = await i18n;
})();

export const mappingTemplateTypeToOperatorType: Record<string, string> = {
  email: 'boxsendemail',
  sms: 'boxsendsms',
  facebookmessenger: 'boxsendfbmessenger',
  pushnotifications: 'boxsendpushnotification',
  popup: 'boxdisplayfreepopup',
  embed: 'boxdisplayfreemodel',
}

export const drawingTreeConfig = {
  boxSizeHeight: 50, // Height of a box
  boxSizeWidth: 230, // Width of a box
  verticalSpaceBetweenBoxes: 90, // The difference in height between each box
  horizontalSpaceBetweenBoxes: 45, // The difference in width between each box
  problemIconWidth: 40,
  deleteLeftMargin: 5,
  mergeConfirmationBoxWidth: 100,
  deleteWidth: 26,
  plusHeight: 24,
  switchIconHeight: 25, /** taille en h du bouton de switch de branches */
};
export const availableFlowchartZoom = [0.25, 0.5, 0.75, 1, 1.25, 1.5];
const maxSavedHistoryPoints = 100;

interface CampaignAutomationRaw {
  id_marketing_workflow: number;
  name_marketing_workflow: string;
  data_marketing_workflow: string;
  type_workflow: string;
  unique: number;
  type_unique: number|null;
  type_retriggered: number|null;
  trigger_time: number|null;
  unit_time: string|null;
  date: string;
}

interface CampaignBulkRaw {
  id_campaign_bulk: number;
  name: string;
  data_campaign: string;
  data_flowchart: string;
  date_modification: string;
  status: string;
}

export interface AutomatedScenarioHistoryRaw {
  id_marketing_workflow_history?: number;
  id_campaign_bulk_history?: number;
  history?: string;
  date_creation?: string;
}

interface AutomatedScenarioOutput {
  label: string;
  multipleLinks: boolean;
}

export interface AutomatedScenarioOperator {
  top: number;
  left: number;
  properties: {
    title: string;
    class: string;
    body?: string;
    uncontained?: boolean;
    inputs: {
      [key: string]: {
        label: string;
      };
    };
    outputs: {
      [key: string]: AutomatedScenarioOutput;
    };
  };
  custom: any;
}

interface AutomatedScenarioLink {
  fromOperator: string;
  toOperator: string;
  fromConnector: string;
  toConnector: string;
  fromSubConnector: number;
  toSubConnector: number;
}

export interface OperatorOutput {
  index: number;
  visible: boolean;
  connectorToMerge: boolean;
  connectorCanBeMerged: boolean;
  connectorSelectedForMerge: boolean;
}

export interface OperatorInput {
  visible: boolean;
  connectorCanBeMerged: boolean;
  connectorSelectedForMerge: boolean;
}

export interface AutomatedScenarioData {
  operators: {
    [key: string]: AutomatedScenarioOperator;
  };
  links: { [key: string]: AutomatedScenarioLink };
  operatorTypes: { [key: string]: object };
}

export interface AutomatedScenarioHistory {
  history: string;
  dateCreation: string;
}

export interface AutomatedScenarioAnalyticsDateInterval {
  interval: string;
  customDateRange?: any;
}

export interface AutomatedScenarioAnalytics {
  show: boolean;
  dateInterval: AutomatedScenarioAnalyticsDateInterval;
  data?: any;
}

export class AutomatedScenario {
  id: number;

  settingsData: GlobalSettingsData | GlobalBulkSettingsData;

  type: TypeCampaignEnum;

  data: AutomatedScenarioData;

  flowchartLevels: Array<any>;

  operatorIdToCenter: string;

  currentZoom: number;

  historyId: number | null;

  history: AutomatedScenarioHistory | null;

  currentHistoryIndex: number;

  savedHistoryIndex: number;

  maxHistoryIndex: number;

  analyticsMode: AutomatedScenarioAnalytics;

  visualisationMode: boolean;

  dateModification: string | null;

  dateSend: string | null;

  constructor(
    id = 0,
    type: TypeCampaignEnum = TypeCampaignEnum.AUTOMATION,
    settingsData: GlobalSettingsData | GlobalBulkSettingsData = {
      name: '',
      type_workflow: MarketingWorkflowTypeEnum.MARKETING,
      unique: 0,
      type_unique: 0,
      type_retrigger: 0,
      time_unit_select: {
        unit: 'MONTH',
        value: 1,
      },
      program_date_ranges: 0,
      date_ranges: '',
    },
    data: AutomatedScenarioData = {
      links: {}, operators: {}, operatorTypes: {},
    },
    historyId: number | null = null,
    history: AutomatedScenarioHistory | null = null,
    currentHistoryIndex = -1,
    savedHistoryIndex = 0,
    maxHistoryIndex = 0,
    analyticsMode: AutomatedScenarioAnalytics = {
      show: false,
      dateInterval: {
        interval: IntervalDateEnum.LAST_30_DAYS,
      },
    },
    visualisationMode = false,
    dateModification: string | null = null) {
    this.id = id;
    this.type = type;
    this.settingsData = settingsData;
    this.data = data;
    this.flowchartLevels = [];
    this.operatorIdToCenter = '';
    this.currentZoom = 1;
    this.historyId = historyId;
    this.history = history;
    this.currentHistoryIndex = currentHistoryIndex;
    this.savedHistoryIndex = savedHistoryIndex;
    this.maxHistoryIndex = maxHistoryIndex - 1;
    this.analyticsMode = analyticsMode;
    this.visualisationMode = visualisationMode;
    this.dateModification = dateModification;
    this.dateSend = null;

    /**
     * For scenarios migrated from the old version
     * => you have to parse the data related to the data_picker to put them in the right format
     */
    if (this.type === TypeCampaignEnum.AUTOMATION) {
      Object.keys(this.data.operators).forEach((operatorId) => {
        const customDataOperators = this.data.operators[operatorId].custom;
        const regex = /^boxfiltre_/;
        if (regex.test(customDataOperators.id)) {
          parseSelectionFiltersData(customDataOperators.inclusion);
          parseSelectionFiltersData(customDataOperators.exclusion);
        } else if (customDataOperators.id === 'boxvisitpage') {
          const dataTypeToParse = ['products', 'categories', 'manufacturers'];
          const keyByDataType: any = {
            products: 'product_picker_product',
            categories: 'product_picker_category',
            manufacturers: 'product_picker_manufacturer',
          };
          dataTypeToParse.forEach((dataType: any) => {
            if (customDataOperators.hasOwnProperty(dataType)
              && customDataOperators[dataType].hasOwnProperty(keyByDataType[dataType])
              && customDataOperators[dataType][keyByDataType[dataType]].hasOwnProperty('selection')) {

              if (typeof customDataOperators[dataType][keyByDataType[dataType]].selection === "string") {
                customDataOperators[dataType][keyByDataType[dataType]].selection = JSON.parse(customDataOperators[dataType][keyByDataType[dataType]].selection);
              }

              if (customDataOperators[dataType][keyByDataType[dataType]].selection.hasOwnProperty('selected')
                && !Array.isArray(customDataOperators[dataType][keyByDataType[dataType]].selection.selected)) {
                customDataOperators[dataType][keyByDataType[dataType]].selection.selected = Object.values(customDataOperators[dataType][keyByDataType[dataType]].selection.selected);
              }
            }

            if (customDataOperators.hasOwnProperty(dataType)
              && customDataOperators[dataType].hasOwnProperty('all')
            ) {
              customDataOperators[dataType].all = parseInt(customDataOperators[dataType].all);
            }
          });
        }
      });
    }
  }

  getOperatorData<T>(operatorId: string): T {
    const d = this.data.operators[operatorId]?.custom as T;
    return { ...d };
  }

  getOperatorBox(operatorId: string): OperatorBox | null {
    const data = this.getOperatorData<{ id: string }>(operatorId) ?? { id: '' };
    if (data.id === '') {
      return null;
    }

    const metadata = getComponentMetadataById(data.id);
    if (!metadata) {
      return null;
    }

    return {
      operatorId,
      targetDiv: `#flowchart_operator_${operatorId}`,
      label: metadata.Label(data),
      icon: metadata.Meta.icon,
      hasConfiguration: metadata.Meta.hasConfiguration,
      kind: metadata.Meta.kind,
      id: operatorId,
      grayscaled: false,
      disabled: false,
      selectable: false,
      analyticsData: null,
    };
  }

  getOperatorComponent<T>(operatorId: string) {
    const d = this.data.operators[operatorId] ?? {};

    return {
      ...d,
    };
  }

  getOperatorOutputs(operator: string): string[] {
    return Object.keys(this.data.operators[operator]?.properties.outputs ?? {});
  }
}

export interface OperatorMeta {
  label: string;
  icon: string;
  kind: string;
  component: string;
  disabled: boolean;
}

export interface BoxDialogMeta {
  type?: BoxDialogType;
  error?: boolean;
  message?: string;
}

export interface OperatorBox {
  operatorId: string;
  targetDiv: string;
  label: string;
  icon: string;
  hasConfiguration: boolean;
  analyticsData: any;
  kind: string;
  id: string;
  grayscaled: boolean;
  disabled: boolean;
  selectable: boolean;
}

export interface BranchClosureData {
  operatorId: string;
  output: string;
}

export enum EditingTemplateStatusEnum {
  IN_TEMPLATE_CHOOSER = 'IN_TEMPLATE_CHOOSER',
  IN_TEMPLATE_EDITOR = 'IN_TEMPLATE_EDITOR',
  SAVE_STAY_TEMPLATE_EDITOR = 'SAVE_STAY_TEMPLATE_EDITOR',
  SAVE_QUIT_TEMPLATE_EDITOR = 'SAVE_QUIT_TEMPLATE_EDITOR',
  QUIT_TEMPLATE_EDITOR = 'QUIT_TEMPLATE_EDITOR',
}

export enum EditingTemplateTypeEnum {
  EDIT = 'EDIT',
  CHANGE = 'CHANGE',
}

export interface EditingTemplate {
  status: EditingTemplateStatusEnum;
  id_campaign: number;
  type?: EditingTemplateTypeEnum;
  isRedirect?: boolean;
  oldIdTemplate?: number;
  parentsRedirect?: Array<number>;
  idTemplate?: number;
}

export enum OperatorAddType {
  ADD_ROOT = 'ADD_ROOT',
  ADD_ABOVE = 'ADD_ABOVE',
  ADD_BELOW = 'ADD_BELOW',
  REPLACE_CURRENT = 'REPLACE_CURRENT',
}

export enum BoxDialogType {
  INFO_RIGHT = 'INFO_RIGHT',
  ACTIONS_ADD_OPERATOR = 'ACTIONS_ADD_OPERATOR',
  ACTIONS_BRANCH_MERGE = 'ACTIONS_BRANCH_MERGE',
  ACTIONS_BRANCH_CLOSURE = 'ACTIONS_BRANCH_CLOSURE',
  ACTIONS_DELETE_OPERATOR = 'ACTIONS_DELETE_OPERATOR',
  CONFIRMATION_BRANCH_CLOSURE = 'CONFIRMATION_BRANCH_CLOSURE',
}

export enum ActionsDialogAddOperatorType {
  ADD_OPERATOR = 'ADD_OPERATOR',
  BRANCH_MERGE = 'BRANCH_MERGE',
  BRANCH_CLOSURE = 'BRANCH_CLOSURE',
}

export enum ActionsDialogBranchMergeType {
  VALIDATE = 'VALIDATE',
  CANCEL = 'CANCEL',
}

export enum ActionsDialogBranchClosureType {
  VALIDATE = 'VALIDATE',
  CANCEL = 'CANCEL',
}

export enum ActionsDialogRemoveOperator {
  OPEN_DIALOG = 'OPEN_DIALOG',
  VALIDATE = 'VALIDATE',
  CANCEL = 'CANCEL',
}

export enum StepsOperatorSelector {
  SELECT = 0,
  CONFIGURE = 1,
}

interface SelectedOperatorState {
  operatorId: string;
  hasActive: boolean;
  possibleOutputs: {
    action: OperatorMeta[];
    declencheur: OperatorMeta[];
    filtre: OperatorMeta[];
  };
  outputs: OperatorBox[];
  meta: Meta;
  outputSelected?: string;
  operatorAddMethod?: OperatorAddType|null;
  mergeBranchData?: AutomatedScenarioLink|null;
  closureBranchData?: BranchClosureData[]|null;
  dialog?: BoxDialogMeta|null;
  currentConfigurationForm: any;
  editingTemplate: EditingTemplate|null;
}

interface LeftToolbarState {
  operatorSelectorStep: StepsOperatorSelector;
  show: {
    operatorEditForm: boolean;
    operatorOutputs: boolean;
    operatorSelector: boolean;
    historyPanel: boolean;
    settingsPanel: boolean;
  };
}

interface AutomatedScenarioStateItem {
  scenario: AutomatedScenario;
  selectedOperator: SelectedOperatorState;
  leftToolbar: LeftToolbarState;
}

interface State {
  isEditorVisible: boolean;
  bulkCampaigns: Array<AutomatedScenarioStateItem>;
  automationCampaigns: Array<AutomatedScenarioStateItem>;
  scenario: AutomatedScenario; // the current active scenario
  selectedOperator: SelectedOperatorState; // the operator (box) on the active scenario flowchart selected
  operators: OperatorBox[]; // list of all operators in the active scenario flowchart
  leftToolbar: LeftToolbarState; // which tabs are visible in left toolbar
  nbOutputs: { // the number of outputs [operator id] => number of outputs
    [key: string]: Array<OperatorOutput>;
  };
  input: {
    [key: string]: OperatorInput;
  };
  $flowchart: any; // jquery object of flowchart js library, can be null if flowchart not yet loaded
  flowchartTick: string; // used to track changes in the flowchart state; hex value, auto-incremented
  linkedOperators: string[]; // used to store linked boxes for openEmail, clickLink, filledOutForm filters
  refreshScenariosList: boolean;
  configuringSegment: boolean; // Configuring a segment
  dataFromTemplateBuilder: { // Data set by template builder to use in boxes configuration (email, sms senders, ...)
    [key: string]: any,
  },
  defaultDisplayUrl: string
}

const emptyMeta = {
  id: '',
  icon: '',
  hasConfiguration: false,
  component: '',
  label: '',
  kind: '',
  availableInCampaign: [],
};

const state = reactive<State>({
  isEditorVisible: false,
  leftToolbar: {
    operatorSelectorStep: StepsOperatorSelector.SELECT,
    show: {
      operatorEditForm: false,
      operatorOutputs: false,
      operatorSelector: false,
      settingsPanel: false,
      historyPanel: false,
    },
  },
  automationCampaigns: [],
  bulkCampaigns: [],
  scenario: new AutomatedScenario(),
  selectedOperator: {
    operatorId: '',
    hasActive: false,
    possibleOutputs: {
      action: [],
      filtre: [],
      declencheur: [],
    },
    meta: { ...emptyMeta },
    outputs: [],
    mergeBranchData: null,
    closureBranchData: null,
    dialog: null,
    currentConfigurationForm: null,
    editingTemplate: null,
  },
  operators: [],
  nbOutputs: {},
  input: {},
  $flowchart: null,
  flowchartTick: '0',
  linkedOperators: [],
  refreshScenariosList: false,
  configuringSegment: false,
  dataFromTemplateBuilder: {},
  defaultDisplayUrl: '',
});

export const AutomatedScenarioState = readonly(state);

/* Recovery of campaigns saved in localStorage if they exist */
const getCampaignsSavedInLocalStorage = () => {
  const campaignsSavedInLocalStorage = JSON.parse(getLocalStorageElement('campaigns') ?? '{}');
  if (Object.keys(campaignsSavedInLocalStorage).length > 0) {
    if (campaignsSavedInLocalStorage.automationCampaigns.length) {
      state.automationCampaigns = campaignsSavedInLocalStorage.automationCampaigns.map((item: AutomatedScenarioStateItem) => {
        item.scenario = new AutomatedScenario(
          item.scenario.id,
          item.scenario.type,
          item.scenario.settingsData,
          item.scenario.data,
          item.scenario.historyId,
          item.scenario.history,
          item.scenario.currentHistoryIndex,
          item.scenario.savedHistoryIndex,
          item.scenario.maxHistoryIndex,
          item.scenario.analyticsMode,
          item.scenario.visualisationMode,
          item.scenario.dateModification,
        );
        return item;
      });
    }
    if (campaignsSavedInLocalStorage.bulkCampaigns.length) {
      state.bulkCampaigns = campaignsSavedInLocalStorage.bulkCampaigns.map((item: AutomatedScenarioStateItem) => {
        item.scenario = new AutomatedScenario(
          item.scenario.id,
          item.scenario.type,
          item.scenario.settingsData,
          item.scenario.data,
          item.scenario.historyId,
          item.scenario.history,
          item.scenario.currentHistoryIndex,
          item.scenario.savedHistoryIndex,
          item.scenario.maxHistoryIndex,
          item.scenario.analyticsMode,
          item.scenario.visualisationMode,
          item.scenario.dateModification,
        );
        return item;
      });
    }
  }
};

watch(
  [() => UserState.isAuthenticated, () => UserState.activeShop?.id],
  () => {
    // wait for user to be authenticated and active shop to be set
    if (UserState.isAuthenticated && UserState.activeShop?.id) {
      getCampaignsSavedInLocalStorage();
    }
  }
);

function preprocessFirstBox(data: AutomatedScenarioData): AutomatedScenarioData {
  const output = { ...data };
  const linkmap = Object.keys(data.links).reduce((p, c) => {
    const operator = data.links[c].toOperator;
    return {
      ...p,
      [operator]: true,
    };
  }, {});

  const root = Object.keys(data.operators).find((p) => !Object.prototype.hasOwnProperty.call(linkmap, p));

  if (root) {
    output.operators[root].properties.inputs = {};
  }

  return output;
}

function preprocessCoordinates(data: AutomatedScenarioData): AutomatedScenarioData {
  return data; // todo
}

/*
 * Retrieving parents that are linked directly to a specific box
 */
export function getOperatorParents(operatorId: string): string[] {
  return Object.keys(state.scenario.data.links)
    .filter(
      (l) => state.scenario.data.links[l].toOperator === operatorId && state.scenario.data.links[l].toConnector
        === 'input_1',
    )
    .map((l) => state.scenario.data.links[l].fromOperator ?? '')
    .filter((n) => n !== '');
}

/*
 * Recovery of all parents linked to the box up to the root of the tree
 */
export function getAllParentsOfBoxToRoot(operatorId: string, parentsList: string[] = []) {
  const parents = getOperatorParents(operatorId);
  parents.forEach((operatorParent: string) => {
    if (operatorParent !== '' && !parentsList.includes(operatorParent)) {
      parentsList.push(operatorParent);
      parentsList.concat(getAllParentsOfBoxToRoot(operatorParent, parentsList));
    }
  });

  return parentsList;
}

/*
 * Retrieving children that are linked directly to a specific box and output
 */
export function getOperatorAndLinkChildByOutput(operatorId: string, output: string): {
  link: string;
  operator: any
} | null {
  const linkKeys = Object.keys(state.scenario.data.links)
    .filter(
      (l) => state.scenario.data.links[l].fromOperator === operatorId && state.scenario.data.links[l].fromConnector
        === output,
    );

  if (linkKeys.length > 0) {
    const linkKey = linkKeys[0];
    return {operator: state.scenario.data.links[linkKey].toOperator, link: linkKey} ?? null;
  }

  return null;
}

/*
 * Retrieving children that are linked directly to a specific box and output
 */
export function getOperatorChildByOutput(operatorId: string, output: string): string|null {
  const linkKey = Object.keys(state.scenario.data.links)
    .find(
      (l) => state.scenario.data.links[l].fromOperator === operatorId && state.scenario.data.links[l].fromConnector
        === output,
    );

  if (linkKey) {
    return state.scenario.data.links[linkKey].toOperator ?? null;
  }

  return null;
}

/*
 * Retrieving children that are linked directly to a box
 */
function getOperatorChildren(operatorId: string): string[] {
  const operatorChildren: string[] = [];
  const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;

  Object.keys(operatorOutputs).forEach((output) => {
    const childOperator = getOperatorChildByOutput(operatorId, output);
    if (childOperator !== null) {
      operatorChildren.push(childOperator);
    }
  });

  return operatorChildren;
}

/*
 * Check if all outputs are linked
 */
function checkIfAllOutputsAreLinked(operatorId: string): boolean {
  const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;

  return Object.keys(operatorOutputs).every((output) => getOperatorChildByOutput(operatorId, output) !== null);
}

/*
 * Checks the operator is the root of the tree
 */
export function isRootOfTree(operatorId: string): boolean {
  const operatorParents = getOperatorParents(operatorId);

  return operatorParents.length === 0;
}

/*
 * Tree root recovery
 */
function getRootOfTree(): string {
  const rootOfTree = Object.keys(state.scenario.data.operators)
    // eslint-disable-next-line array-callback-return
    .filter((operatorId) => isRootOfTree(operatorId));

  return rootOfTree[0] ?? null;
}

/*
 * Retrieval of the connector (output) that links the box fromOperator with toOperator
 */
function getFromConnectorBetweenTwoBoxes(fromOperator: string, toOperator: string): string {
  const filterResult = Object.keys(state.scenario.data.links)
    .filter(
      (l) => state.scenario.data.links[l].fromOperator === fromOperator && state.scenario.data.links[l].toOperator
        === toOperator,
    )
    .map((l) => state.scenario.data.links[l].fromConnector ?? '')
    .filter((n) => n !== '');

  return filterResult[0] ?? '';
}

/*
 * Check if the box has multiple parents
 */
function boxHasMultipleParents(operatorId: string): boolean {
  return Object.keys(state.scenario.data.links)
    .filter(
      (l) => state.scenario.data.links[l].toOperator === operatorId,
    ).length > 1;
}

/*
 * Check if the box has multiple outputs
 */
function boxHasMultipleOutputs(operatorId: string): boolean {
  if (typeof (state.scenario.data.operators[operatorId]) === 'undefined') {
    return false;
  }
  return Object.keys(state.scenario.data.operators[operatorId].properties.outputs).length > 1;
}

/*
 * Check if the box has multiple outputs
 */
function boxHasParentWithMultiOutputs(operatorId: string): boolean {
  if (typeof (state.scenario.data.operators[operatorId]) === 'undefined') {
    return false;
  }

  const parentsOfThatBox = getAllParentsOfBoxToRoot(operatorId);
  if (parentsOfThatBox.length === 0) {
    return false;
  }

  return parentsOfThatBox.some((operator: string) => boxHasMultipleOutputs(operator));
}

/*
 * Retrieving the outputs of an operator
 */
function getOperatorsOutputs(operatorId: string): Array<string> {
  if (typeof (state.scenario.data.operators[operatorId]) === 'undefined') {
    return [];
  }
  return Object.keys(state.scenario.data.operators[operatorId].properties.outputs);
}

/*
 * Draw a box in an x,y position
 */
function drawABox(operatorId: string, x: number, y: number) {
  state.scenario.data.operators[operatorId].left = x;
  state.scenario.data.operators[operatorId].top = y;
}

/*
 * Resetting the positions of all boxes in the scenario
 */
function resetBoxPositions() {
  Object.keys(state.scenario.data.operators).forEach((operatorId) => {
    drawABox(operatorId, -1, -1);
  });
}

/*
 * Recovery of the different stages of the flowchart
 */
function getFlowchartFloors(floor: number) {
  if (typeof (state.scenario.flowchartLevels[floor - 1]) !== 'undefined' && state.scenario.flowchartLevels[floor - 1].length) {
    Object.keys(state.scenario.flowchartLevels[floor - 1]).forEach((key) => {
      const operatorId = state.scenario.flowchartLevels[floor - 1][key];
      if (typeof (operatorId) !== 'undefined') {
        // On récupère les enfants de chacune des boites
        for (let i = 1; i <= Object.keys(state.scenario.data.operators[operatorId].properties.outputs).length; i++) {
          Object.keys(state.scenario.data.links).forEach((keyLink) => {
            const link = state.scenario.data.links[keyLink];
            if (link.fromOperator === operatorId && link.fromConnector === `output_${i}`) {
              if (typeof (state.scenario.flowchartLevels[floor]) === 'undefined') {
                state.scenario.flowchartLevels[floor] = [];
              }

              if (state.scenario.flowchartLevels[floor].indexOf(link.toOperator) === -1) {
                state.scenario.flowchartLevels[floor].push(link.toOperator);
              }
            }
          });
        }
      }

      // On vérifie si l'opérateur ne figure pas dans les étages au-dessus
      if (floor > 0) {
        for (let l = 0; l < floor - 1; l++) {
          if (state.scenario.flowchartLevels[l].indexOf(operatorId) >= 0) {
            // On retire l'opérateur de l'étage du dessus
            state.scenario.flowchartLevels[l].splice(state.scenario.flowchartLevels[l].indexOf(operatorId), 1);
          }
        }
      }
    });

    getFlowchartFloors(floor + 1);
  }
}

function getToOperatorLink(operatorId: string, fromConnector: string) {
  const filterResult = Object.keys(state.scenario.data.links)
    .filter(
      (l) => state.scenario.data.links[l].fromOperator === operatorId && state.scenario.data.links[l].fromConnector
        === fromConnector,
    )
    .map((l) => state.scenario.data.links[l].toOperator ?? '')
    .filter((n) => n !== '');

  return filterResult[0] ?? '';
}

function getMaxX(operatorsId: Array<string>): number {
  let maxX = 0;

  operatorsId.forEach((key) => {
    const currentX = state.scenario.data.operators[key].left;
    maxX = currentX > maxX ? currentX : maxX;
  });

  return (maxX + (drawingTreeConfig.boxSizeWidth));
}

function getMinX(operatorsId: Array<string>): number {
  let minX = getMaxX(operatorsId);

  operatorsId.forEach((key) => {
    const currentX = state.scenario.data.operators[key].left;
    minX = currentX < minX ? currentX : minX;
  });

  return minX - drawingTreeConfig.problemIconWidth;
}

function getNearestX(x: number) {
  let minX = getMinX(Object.keys(state.scenario.data.operators));

  Object.keys(state.scenario.data.operators).forEach((key) => {
    const currentX = state.scenario.data.operators[key].left;
    minX = currentX <= x && currentX > minX ? currentX : minX;
  });

  return minX;
}

function getMaxY(operatorsId: Array<string>) {
  let maxY = 0;
  const heightToUse = drawingTreeConfig.boxSizeHeight;

  operatorsId.forEach((key) => {
    const currentY = state.scenario.data.operators[key].top;
    maxY = currentY > maxY ? currentY : maxY;
  });

  return maxY + heightToUse + (drawingTreeConfig.plusHeight / 2);
}

function getOperatorPosition(operatorId: string) {
  const pos = [];
  pos[0] = state.scenario.data.operators[operatorId].left;
  pos[1] = state.scenario.data.operators[operatorId].top;
  return pos;
}

function isOtherBoxAtPos(operatorId: string, x: number, y: number): boolean|string {
  const heightToUse = drawingTreeConfig.boxSizeHeight;

  let result: string|boolean;
  result = false;
  Object.keys(state.scenario.data.operators)
    .some(
      (key) => {
        if (operatorId !== key && (state.scenario.data.operators[key].top <= y
            && (state.scenario.data.operators[key].top + heightToUse) >= y)
          && (state.scenario.data.operators[key].left <= x && (state.scenario.data.operators[key].left + drawingTreeConfig.boxSizeWidth) >= x)
        ) {
          result = key;
          return true;
        }
        return false;
      },
    );

  return result;
}

function getFloorOfOperator(operatorId: any): any {
  let floorToReturn = null;

  for (let i = 0; i < state.scenario.flowchartLevels.length; i++) {
    if (state.scenario.flowchartLevels[i].indexOf(operatorId) >= 0) {
      floorToReturn = i;
      break;
    }
  }

  return floorToReturn;
}

function moveParentsOfBox(operatorId: string, currentFloor: number, nbBoxesOnLeft: number): void {
  const widthDecal = drawingTreeConfig.horizontalSpaceBetweenBoxes + drawingTreeConfig.boxSizeWidth;
  const parentsDone: Array<string> = [];
  let originParent = operatorId;
  let floorParent = currentFloor;

  // On décale aussi la boite parente et les boites de droite
  while (42) {
    let resultLoop = true;
    const parents = getOperatorParents(originParent);
    if (parents.length === 0) {
      break;
    }

    // eslint-disable-next-line consistent-return,no-loop-func
    parents.forEach((parentId, index) => {
      // On vérifie si le parent est dans l'étage juste au dessus, sinon on le traite pas
      if (!state.scenario.flowchartLevels[floorParent - 1] || state.scenario.flowchartLevels[floorParent - 1].indexOf(parentId) === -1) {
        return true;
      }

      if (parentsDone.indexOf(parentId) === -1) {
        parentsDone.push(parentId);

        /**
         * On récupère la position du parent dans le tableau des étages
         * et on décale les box de droite + le parent jusqu'en haut
         * sauf si il s'agit de la box la plus à droite de l'étage
         */
        if (floorParent - 1 >= 0) {
          const indexParentInTree = state.scenario.flowchartLevels[floorParent - 1].indexOf(parentId);

          if (indexParentInTree !== -1 && indexParentInTree < state.scenario.flowchartLevels[floorParent - 1].length - 1) {
            // On boucle sur toutes les box à droite du parent pour les décaler
            for (let i = indexParentInTree + 1; i < state.scenario.flowchartLevels[floorParent - 1].length; i++) {
              const posRightBox = getOperatorPosition(state.scenario.flowchartLevels[floorParent - 1][i]);
              posRightBox[0] += nbBoxesOnLeft * widthDecal;
              drawABox(state.scenario.flowchartLevels[floorParent - 1][i], posRightBox[0], posRightBox[1]);
            }
          }

          const posParent = getOperatorPosition(parentId);
          posParent[0] += nbBoxesOnLeft * widthDecal;
          while (isOtherBoxAtPos(parentId, posParent[0], posParent[1])) {
            posParent[0] += widthDecal;
          }

          drawABox(parentId, posParent[0], posParent[1]);
          originParent = parentId;
          floorParent -= 1;
        } else {
          const posParent = getOperatorPosition(parentId);
          posParent[0] += nbBoxesOnLeft * widthDecal;
          while (isOtherBoxAtPos(parentId, posParent[0], posParent[1])) {
            posParent[0] += widthDecal;
          }

          drawABox(parentId, posParent[0], posParent[1]);
          resultLoop = false;
          return false;
        }
      } else if (floorParent - 1 >= 0) {
        originParent = parentId;
        floorParent -= 1;
      } else {
        resultLoop = false;
        return false;
      }
    });

    if (!resultLoop) {
      break;
    }
  }
}

function layoutABox(currentFloor: number, operatorId: string) {
  const heightToUse = drawingTreeConfig.boxSizeHeight;

  const widthDecal = drawingTreeConfig.horizontalSpaceBetweenBoxes + drawingTreeConfig.boxSizeWidth;
  const heightDecal = drawingTreeConfig.verticalSpaceBetweenBoxes + heightToUse;

  let newX = 0;
  let newY = 0;

  // On récupère les parents de la box courante
  const parents = getOperatorParents(operatorId);
  if (Object.keys(parents).length > 1) {
    /** Cas où la box operatorId a plusieurs parents */
    // On récupère le parent le plus haut et l'enfant le plus bas
    let highestParent = '';
    let lowestParent = '';

    parents.forEach((k) => {
      const v = state.scenario.data.operators[k];

      if (highestParent === '') {
        highestParent = k;
      } else if (v.top < state.scenario.data.operators[highestParent].top) {
        highestParent = k;
      }

      if (lowestParent === '') {
        lowestParent = k;
      } else if (v.top > state.scenario.data.operators[lowestParent].top) {
        lowestParent = k;
      }
    });

    // On prend l'enfant le plus à gauche du lowestParent et on récupère son left
    let childOfHighest = getToOperatorLink(highestParent, 'output_1');

    if (childOfHighest === operatorId) {
      childOfHighest = getToOperatorLink(highestParent, 'output_2');
    }

    const lowestLeft = childOfHighest !== '' ? state.scenario.data.operators[childOfHighest].left : 0;
    const highestLeft = state.scenario.data.operators[highestParent].left;

    if (highestLeft > 0 && lowestLeft > 0) {
      if (Object.keys(parents).length === 2) {
        newX = highestLeft > lowestLeft ? lowestLeft : highestLeft;
      } else {
        let marginLeft = Math.abs(highestLeft - lowestLeft);
        marginLeft += highestLeft;
        newX = (highestLeft - lowestLeft < 0) ? marginLeft : marginLeft + drawingTreeConfig.problemIconWidth;
      }
    } else {
      /** Si mauvais calcul, on retombe sur le calcul classique */
      // On calcule le X en fonction de la box la plus à gauche et la plus à droite
      // On récupère le MinX et le MaxX des parents
      const minLefBox = getMinX(parents);
      const maxLeftBox = getMaxX(parents);
      newX = Math.round(minLefBox + ((maxLeftBox - minLefBox) / 2) - (drawingTreeConfig.boxSizeWidth / 2) + (drawingTreeConfig.problemIconWidth / 2));

      // On a calculé le milieu entre la box la plus à gauche, et celle la plus à droite parmi les box à merger.
      // On va maintenant récupérer le X de la box qui est la plus proche à gauche du point milieu pour éviter
      // tout décalage de l'arbre parmi toutes les boites au dessus
      newX = getNearestX(newX);
    }

    // On prend le Y max des parents directs de la box
    newY = state.scenario.data.operators[lowestParent].top + heightDecal;
  } else {
    /** Cas où la box operatorId n'a qu'un seul parent */
    const originId = parents[0];

    // On récupère la position de la box parente
    const originPos = getOperatorPosition(originId);

    if (boxHasMultipleOutputs(originId)) {
      /** Le parent dispose de plusieurs sorties */
      // Calcul de la position X de la boite si le parent est multi-outputs
      const operatorOutputs = getOperatorsOutputs(originId);
      const operatorOutputNb = operatorOutputs.length;
      let connectorId: any = getFromConnectorBetweenTwoBoxes(originId, operatorId);
      connectorId = connectorId.split('_').pop();
      const connectorIndex = parseInt(connectorId, 10);

      const pairMode = !(operatorOutputNb % 2);

      if (pairMode) {
        if (connectorIndex <= operatorOutputNb / 2) {
          newX = originPos[0] - ((Math.abs((operatorOutputNb / 2) - connectorIndex) + 1) * widthDecal);
        } else {
          newX = originPos[0] + ((Math.abs((operatorOutputNb / 2) - connectorIndex)) * widthDecal);
        }
      } else {
        const midIndex = Math.ceil(operatorOutputNb / 2) + 1;
        if ((midIndex - 1) === connectorIndex) {
          newX = originPos[0] - 0; // TODO  ESLINT prefer-destructuring
        } else if (connectorIndex < midIndex) {
          newX = originPos[0] - (Math.abs((connectorIndex - midIndex) + 1) * widthDecal);
        } else {
          newX = originPos[0] + (Math.abs((connectorIndex - midIndex) + 1) * widthDecal);
        }
      }
    } else {
      /** Le parent n'a qu'un seul output */
      // On aligne la box en X avec son parent
      newX = originPos[0] - 0; // TODO  ESLINT prefer-destructuring
    }
    // On prend la position Y du parent et on ajoute le décalage
    newY = originPos[1] + heightDecal;
  }

  // On vérifie si la box est en superposition par rapport à une autre box
  if (isOtherBoxAtPos(operatorId, newX, newY)) {
    /** La box est en superposition */
    // On décale la box courante vers la droite
    newX += widthDecal;
  }

  /* On vérifie si le parent de la box courante est positionné juste au dessus ou pas. Dans le cas d'un filtre,
    le parent doit normalement être décalé à gauche/droite selon la sortie. Si il y a une boite située juste au
    dessus et que ce n'est pas son parent direct, on va décaler la boite située au dessus , les boites à sa droite,
    et les parents jusqu'à la racine
   */
  if (currentFloor > 0) {
    let boxAboveButNotParent;
    let sameVerticalLineFloor = 0; // Etage de la box détectée sur la même ligne verticale

    // On va chercher une boite sur la même ligne verticale, en partant du niveau courant, et en remontant à la racine
    for (let i = currentFloor - 1; i >= 0; i--) {
      const multiplicator = currentFloor - i;
      boxAboveButNotParent = isOtherBoxAtPos(operatorId, newX, newY - (multiplicator * heightDecal));

      if (boxAboveButNotParent !== false && typeof (boxAboveButNotParent) !== 'undefined') {
        sameVerticalLineFloor = i;
        break;
      }
    }

    const parentsOfThatBox = getAllParentsOfBoxToRoot(operatorId);
    if (boxAboveButNotParent !== false && typeof (boxAboveButNotParent) === 'string'
      && parentsOfThatBox.indexOf(boxAboveButNotParent) === -1) {
      // La box détectée sur la même ligne verticale n'est pas parente de la box courante
      // On récupère les boites de l'étage de la box trouvée
      const boxesOfMatchedFloor = state.scenario.flowchartLevels[sameVerticalLineFloor];
      const parentsOnTheMatchedFloor = parentsOfThatBox.filter((n: any) => boxesOfMatchedFloor.indexOf(n) !== -1);

      // Pour chaque parent que l'on trouve, on va déterminer sa position par rapport à la box sur la même ligne verticale.
      // Selon la position (gauche/droite), on va décaler toutes les boites
      if (parentsOnTheMatchedFloor.length > 0) {
        const posSameVerticalLine = getOperatorPosition(boxAboveButNotParent);

        for (let i = 0; i < parentsOnTheMatchedFloor.length; i++) {
          // On détecte la position de la boite parente en fonction de la boite de la même ligne verticale
          const posParent = getOperatorPosition(parentsOnTheMatchedFloor[i]);

          if (posParent[0] < posSameVerticalLine[0]) {
            // Le parent est sur la gauche
            const indexParentInTree = state.scenario.flowchartLevels[sameVerticalLineFloor].indexOf(parentsOnTheMatchedFloor[i]);
            if (indexParentInTree !== -1 && indexParentInTree < state.scenario.flowchartLevels[sameVerticalLineFloor].length - 1) {
              // On boucle sur toutes les box à droite du parent pour les décaler
              for (let j = indexParentInTree + 1; j < state.scenario.flowchartLevels[sameVerticalLineFloor].length; j++) {
                const posRightBox = getOperatorPosition(state.scenario.flowchartLevels[sameVerticalLineFloor][j]);
                posRightBox[0] += widthDecal;

                while (isOtherBoxAtPos(state.scenario.flowchartLevels[sameVerticalLineFloor][j], posRightBox[0], posRightBox[1])) {
                  posRightBox[0] += widthDecal;
                }

                drawABox(state.scenario.flowchartLevels[sameVerticalLineFloor][j], posRightBox[0], posRightBox[1]);
              }
            }

            if (!boxHasMultipleParents(operatorId)) {
              // On décale les parents
              moveParentsOfBox(boxAboveButNotParent, sameVerticalLineFloor, 1);
            }
          } else {
            // Le parent est sur la droite
            newX += widthDecal;

            while (isOtherBoxAtPos(operatorId, newX, newY)) {
              newX += widthDecal;
            }

            if (!boxHasMultipleParents(operatorId)) {
              // On décale les parents
              moveParentsOfBox(operatorId, currentFloor, 1);
            }
          }
        }
      }
    } else if (boxHasMultipleParents(operatorId)) {
      // On vérifie si la boite a plusieurs parents directs
      // On récupère la liste de ses parents directs
      const directParentsOfThatBox = getOperatorParents(operatorId);
      // On va boucler sur les parents directs de la box pour vérifier lesquels sont sur la même
      // position horizontale (même X) que la boite courante, à l'exception de la boite juste au dessus
      const currentXOfThatBox = newX;

      directParentsOfThatBox.forEach((kDirectParent) => {
        const vDirectParent = state.scenario.data.operators[kDirectParent];
        // On vérifie si le parent est au même X et qu'il n'est pas situé juste au-dessus de
        // l'opérateur courante
        if (vDirectParent.left === currentXOfThatBox && (vDirectParent.top + heightDecal) < newY) {
          // On récupère l'étape de la boite concernée
          const floorOfThatParent = getFloorOfOperator(kDirectParent);

          // Le parent est sur la gauche
          const indexParentInTree = state.scenario.flowchartLevels[floorOfThatParent].indexOf(kDirectParent);
          // On boucle sur toutes les box à droite du parent pour les décaler
          for (let i = indexParentInTree; i < state.scenario.flowchartLevels[floorOfThatParent].length; i++) {
            const posRightBox = getOperatorPosition(state.scenario.flowchartLevels[floorOfThatParent][i]);
            posRightBox[0] += widthDecal;

            while (isOtherBoxAtPos(state.scenario.flowchartLevels[floorOfThatParent][i], posRightBox[0], posRightBox[1])) {
              posRightBox[0] += widthDecal;
            }

            drawABox(state.scenario.flowchartLevels[floorOfThatParent][i], posRightBox[0], posRightBox[1]);
          }
        }
      });
    }
  }

  /**
   * On vérifie si la box a plusieurs sorties et si une box est collée à gauche
   */
  // Récupération du nombre de sorties de la box courante
  const currentBoxNbOutputs = Object.keys(state.scenario.data.operators[operatorId].properties.outputs).length;
  if (currentBoxNbOutputs > 1) {
    // On calcule combien il y a de sorties à gauche du milieu de la box
    const nbOutputsOnLeftOfBox = Math.floor(currentBoxNbOutputs / 2);

    // On calcule combien il y a de box sur la gauche de la boite, dans la limite du nombre de sorties à gauche
    let nbBoxesOnLeft = 0;
    for (let i = 1; i <= nbOutputsOnLeftOfBox; i++) {
      if (isOtherBoxAtPos(operatorId, newX - (i * widthDecal), newY)) {
        nbBoxesOnLeft += 1;
      }
    }

    newX += nbBoxesOnLeft * widthDecal;

    if (!boxHasMultipleParents(operatorId) && nbBoxesOnLeft > 0) {
      // On décale les parents
      moveParentsOfBox(operatorId, currentFloor, nbBoxesOnLeft);
    }
  }

  // On ajoute les coordonnées de la box à arbreData
  drawABox(operatorId, newX, newY);
}

function drawFlowchartFloors(floor: number) {
  if (typeof (state.scenario.flowchartLevels[floor]) !== 'undefined' && state.scenario.flowchartLevels[floor].length > 0) {
    Object.keys(state.scenario.flowchartLevels[floor]).forEach((key1) => {
      const operatorId = state.scenario.flowchartLevels[floor][key1];
      layoutABox(floor, operatorId);
    });

    if (typeof (state.scenario.flowchartLevels[floor + 1]) !== 'undefined' && state.scenario.flowchartLevels[floor + 1].length > 0) {
      drawFlowchartFloors(floor + 1);
    }
  }
}

function centerFiltersBoxes() {
  const initialFloor = state.scenario.flowchartLevels.length - 1;
  const boxTotalWidth = drawingTreeConfig.boxSizeWidth + drawingTreeConfig.problemIconWidth
    + drawingTreeConfig.deleteLeftMargin;

  for (let i = initialFloor; i >= 0; i--) {
    Object.keys(state.scenario.flowchartLevels[i]).forEach((k) => {
      const v = state.scenario.flowchartLevels[i][k];
      // Pour chaque boite de l'étage, on vérifie si son/ses parent(s) est/sont un filtre
      const parentsOfBox = getOperatorParents(v);

      // Pour chaque parent trouvé, on va vérifier son type (filtre), le nombre de sorties (2)
      parentsOfBox.forEach((v2, k2) => {
        // On vérifie si le parent se trouve dans le niveau juste au dessus, sinon on zappe
        if (state.scenario.flowchartLevels[i - 1].indexOf(v2) === -1) return true;

        if (state.scenario.data.operators[v2].properties.class === 'filtre'
          && Object.keys(state.scenario.data.operators[v2].properties.outputs).length === 2) {
          // On récupère ses enfants directs
          const children = getOperatorChildren(v2);

          const childrenOnTheNextFloor = children.filter((n) => state.scenario.flowchartLevels[i].indexOf(n) !== -1);

          // On vérifie si les enfants du filtre sont sur le même niveau, sinon on zappe
          if (childrenOnTheNextFloor.length < 2) return true;

          // On récupère la position des enfants directs
          const operatorPosition = getOperatorPosition(v2);
          const originalLeft = operatorPosition[0];

          if (children.length === 2) {
            const left1 = state.scenario.data.operators[children[0]].left;
            const left2 = state.scenario.data.operators[children[1]].left;

            if (left1 !== left2 && (Math.abs(left2 - left1) / 2) > boxTotalWidth) {
              // La box courante est un filtre qui peut être recentré, on calcule sa nouvelle position
              const originPos = Math.min(left1, left2);

              // On calcule combien de box peuvent être placées virtuellement entre la boite de gauche et la boite de droite
              const nbBoxesVirtualMiddle = (Math.max(left1, left2) - (originPos + boxTotalWidth)) / boxTotalWidth;

              const decal = nbBoxesVirtualMiddle % 2 === 0 ? nbBoxesVirtualMiddle / 2 : Math.ceil(nbBoxesVirtualMiddle / 2);
              let newX = originPos + (decal * boxTotalWidth);

              // On vérifie si une boite se trouve à cette position, si oui, on décale d'un cran vers la droite
              while (isOtherBoxAtPos(v2, newX, operatorPosition[1])) {
                newX += boxTotalWidth;
              }

              // On calcule le retrait vers la gauche exprimé en nombre de box
              const diffXBoxes = (originalLeft - newX) / boxTotalWidth;

              // On redessine la boite
              drawABox(v2, newX, operatorPosition[1]);

              // On décale aussi les parents
              if (diffXBoxes > 0) {
                if (!boxHasMultipleParents(v2)) {
                  // On décale les parents
                  moveParentsOfBox(v2, i - 1, (0 - diffXBoxes));
                }
              }
            }
          }
        }

        return true;
      });
    });
  }
}

export function centerZoom(left: number|null = null, top: number|null = null) {
  const $container = state.$flowchart.parent();
  const heightToUse = drawingTreeConfig.boxSizeHeight;

  // On calcule le height total de l'arbre en prenant la position de la boite la plus basse
  let maxY = getMaxY(Object.keys(state.scenario.data.operators));

  // On ajoute une marge en bas pour l'icone de switch des branches 1-2
  maxY += drawingTreeConfig.switchIconHeight;
  state.$flowchart.height(maxY);

  let cx; let cy;
  // Si la position left/top a été défini
  if (left !== null && top !== null) {
    cx = left;
    cy = top;
  } else if (typeof (state.scenario.operatorIdToCenter) !== 'undefined'
    && state.scenario.operatorIdToCenter !== '') {
    // Sinon, on verifie si on doit centrer sur une box en particulier
    if (typeof (state.scenario.data.operators[state.scenario.operatorIdToCenter]) !== 'undefined') {
      cx = state.scenario.data.operators[state.scenario.operatorIdToCenter].left
        + (drawingTreeConfig.boxSizeWidth / 2);
      cy = state.scenario.data.operators[state.scenario.operatorIdToCenter].top
        + heightToUse + 20;
    }
    state.scenario.operatorIdToCenter = '';
  } else {
    // Sinon on centre au milieu de l'arbre
    // On calcule le milieu de l'arbre
    cx = state.$flowchart.width() / 2;
    cy = state.$flowchart.height() / 2;
  }
  // Panzoom initialization...
  state.$flowchart.panzoom();
  if (typeof cx !== 'undefined' && typeof cy !== 'undefined') {
    state.$flowchart.panzoom('pan', ($container.width() / 2 - cx),
      ($container.height() / 2 - cy));
  }
}

function calculateHorizontalPositions() {
  let minLeft = getMinX(Object.keys(state.scenario.data.operators));

  // On ajoute un décalage pour le div de confirmation d'un merge des branches
  minLeft -= drawingTreeConfig.mergeConfirmationBoxWidth;

  if (minLeft < 0) {
    // On va boucler sur tous les opérateurs pour ajouter minLeft à la position left
    Object.keys(state.scenario.data.operators).forEach((operatorId) => {
      state.scenario.data.operators[operatorId].left += Math.abs(minLeft);
    });
  } else {
    // On va boucler sur tous les opérateurs pour retirer minLeft à la position left
    Object.keys(state.scenario.data.operators).forEach((operatorId) => {
      state.scenario.data.operators[operatorId].left -= minLeft;
    });
  }
}

export function coreDrawUI() {
  state.scenario.flowchartLevels = [];

  resetBoxPositions();
  const rootOfTree = getRootOfTree();

  if (rootOfTree === null) {
    return;
  }

  state.scenario.flowchartLevels[0] = [rootOfTree];
  drawABox(rootOfTree, 0, 0);
  getFlowchartFloors(1);

  if (state.scenario.flowchartLevels.length > 0) {
    // Dans le cas où on a des enfants à dessiner, on va entamer la boucle de dessin des box
    drawFlowchartFloors(1);

    // Une fois le dessin terminé, on va recentrer les box filtres à double sortie qui ne sont pas centrées par rapport à leurs enfants
    centerFiltersBoxes();
  }

  calculateHorizontalPositions();

  /**
   * Mise a jour de la taille en largeur de la div de l'arbre
   */
  const boxWidth = getMaxX(Object.keys(state.scenario.data.operators))
    + drawingTreeConfig.deleteWidth + drawingTreeConfig.problemIconWidth + drawingTreeConfig.deleteLeftMargin;

  state.$flowchart.width(boxWidth);
  const horizontalCenter = Math.floor(window.innerWidth / 2);

  if (Object.keys(state.scenario.data.operators).length > 1) {
    state.$flowchart.panzoom('pan', (horizontalCenter - (boxWidth / 2)), 0);
  } else {
    state.$flowchart.panzoom('pan', 0, 0);
  }

  // Rétablir le zoom courant
  state.$flowchart.panzoom('zoom', state.scenario.currentZoom, {
    animate: false,
    contain: false,
  });
  state.$flowchart.flowchart('setPositionRatio', state.scenario.currentZoom);

  centerZoom();
  state.$flowchart.flowchart('setData', state.scenario.data);

  state.operators.forEach((operator) => {
    /* Gestion du positionnement des outputs flowchart */
    const operatorOutputs = document.querySelectorAll(`${operator.targetDiv} .flowchart-operator-inputs-outputs
            .flowchart-operator-outputs .flowchart-operator-connector-set`);
    const lengthBox = operatorOutputs.length > 2 ? 225 : 50;
    const space = lengthBox / (operatorOutputs.length - 1);

    (Array.from(operatorOutputs) as HTMLElement[])
      .forEach((outputConnectorElement: HTMLElement, key: number) => {
        const outputConnectorElementStyle = window.getComputedStyle(outputConnectorElement, null);
        // eslint-disable-next-line no-param-reassign
        outputConnectorElement.style.left = `${key * space}px`;
      });
  });

  state.$flowchart.flowchart('redrawLinksLayer');
}

// getOperatorLineage returns all operators ids that are in the lineage of the given operatorId (including itself - optionally)
export function getOperatorLineage(operatorId: string, includeMe = true): Array<string> {
  const ancestry = includeMe ? [operatorId] : [];

  const parents = getOperatorParents(operatorId);
  while (parents.length !== 0) {
    const p = parents.splice(0, 1);
    if (p.length !== 1) {
      break;
    }
    ancestry.push(p[0]);
    getOperatorParents(p[0]).forEach((g) => parents.push(g));
  }

  const children = getOperatorChildren(operatorId);
  while (children.length !== 0) {
    const p = children.splice(0, 1);
    if (p.length !== 1) {
      break;
    }
    ancestry.push(p[0]);
    getOperatorChildren(p[0]).forEach((g) => children.push(g));
  }

  return ancestry;
}

// Clean bad operators saved in data_flowchart preventing opening the campaign
export function cleanOperators(data: Record<string, any>) {
  Object.keys(data.operators).forEach((operatorId) => {
    if (typeof data.operators[operatorId].internal !== 'undefined') {
      delete data.operators[operatorId].internal;
    }

    if (!operatorId || operatorId === '') {
      delete data.operators[operatorId];
    }
  });
}

// updateOperators calculates operators in memory for active scenario
// takes data typically from jquery flowchart format to obtain values needed for vue
function updateOperators(data: AutomatedScenarioData) {
  state.operators = Object.keys(data.operators).map((operatorId) => {
    const op = data.operators[operatorId];
    const { id } = op.custom;
    const metadata = getComponentMetadataById(op.custom.id ?? '');
    const grayscaled = false;
    const disabled = false;
    const selectable = false;
    const analyticsData = null;

    if (!metadata) {
      return {
        operatorId, label: '', icon: '', hasConfiguration: false, kind: '', targetDiv: '', id: '', grayscaled, disabled, selectable, analyticsData
      };
    }

    const { icon = '', kind = '', hasConfiguration } = metadata.Meta;

    const label = metadata.Label(op.custom);

    const targetDiv = `#flowchart_operator_${operatorId}`;

    return {
      operatorId, label, icon, hasConfiguration, kind, targetDiv, id, grayscaled, disabled, selectable, analyticsData
    };
  }).filter((n) => n.kind !== '');
}

// updateNbOutputs calculates output for each operator
function updateNbOutputs() {
  if (!state.scenario) {
    return;
  }

  state.nbOutputs = state.operators.map((op) => {
    // first we find the function that will give us the number of outputs
    const { id, operatorId } = op;
    const metadata = getComponentMetadataById(id);
    let nbOutputs = 1;
    const data = state.scenario.getOperatorData(operatorId) ?? null;

    if (metadata === null || data === null) {
      nbOutputs = 0; // should not happen
    } else {
      nbOutputs = asInt(metadata.Outputs(data));
    }

    return { operatorId, nbOutputs };
  })
    // then we filter out the operators that don't have a function
    .filter((op) => op !== null)
    // then we convert it into a map
    .reduce((acc, op) => {
      const { operatorId, nbOutputs } = op;
      const links = Object.keys(state.scenario.data.links ?? {})
        .filter((l) => state.scenario.data.links[l].fromOperator === operatorId)
        .reduce((p, c) => {
          const output = state.scenario.data.links[c].fromConnector ?? '';
          return {
            ...p,
            [output]: true,
          };
        }, {});

      const outputs: Array<OperatorOutput> = [];
      const outputMap: {
        [key: string]: AutomatedScenarioOutput;
      } = {};

      for (let i = 1; i <= nbOutputs; i++) {
        const key = `output_${i}`;
        outputs.push({
          index: i,
          visible: !Object.prototype.hasOwnProperty.call(links, key),
          connectorToMerge: false,
          connectorCanBeMerged: false,
          connectorSelectedForMerge: false,
        });

        outputMap[key] = {
          label: key,
          multipleLinks: true,
        };
      }

      if (state.scenario) {
        state.scenario.data.operators[operatorId].properties.outputs = outputMap;
      }

      return {
        ...acc,
        [operatorId]: outputs,
      };
    }, {});
}

/*
  Def : Checks the compatibility of an operator with its parents
  Returns true if the verified operator is compatible with its parents
 */
export function compatibilityOperatorsCheck(operatorId: string): boolean {
  if (typeof (operatorId) === 'undefined') {
    return true;
  }

  const { id } = state.scenario.getOperatorData<{ id: string }>(operatorId) ?? { id: '' };
  if (id === '') {
    return true;
  }

  let isCompatible = true;
  const operatorParents = getOperatorParents(operatorId)
    .map((l) => state.scenario.data.operators[l].custom.id ?? '');

  operatorParents.every((operatorType: string) => {
    const metadata = getComponentMetadataById(operatorType);
    if (metadata !== null) {
      const incompatibleNextBox = (metadata.Meta?.incompatibilities && metadata.Meta?.incompatibilities[state.scenario.type]
      && metadata.Meta?.incompatibilities[state.scenario.type]?.next_operators)
        ? metadata.Meta?.incompatibilities[state.scenario.type]?.next_operators : [];

      if (incompatibleNextBox && incompatibleNextBox.includes(id)) {
        isCompatible = false;
        return false;
      }
    }
    return true;
  });

  return isCompatible;
}

export function preprocessData(data: AutomatedScenarioData): AutomatedScenarioData {
  let dt = preprocessFirstBox(data);
  dt = preprocessCoordinates(dt);
  cleanOperators(dt);
  updateOperators(dt);

  if (state.scenario) {
    state.scenario.data = dt;
  }

  // number of outputs can be dependent on the operator data (e.g. ABTest)
  updateNbOutputs();

  // init/reset input class
  state.input = {};
  state.operators.forEach((op) => {
    state.input[op.operatorId] = {
      visible: true,
      connectorCanBeMerged: false,
      connectorSelectedForMerge: false,
    };
  });

  return dt;
}

function resetFlowChartTick() {
  state.flowchartTick = '0';
}

export const setDefaultDisplayUrl = (url: string) => {
  state.defaultDisplayUrl = url;
};

export const resetDefaultDisplayUrl = () => {
  state.defaultDisplayUrl = '';
};

export function resetSelectedOperator() {
  state.selectedOperator = {
    operatorId: '',
    hasActive: false,
    possibleOutputs: {
      action: [],
      filtre: [],
      declencheur: [],
    },
    meta: { ...emptyMeta },
    outputs: [],
    mergeBranchData: null,
    closureBranchData: null,
    operatorAddMethod: null,
    dialog: null,
    currentConfigurationForm: null,
    editingTemplate: null,
  };
}

export function setCurrentConfigurationForm(data: any) {
  state.selectedOperator.currentConfigurationForm = data;
}

export function setConfiguringSegment(value: boolean) {
  state.configuringSegment = value;
}

export function setDataFromTemplateBuilder(value: Record<string, any>) {
  state.dataFromTemplateBuilder = value;
}

export function resetDataFromTemplateBuilder() {
  state.dataFromTemplateBuilder = {};
}

export function resetLeftToolbar() {
  state.leftToolbar.operatorSelectorStep = StepsOperatorSelector.SELECT;
  state.leftToolbar.show = {
    operatorEditForm: false,
    operatorOutputs: false,
    operatorSelector: false,
    settingsPanel: false,
    historyPanel: false,
  };
  setConfiguringSegment(false);
}

export function backOperatorSelect(operatorEditForm: boolean) {
  if (operatorEditForm) {
    resetSelectedOperator();
    resetDefaultDisplayUrl();
    resetLeftToolbar();
  } else if (state.leftToolbar.operatorSelectorStep > 0) {
    if (state.configuringSegment) {
      setConfiguringSegment(false);
    } else {
      state.leftToolbar.operatorSelectorStep -= 1;
    }
  } else {
    resetLeftToolbar();
  }
}

/*
 * Show campaign editor
 */
export function show() {
  state.isEditorVisible = true;
}

/*
 * Hide campaign editor
 */
export function hide() {
  state.isEditorVisible = false;
}

export function updateDateModificationOfCurrentScenario(date: string) {
  state.scenario.dateModification = date;
}

/*
 * Minimize campaign editor
 */
export async function minimizeEditorCampaign(hideEditorModal = false) {
  if (state.scenario?.id > 0 && state.scenario.type === TypeCampaignEnum.AUTOMATION) {
    state.automationCampaigns.forEach((item, key) => {
      if (item.scenario.id === state.scenario.id) {
        state.automationCampaigns[key].leftToolbar = {...state.leftToolbar};
        state.automationCampaigns[key].selectedOperator = {...state.selectedOperator};
      }
    });
  } else if (state.scenario?.id > 0 && state.scenario.type === TypeCampaignEnum.BULK) {
    const bulkCampaignStatus = await Get<CampaignBulkRaw>({
      name: 'CampaignsBulk',
      id: state.scenario?.id,
      keyName: 'id_campaign_bulk',
      fields: [
        'status',
      ],
    });

    if (bulkCampaignStatus.item && bulkCampaignStatus.item.status === CampaignsBulkStatusEnum.BEING_EDITED) {
      // We update the status to "draft" and the date_modification in state and in database
      const dateModification = moment()
        .format('YYYY-MM-DD HH:mm:ss');

      await updateCampaignBulk([{
        id_campaign_bulk: state.scenario?.id,
        id_shop: UserState.activeShop?.id ?? 0,
        status: CampaignsBulkStatusEnum.DRAFT,
        date_send: null,
        date_modification: dateModification,
      }]);

      updateDateModificationOfCurrentScenario(dateModification);
    }

    setRefreshScenariosList(true);

    state.bulkCampaigns.forEach((item, key) => {
      if (item.scenario.id === state.scenario.id) {
        state.bulkCampaigns[key].leftToolbar = {...state.leftToolbar};
        state.bulkCampaigns[key].selectedOperator = {...state.selectedOperator};
      }
    });
  }

  if (hideEditorModal) {
    hide();
  }

  /* we need to reset flowchart tick to start teleport in editor when campaign is displayed */
  resetFlowChartTick();
  resetSelectedOperator();
  resetDefaultDisplayUrl();
  resetLeftToolbar();
  state.scenario = new AutomatedScenario(0, state.scenario.type);
}

function parseSelectionFiltersData(data: any) {
  Object.keys(data).forEach((segmentKey) => {
    const elementsSegmentKeys = Object.keys(data[segmentKey]);
    const filteredElementsKeys = elementsSegmentKeys.filter((element) => element.includes('_picker'));

    if (filteredElementsKeys.length > 0) {
      filteredElementsKeys.forEach((element) => {
        data[segmentKey][element].forEach((elementData: any) => {
          if (elementData.hasOwnProperty('selection') && typeof elementData.selection === "string") {
            elementData.selection = JSON.parse(elementData.selection);
          }
        });
      });
    }
  });
}

/*
 * activate an automation campaign, load if not in memory else just show it
 */
async function activateAutomationCampaign(id: number): Promise<Maybe<AutomatedScenario>> {
  const ex = state.automationCampaigns.findIndex((s) => s.scenario.id === id);

  /* Some state infos need to be reset on new campaign activation */
  if (ex !== -1) {
    state.scenario = state.automationCampaigns[ex].scenario;
    state.leftToolbar = state.automationCampaigns[ex].leftToolbar;
    state.selectedOperator = state.automationCampaigns[ex].selectedOperator;
    resetFlowChartTick();

    preprocessData(state.scenario.data);
    show();
    return state.automationCampaigns[ex].scenario;
  }

  resetFlowChartTick();
  resetSelectedOperator();
  resetDefaultDisplayUrl();
  resetLeftToolbar();

  const workflow = await Get<CampaignAutomationRaw>({
    name: 'MarketingWorkflows',
    id,
    keyName: 'id_marketing_workflow',
    fields: [
      'id_marketing_workflow',
      'name_marketing_workflow',
      'data_marketing_workflow',
      'type_workflow',
      'unique',
      'type_unique',
      'type_retriggered',
      'trigger_time',
      'unit_time',
      'date',
    ],
  });

  if (workflow.item === null) {
    await showToastError(workflow.err);
    return null;
  }

  const scheduler = await List<any>({
    name: 'MarketingWorkflowsScheduler',
    settings: {
      offset: 0,
      limit: 1000,
      order: [],
      filter: [
        { field: 'id_workflow', value: id, operator: OperatorType.Equals },
      ],
    },
    fields: ['start_date', 'end_date'],
  });

  const dateRange: DateRangeWorkflow[] = [];
  if (scheduler.items) {
    scheduler.items.forEach((record) => {
      dateRange.push({
        date_start: record.start_date,
        date_end: record.end_date,
      });
    });
  }

  const globalSettingsData: GlobalSettingsData = {
    name: workflow.item.name_marketing_workflow,
    type_workflow: workflow.item.type_workflow,
    unique: workflow.item.unique ? 1 : 0,
    type_unique: workflow.item.type_unique !== null ? workflow.item.type_unique : 0,
    type_retrigger: workflow.item.type_retriggered !== null ? workflow.item.type_retriggered : 0,
    time_unit_select: {
      value: workflow.item.trigger_time ?? 1,
      unit: workflow.item.unit_time ?? 'MONTH',
    },

    program_date_ranges: dateRange.length ? 1 : 0,
    date_ranges: dateRange.length ? JSON.stringify(dateRange) : '[]',
  };

  const campaignHistory = await getHistoryByCampaign(TypeCampaignEnum.AUTOMATION, id)

  let formattedDataWorkflow = JSON.parse(workflow.item.data_marketing_workflow ?? '{}');
  if (formattedDataWorkflow.operators === 'undefined' || !Object.keys(formattedDataWorkflow.operators).length) {
    formattedDataWorkflow = {
      links: {}, operators: {}, operatorTypes: {},
    };
  }

  const scenario = new AutomatedScenario(
    workflow.item.id_marketing_workflow,
    TypeCampaignEnum.AUTOMATION,
    globalSettingsData,
    formattedDataWorkflow,
    campaignHistory.historyId,
    campaignHistory.campaignHistoryJson,
    campaignHistory.currentHistoryIndex,
    campaignHistory.savedHistoryIndex,
    campaignHistory.campaignHistoryJsonLength,
    {
      show: false,
      dateInterval: {
        interval: IntervalDateEnum.LAST_30_DAYS,
      },
    },
    false,
    workflow.item.date,
  );

  const scenarioItem: AutomatedScenarioStateItem = {
    scenario,
    leftToolbar: state.leftToolbar,
    selectedOperator: state.selectedOperator,
  }
  state.automationCampaigns.push(scenarioItem);
  state.scenario = scenario;

  preprocessData(scenario.data);
  show();
  return scenario;
}

/*
 * activate a bulk campaign, load if not in memory else just show it
 */
async function activateBulkCampaign(id: number, visualisationMode = false, dateSend: string | null = null): Promise<Maybe<AutomatedScenario>> {
  const ex = state.bulkCampaigns.findIndex((s) => s.scenario.id === id);

  /* Some state infos need to be reset on new campaign activation */
  if (ex !== -1) {
    state.scenario = state.bulkCampaigns[ex].scenario;
    state.leftToolbar = state.bulkCampaigns[ex].leftToolbar;
    state.selectedOperator = state.bulkCampaigns[ex].selectedOperator;
    resetFlowChartTick();

    preprocessData(state.scenario.data);
    show();
    return state.bulkCampaigns[ex].scenario;
  }

  resetFlowChartTick();
  resetSelectedOperator();
  resetDefaultDisplayUrl();
  resetLeftToolbar();

  const bulkCampaign = await Get<CampaignBulkRaw>({
    name: 'CampaignsBulk',
    id,
    keyName: 'id_campaign_bulk',
    fields: [
      'id_campaign_bulk',
      'name',
      'data_campaign',
      'data_flowchart',
      'date_modification'
    ],
  });

  if (bulkCampaign.item === null) {
    await showToastError(bulkCampaign.err);
    return null;
  }

  const dataCampaign = JSON.parse(bulkCampaign.item.data_campaign);

  const globalSettingsData: GlobalBulkSettingsData = {
    name: bulkCampaign.item.name,
    time_sending: dataCampaign.time_sending,
    perfect_timing: dataCampaign.perfect_timing,
    slowdown: dataCampaign.slowdown,
    interval_between_sends: dataCampaign.interval_between_sends,
    commercial_campaign: dataCampaign.commercial_campaign ?? 1,
  };

  const campaignHistory = await getHistoryByCampaign(TypeCampaignEnum.BULK, id)

  let formattedDataFlowchart = JSON.parse(bulkCampaign.item.data_flowchart ?? '{}');
  if (formattedDataFlowchart.operators === 'undefined' || !Object.keys(formattedDataFlowchart.operators).length) {
    formattedDataFlowchart = {
      links: {}, operators: {}, operatorTypes: {},
    };
  }

  const scenario = new AutomatedScenario(
    bulkCampaign.item.id_campaign_bulk,
    TypeCampaignEnum.BULK,
    globalSettingsData,
    formattedDataFlowchart,
    campaignHistory.historyId,
    campaignHistory.campaignHistoryJson,
    campaignHistory.currentHistoryIndex,
    campaignHistory.savedHistoryIndex,
    campaignHistory.campaignHistoryJsonLength,
    {
      show: visualisationMode,
      dateInterval: {
        interval: IntervalDateEnum.LAST_30_DAYS,
      },
    },
    visualisationMode,
    bulkCampaign.item.date_modification,
  );

  if (dateSend) {
    scenario.dateSend = dateSend;
    scenario.analyticsMode.dateInterval = {
      interval: IntervalNextDateEnum.NEXT_30_DAYS
    };
  }

  const scenarioItem: AutomatedScenarioStateItem = {
    scenario,
    leftToolbar: state.leftToolbar,
    selectedOperator: state.selectedOperator,
  }
  state.bulkCampaigns.push(scenarioItem);
  state.scenario = scenario;

  preprocessData(scenario.data);

  show();
  return scenario;
}

export async function activateCampaign(id: number, type: TypeCampaignEnum, dateSend: string | null = null): Promise<Maybe<AutomatedScenario>> {
  if (type === TypeCampaignEnum.BULK) {
    let translation: any = await i18n;
    /* Before activating a bulk campaign, certain checks must be made. */
    const currentStatus = await getBulkCampaignStatus(id);
    if (currentStatus === null) {
      await showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
      return null;
    }

    if ([CampaignsBulkStatusEnum.DRAFT, CampaignsBulkStatusEnum.SCHEDULED].includes(currentStatus)) {
      /* If the campaign status is "draft" or "scheduled"
        => we check if the campaign is in the state, and if true, if the dateModification is the same as in database
          => if same date, we open from state
          => if different date, we open from database (updated by another user)
        => We update the campaign status to "being_edited";
        => then, we activate the campaign.
       */
      const indexInState = state.bulkCampaigns.findIndex((s) => s.scenario.id === id);

      if (indexInState !== -1) {
        /**
         * Campaign is already in the state, we check the date_modification
         * If date of database equals date of state, we open from the state
         * Otherwise, we remove from the state and open from database (updated by another user)
         */
        const bulkCampaignLastDateModification = await Get<CampaignBulkRaw>({
          name: 'CampaignsBulk',
          id,
          keyName: 'id_campaign_bulk',
          fields: [
            'date_modification'
          ],
        });

        if (bulkCampaignLastDateModification.item === null) {
          // No date returned, so we trigger an error, and we remove the campaign from the state and local storage
          state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);
          saveCampaignsInLocalStorage();
          await showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
          return null;
        }

        if (bulkCampaignLastDateModification.item.date_modification && state.bulkCampaigns[indexInState].scenario.dateModification !== bulkCampaignLastDateModification.item.date_modification) {
          // Dates are different, we remove the campaign from the state and local storage
          state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);
          saveCampaignsInLocalStorage();
        }
      }

      // We open the campaign and set the status to being_edited
      return await updateCampaignBulk([{
        id_campaign_bulk: id,
        id_shop: UserState.activeShop?.id ?? 0,
        status: CampaignsBulkStatusEnum.BEING_EDITED,
        date_send: null,
        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
      }]).then(async () => {
        return await activateBulkCampaign(id).then((result: Maybe<AutomatedScenario>) => {
          setRefreshScenariosList(true);
          return result;
        });
      }).catch(() => {
        showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
        return null;
      });
    }
    else if (currentStatus === CampaignsBulkStatusEnum.BEING_EDITED) {
      /* The campaign is being edited by another user or has been left in that status, we check if the latest date update is greater than now() + 15 secs. If true, we let the opening happen */
      const bulkCampaignLastDateModification = await Get<CampaignBulkRaw>({
        name: 'CampaignsBulk',
        id,
        keyName: 'id_campaign_bulk',
        fields: [
          'date_modification'
        ],
      });

      // If the campaign is in the state / local storage, we remove it
      const indexInState = state.bulkCampaigns.findIndex((s) => s.scenario.id === id);
      if (indexInState !== -1) {
        state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);
        saveCampaignsInLocalStorage();
      }

      if (bulkCampaignLastDateModification.item) {
        const now = Date.now();
        const dateCampaign = (new Date(bulkCampaignLastDateModification.item.date_modification)).getTime();
        const seconds = (now - dateCampaign) / 1000;

        if (seconds < 15) {
          await showToastError(translation.global.t('Cette campagne est en cours d\'edition par un autre utilisateur, vous ne pouvez pas l\'editer pour le moment.'));
          hide();
          return null;
        }
      } else {
        showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
        return null;
      }

      // Open campaign and update date_modification
      return await updateCampaignBulk([{
        id_campaign_bulk: id,
        id_shop: UserState.activeShop?.id ?? 0,
        status: CampaignsBulkStatusEnum.BEING_EDITED,
        date_send: null,
        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
      }]).then(async () => {
        return await activateBulkCampaign(id).then((result: Maybe<AutomatedScenario>) => {
          setRefreshScenariosList(true);
          return result;
        });
      }).catch(() => {
        showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
        return null;
      });
    }
    else if (currentStatus === CampaignsBulkStatusEnum.SENT) {
      state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);
      return await activateBulkCampaign(id, true, dateSend).then((result: Maybe<AutomatedScenario>) => {
        setRefreshScenariosList(true);
        return result;
      });
    }
    else {
      /* If the status is neither "draft", nor "scheduled", nor "being_edited".
         there's no reason for the campaign to be in the state. we make sure that is not the case. */
      state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);

      // We save current campaigns in local storage
      saveCampaignsInLocalStorage();

      // Refresh list of scenarios (datatable)
      setRefreshScenariosList(true);

      await showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
      return null;
    }
  } else if (type === TypeCampaignEnum.AUTOMATION) {
    /**
     * We check if the scenario is already in the state.
     * If true, we compare its dateModification to the one in database.
     * If dates are different, we remove the scenario from the local storage and the state
     */
    const indexInState = state.automationCampaigns.findIndex((s) => s.scenario.id === id);

    if (indexInState !== -1) {
      /**
       * Campaign is already in the state, we check the date_modification
       * If date of database equals date of state, we open from the state
       * Otherwise, we remove from the state and open from database (updated by another user)
       */
      const automationCampaignLastDateModification = await Get<CampaignAutomationRaw>({
        name: 'MarketingWorkflows',
        id,
        keyName: 'id_marketing_workflow',
        fields: [
          'date'
        ],
      });

      if (automationCampaignLastDateModification.item === null) {
        // No date returned, so we trigger an error and we remove the campaign from the state and local storage
        state.automationCampaigns = state.automationCampaigns.filter((s) => s.scenario.id !== id);
        saveCampaignsInLocalStorage();
        await showToastError(translation.global.t('errorMessages.GENERIC_ERROR'));
        return null;
      }

      if (automationCampaignLastDateModification.item.date && state.automationCampaigns[indexInState].scenario.dateModification !== automationCampaignLastDateModification.item.date) {
        // Dates are different, we remove the campaign from the state and local storage
        state.automationCampaigns = state.automationCampaigns.filter((s) => s.scenario.id !== id);
        saveCampaignsInLocalStorage();
      }
    }

    return await activateAutomationCampaign(id);
  }

  return null;
}

/*
 * Close the active campaign
 */
export function deactivateCampaign(id: number): void {
  if (state.scenario.type === TypeCampaignEnum.AUTOMATION) {
    state.automationCampaigns = state.automationCampaigns.filter((s) => s.scenario.id !== id);
  } else if (state.scenario.type === TypeCampaignEnum.BULK) {
    state.bulkCampaigns = state.bulkCampaigns.filter((s) => s.scenario.id !== id);
  }

  state.operators = [];
  state.$flowchart = null;
  hide();
}

export enum ToolTab {
  OPERATOR_EDIT = 'OPERATOR_EDIT',
  OPERATOR_ADD = 'OPERATOR_ADD',
  SETTINGS_PANEL = 'SETTINGS_PANEL',
  HISTORY_PANEL = 'HISTORY_PANEL',
}

// set$Flowchart is used when the jquery flowchart object is available
export function set$Flowchart($flowchart: any) {
  state.$flowchart = $flowchart;
}

/**
 * updateToolbar updates the state of the left toolbar tab which is active
 * it is meant to keep the ui tooltab component model value sync with the state value
 * thus changes are by index not ToolTab
 * for named tab switching use showToolTab
 *
 * @param index index of the tab to be activated
 * @see showToolTab
 */
export function updateOperatorSelectorStep(index: number) {
  state.leftToolbar.operatorSelectorStep = index;
}

/**
 * Switch and activate a tab by name
 * @param tab the tab name to show
 */
export function showToolTab(tab: ToolTab) {
  resetLeftToolbar();

  switch (tab) {
    case ToolTab.OPERATOR_ADD:
      state.leftToolbar.show.operatorSelector = true;
      break;
    case ToolTab.OPERATOR_EDIT:
      state.leftToolbar.show.operatorEditForm = true;
      break;
    case ToolTab.HISTORY_PANEL:
      state.leftToolbar.show.historyPanel = true;
      break;
    case ToolTab.SETTINGS_PANEL:
      state.leftToolbar.show.settingsPanel = true;
      break;
    default:
      break;
  }
}

/**
 * Close the active ToolTab
 */
export function hideToolTab() {
  state.leftToolbar.show.operatorEditForm = false;
  state.leftToolbar.show.operatorOutputs = false;
  state.leftToolbar.show.operatorSelector = false;
  state.leftToolbar.show.historyPanel = false;
}


export const checkBackupStatusOfTheCurrentOperatorConfig = () => {
  const selectedOperator = JSON.parse(JSON.stringify(state.selectedOperator));
  if (selectedOperator.operatorId !== '' && selectedOperator.operatorAddMethod === null && selectedOperator.dialog === null) {
    // get original config data
    const originalConfigData = deepToRaw(state.scenario.getOperatorData(selectedOperator.operatorId));
    const currentConfigData = deepToRaw(selectedOperator.currentConfigurationForm);
    if (currentConfigData === null) {
      return true;
    }
    // verifier si originalConfigData contient un element persistanceList
    if (originalConfigData.hasOwnProperty('persistanceList')) {
      delete originalConfigData.persistanceList;
    }
    // verifier si originalConfigData contient un element persistanceList
    if (currentConfigData.hasOwnProperty('persistanceList')) {
      delete currentConfigData.persistanceList;
    }

    // verifier si originalConfigData contient un element persistanceList
    if (originalConfigData.hasOwnProperty('initial_number_outputs')) {
      delete originalConfigData.initial_number_outputs;
    }
    // verifier si originalConfigData contient un element persistanceList
    if (currentConfigData.hasOwnProperty('initial_number_outputs')) {
      delete currentConfigData.initial_number_outputs;
    }
    const originalConfigDataChecksum = calculateChecksum(originalConfigData);
    const currentConfigDataChecksum = calculateChecksum(currentConfigData);
    return originalConfigDataChecksum === currentConfigDataChecksum;
  } else if (state.leftToolbar.show.operatorSelector && state.leftToolbar.operatorSelectorStep === StepsOperatorSelector.CONFIGURE) {
    return false;
  }

  return true;
}

const deepToRaw = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(deepToRaw);
  } else if (typeof obj === 'object' && obj !== null) {
    const rawObj: any = {};
    for (const key in obj) {
      rawObj[key] = deepToRaw(obj[key]);
    }
    return rawObj;
  } else {
    return obj;
  }
}

const calculateChecksum = (obj: any):any => {
  const jsonString = JSON.stringify(obj); // Convertir l'objet en chaîne JSON
  const hash = CryptoJS.SHA256(jsonString); // Calculer le hash SHA-256
  return hash.toString();
}

// selectOperator handles a box being clicked
export function selectOperator(operatorId: string, edit = false, returnMetaOnly = false): any {
  const { scenario } = state;

  const { id } = scenario.getOperatorData<{ id: string }>(operatorId) ?? { id: '' };
  if (id === '') {
    return false;
  }

  // on passe sur les links
  const outputs = Object.keys(scenario.data.links)
    // on prend ceux qui sortent de notre operatorId
    .filter((l) => scenario.data.links[l].fromOperator === operatorId)
    // on trouve les destinataires
    .map((l) => scenario.data.links[l].toOperator)
    // on recupere les objets operator
    .map((l) => scenario.getOperatorBox(l))
    .filter((l) => l !== null) as OperatorBox[];

  const metadata = getComponentMetadataById(id);
  if (!metadata) {
    return false;
  }

  if (returnMetaOnly) {
    return metadata;
  }

  const hasActive = true;

  state.selectedOperator = {
    ...state.selectedOperator,
    operatorId,
    hasActive,
    meta: metadata.Meta,
    outputs,
    dialog: null,
    currentConfigurationForm: null,
  };

  resetLeftToolbar();
  if (edit) {
    state.selectedOperator = {
      ...state.selectedOperator,
      operatorAddMethod: null,
    };
    showToolTab(ToolTab.OPERATOR_EDIT);
  }
  return true;
}

export function onStartButtonClicked() {
  const possibleOutputs = Object.values(Metadata)
    .filter((m) => m.Meta.availableInCampaign.includes(state.scenario.type))
    .reduce((p, c) => {
      const kind: string = c.Meta?.kind ?? '';

      if (kind === 'declencheur') {
        return {
          ...p,
          [c.Meta.kind]: [...p.declencheur, c],
        };
      }
      return {
        ...p,
      };
    }, {
      action: [] as OperatorMeta[],
      declencheur: [] as OperatorMeta[],
      filtre: [] as OperatorMeta[],
    });

  state.selectedOperator = {
    ...state.selectedOperator,
    operatorId: 'root',
    possibleOutputs,
    operatorAddMethod: OperatorAddType.ADD_ROOT,
  };

  showToolTab(ToolTab.OPERATOR_ADD);
}

// onReplaceClicked handles clicking a replace button action
export function onReplaceClicked(operatorId: string) {
  if (!selectOperator(operatorId)) {
    return;
  }

  state.selectedOperator = {
    ...state.selectedOperator,
    operatorAddMethod: OperatorAddType.REPLACE_CURRENT,
  };
  const possibleOutputs = isRootOfTree(operatorId) ? Object.values(Metadata)
    .filter((m) => m.Meta.availableInCampaign.includes(state.scenario.type))
    .reduce((p, c) => {
      const kind: string = c.Meta?.kind ?? '';

      if (kind === 'declencheur') {
        return {
          ...p,
          [c.Meta.kind]: [...p.declencheur, c],
        };
      }
      return {
        ...p,
      };
    }, {
      action: [] as OperatorMeta[],
      declencheur: [] as OperatorMeta[],
      filtre: [] as OperatorMeta[],
    }) : getaAvailableOperators(state.scenario.type,false);

  state.selectedOperator = {
    ...state.selectedOperator,
    possibleOutputs,
  };

  showToolTab(ToolTab.OPERATOR_ADD);
}

// onInputClicked handles clicking a plus button
export function onInputClicked(operatorId: string) {
  if (!selectOperator(operatorId)) {
    return;
  }

  state.selectedOperator = {
    ...state.selectedOperator,
    operatorAddMethod: OperatorAddType.ADD_ABOVE,
  };

  const possibleOutputs = getaAvailableOperators(state.scenario.type,true);

  state.selectedOperator = {
    ...state.selectedOperator,
    possibleOutputs,
  };

  showToolTab(ToolTab.OPERATOR_ADD);
}

export function mergeConnector(operatorId: string, type: string, connector: OperatorInput|OperatorOutput) {
  if (type === 'input') {
    if (state.input[operatorId].connectorCanBeMerged) {
      state.input[operatorId] = {
        ...state.input[operatorId],
        connectorCanBeMerged: false,
        connectorSelectedForMerge: true,
      };

      state.selectedOperator.mergeBranchData = {
        fromConnector: state.selectedOperator.mergeBranchData?.fromConnector ?? '',
        fromOperator: state.selectedOperator.mergeBranchData?.fromOperator ?? '',
        fromSubConnector: 0,
        toOperator: operatorId,
        toConnector: 'input_1',
        toSubConnector: 0,
      };
    } else if (state.input[operatorId].connectorSelectedForMerge) {
      state.input[operatorId] = {
        ...state.input[operatorId],
        connectorCanBeMerged: true,
        connectorSelectedForMerge: false,
      };

      state.selectedOperator.mergeBranchData = {
        fromConnector: state.selectedOperator.mergeBranchData?.fromConnector ?? '',
        fromOperator: state.selectedOperator.mergeBranchData?.fromOperator ?? '',
        fromSubConnector: 0,
        toOperator: '',
        toConnector: '',
        toSubConnector: 0,
      };
    }

    Object.keys(state.input).forEach((key) => {
      if (key !== operatorId && (state.input[key].connectorCanBeMerged || state.input[key].connectorSelectedForMerge)) {
        state.input[key] = {
          ...state.input[operatorId],
          connectorCanBeMerged: true,
          connectorSelectedForMerge: false,
        };
      }
    });
  } else if (type === 'output') {
    if ('index' in connector) {
      const indexOutput = connector.index - 1;
      if (state.nbOutputs[operatorId][indexOutput].connectorCanBeMerged) {
        const closureBranchData: BranchClosureData = {
          operatorId,
          output: `output_${connector.index}` ?? 'output_1',
        };

        if (state.selectedOperator.closureBranchData) {
          // If it is a multi-output box, the other connectors are deselected if they are selected.
          if (boxHasMultipleOutputs(operatorId)) {
            state.nbOutputs[operatorId] = state.nbOutputs[operatorId].map((output) => ({
              ...output,
              connectorCanBeMerged: true,
              connectorSelectedForMerge: false,
            }));
            state.selectedOperator.closureBranchData = toRaw(state.selectedOperator.closureBranchData).filter(
              (item) => (item.operatorId !== operatorId),
            );
          }

          state.selectedOperator.closureBranchData.push(closureBranchData);
        }

        state.nbOutputs[operatorId][indexOutput] = {
          ...state.nbOutputs[operatorId][indexOutput],
          connectorCanBeMerged: false,
          connectorSelectedForMerge: true,
        };
      } else if (state.nbOutputs[operatorId][indexOutput].connectorSelectedForMerge) {
        if (state.selectedOperator.closureBranchData) {
          state.selectedOperator.closureBranchData = toRaw(state.selectedOperator.closureBranchData).filter(
            (item) => !(item.operatorId === operatorId && item.output === `output_${connector.index}`),
          );
        }

        state.nbOutputs[operatorId][indexOutput] = {
          ...state.nbOutputs[operatorId][indexOutput],
          connectorCanBeMerged: true,
          connectorSelectedForMerge: false,
        };
      }
    }
  }
}

// flowchartTick updates the tick value of the flowchart to force re-render
export function flowchartTick() {
  state.flowchartTick = (parseInt(state.flowchartTick, 16) + 1).toString(16);
}

function getNextName(names: string[], prefix: string): string {
  const next = names.map((n) => asInt(n.substring(prefix.length)))
    .reduce((p, c) => (c > p ? c : p), 0) + 1;
  return `${prefix}${next + 1}`;
}

function createNewLink(newLink: AutomatedScenarioLink) {
  const newLinkName = getNextName(
    Object.keys(state.scenario.data.links),
    'created_link_',
  );

  state.scenario.data.links[newLinkName] = newLink;
}

// onInputClicked handles clicking a plus button
export function onActionAddOperatorBtnClicked(operatorId: string, type: ActionsDialogAddOperatorType) {
  if (type === ActionsDialogAddOperatorType.ADD_OPERATOR) {

    const possibleOutputs = getaAvailableOperators(state.scenario.type,false);

    state.selectedOperator = {
      ...state.selectedOperator,
      possibleOutputs,
      dialog: null,
    };

    if (state.selectedOperator.closureBranchData !== null) {
      state.selectedOperator = {
        ...state.selectedOperator,
        dialog: {
          type: BoxDialogType.CONFIRMATION_BRANCH_CLOSURE,
          error: false,
        },
      };

      Object.keys(state.scenario.data.operators).forEach((key) => {
        state.nbOutputs[key] = state.nbOutputs[key].map((output) => {
          if (output.connectorSelectedForMerge) {
            return {
              ...output,
              connectorToMerge: true,
              connectorCanBeMerged: false,
              connectorSelectedForMerge: false,
            };
          }
          return {
            ...output,
            connectorCanBeMerged: false,
            connectorSelectedForMerge: false,
          };
        });
      });
    }

    showToolTab(ToolTab.OPERATOR_ADD);
  } else if (type === ActionsDialogAddOperatorType.BRANCH_MERGE) {
    const newLink: AutomatedScenarioLink = {
      fromOperator: state.selectedOperator.operatorId,
      fromConnector: `output_${state.selectedOperator.outputSelected}` ?? 'output_1',
      fromSubConnector: 0,
      toOperator: '',
      toConnector: '',
      toSubConnector: 0,
    };

    state.selectedOperator = {
      ...state.selectedOperator,
      dialog: {
        type: BoxDialogType.ACTIONS_BRANCH_MERGE,
        error: false,
      },
      closureBranchData: null,
      mergeBranchData: newLink,
    };

    const parents = getAllParentsOfBoxToRoot(operatorId);
    Object.keys(state.scenario.data.operators).forEach((key) => {
      if (key === operatorId) {
        let indexOutputSelected = 0;
        if (state.selectedOperator.outputSelected) {
          indexOutputSelected = parseInt(state.selectedOperator.outputSelected, 10) - 1;
        }
        state.nbOutputs[key][indexOutputSelected] = {
          ...state.nbOutputs[key][indexOutputSelected],
          connectorToMerge: true,
        };
      } else if (parents.indexOf(key) === -1) {
        state.input[key] = {
          visible: false,
          connectorCanBeMerged: true,
          connectorSelectedForMerge: false,
        };
      }
    });

    state.operators = state.operators.map((op) => ({
      ...op,
      disabled: true,
    }));
  } else if (type === ActionsDialogAddOperatorType.BRANCH_CLOSURE) {
    const closureBranchData: BranchClosureData = {
      operatorId,
      output: `output_${state.selectedOperator.outputSelected}` ?? 'output_1',
    };

    state.selectedOperator = {
      ...state.selectedOperator,
      closureBranchData: [closureBranchData],
      mergeBranchData: null,
      dialog: {
        type: BoxDialogType.ACTIONS_BRANCH_CLOSURE,
        error: false,
      },
    };

    Object.keys(state.scenario.data.operators).forEach((key) => {
      if (key === operatorId) {
        let indexOutputSelected = 0;
        if (state.selectedOperator.outputSelected) {
          indexOutputSelected = parseInt(state.selectedOperator.outputSelected, 10) - 1;
        }
        state.nbOutputs[key][indexOutputSelected] = {
          ...state.nbOutputs[key][indexOutputSelected],
          connectorToMerge: true,
        };
      } else {
        if (boxHasMultipleOutputs(key) && state.scenario.data.operators[key].properties.class !== 'filtre') {
          return false;
        }
        Object.keys(state.nbOutputs[key]).forEach((indexOutput) => {
          const output = `output_${parseInt(indexOutput, 10) + 1}`;
          if (getOperatorChildByOutput(key, output) === null) {
            state.nbOutputs[key][parseInt(indexOutput, 10)] = {
              ...state.nbOutputs[key][parseInt(indexOutput, 10)],
              connectorToMerge: false,
              connectorSelectedForMerge: false,
              connectorCanBeMerged: true,
            };
          }
        });
      }
      return true;
    });

    state.operators = state.operators.map((op) => ({
      ...op,
      disabled: true,
    }));
  }
}

export function onActionBranchMergeBtnClicked(operatorId: string, type: ActionsDialogBranchMergeType) {
  let resetOperators = true;
  if (type === ActionsDialogBranchMergeType.VALIDATE) {
    if (state.selectedOperator.mergeBranchData && state.selectedOperator.mergeBranchData.fromOperator !== ''
      && state.selectedOperator.mergeBranchData.toOperator !== '') {
      createNewLink(state.selectedOperator.mergeBranchData);
      preprocessData(state.scenario.data);
      state.$flowchart.flowchart('setData', state.scenario.data);
      coreDrawUI();
      flowchartTick();
      selectOperator(operatorId);
      state.scenario.operatorIdToCenter = operatorId;
    } else {
      resetOperators = false;
      state.selectedOperator.dialog = {
        ...state.selectedOperator.dialog,
        error: true,
      };
    }
  }

  if (resetOperators) {
    resetSelectedOperator();
    resetDefaultDisplayUrl();
    state.operators = state.operators.map((op) => ({
      ...op,
      disabled: false,
    }));
    Object.keys(state.scenario.data.operators).forEach((key) => {
      state.nbOutputs[key] = state.nbOutputs[key].map((output) => ({
        ...output,
        connectorToMerge: false,
      }));
      state.input[key] = {
        visible: true,
        connectorCanBeMerged: false,
        connectorSelectedForMerge: false,
      };
    });
  }
}

export function onActionBranchClosureBtnClicked(operatorId: string, type: ActionsDialogBranchClosureType) {
  let resetOperators = false;

  if (type === ActionsDialogBranchClosureType.VALIDATE) {
    if (state.selectedOperator.closureBranchData && state.selectedOperator.closureBranchData?.length > 1) {
      onActionAddOperatorBtnClicked(operatorId, ActionsDialogAddOperatorType.ADD_OPERATOR);
      state.scenario.operatorIdToCenter = operatorId;
    } else {
      state.selectedOperator.dialog = {
        ...state.selectedOperator.dialog,
        error: true,
      };
    }
  } else if (type === ActionsDialogBranchClosureType.CANCEL) {
    resetOperators = true;
  }

  if (resetOperators) {
    resetSelectedOperator();
    resetDefaultDisplayUrl();
    resetLeftToolbar();
    state.operators = state.operators.map((op) => ({
      ...op,
      disabled: false,
    }));
    Object.keys(state.scenario.data.operators).forEach((key) => {
      state.nbOutputs[key] = state.nbOutputs[key].map((output) => ({
        ...output,
        connectorToMerge: false,
        connectorCanBeMerged: false,
        connectorSelectedForMerge: false,
      }));
    });
  }
}

// onOutputClicked handles clicking a plus button
export function onOutputClicked(operatorId: string, outputId: string) {
  if (!selectOperator(operatorId)) {
    return;
  }

  state.selectedOperator = {
    ...state.selectedOperator,
    operatorAddMethod: OperatorAddType.ADD_BELOW,
    outputSelected: outputId,
  };

  let nbConditionedBoxes = 0;

  Object.keys(state.scenario.data.operators).forEach((key) => {
    if ((!boxHasMultipleOutputs(key) || state.scenario.data.operators[key].properties.class === 'filtre')
      && !checkIfAllOutputsAreLinked(key)
      && boxHasParentWithMultiOutputs(key)) {
      nbConditionedBoxes += 1;
    }
  });

  if (nbConditionedBoxes > 1
    && (
      (!boxHasMultipleOutputs(operatorId) && boxHasParentWithMultiOutputs(operatorId))
      || state.scenario.data.operators[operatorId].properties.class === 'filtre'
    )
    && !checkIfAllOutputsAreLinked(operatorId)) {
    state.selectedOperator = {
      ...state.selectedOperator,
      dialog: {
        type: BoxDialogType.ACTIONS_ADD_OPERATOR,
        error: false,
      },
    };
  } else {
    onActionAddOperatorBtnClicked(operatorId, ActionsDialogAddOperatorType.ADD_OPERATOR);
  }
}

function getOperatorsAndLinksNotInOperatorOutput(operatorId: string, notInOutputs: any , operatorsToKeep: string[], linksToKeep: string[], checkOutputs: boolean) {
  const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;

  Object.keys(operatorOutputs).forEach((output) => {
    if((checkOutputs && !notInOutputs.includes(output)) || !checkOutputs) {
      const childOperatorLink = getOperatorAndLinkChildByOutput(operatorId, output);

      if (childOperatorLink !== null) {
        if (!operatorsToKeep.includes(childOperatorLink.operator)) {
          operatorsToKeep.push(childOperatorLink.operator);

        }
        if (!linksToKeep.includes(childOperatorLink.link)) {
          linksToKeep.push(childOperatorLink.link);
        }
        getOperatorsAndLinksNotInOperatorOutput(childOperatorLink.operator, notInOutputs, operatorsToKeep, linksToKeep, false);
      }
    }
  });
}

function getChildrenAndLinksFromOperatorOutput(operatorId: string, inOutputs: any , operatorsToDelete: string[], linksToDelete: string[], checkOutputs: boolean) {
  const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;

  Object.keys(operatorOutputs).forEach((output) => {
    if((checkOutputs && inOutputs.includes(output)) || !checkOutputs) {

      const childOperatorLink = getOperatorAndLinkChildByOutput(operatorId, output);
      if (childOperatorLink !== null) {
        if (!operatorsToDelete.includes(childOperatorLink.operator)) {
          operatorsToDelete.push(childOperatorLink.operator);
        }
        if (!linksToDelete.includes(childOperatorLink.link)) {
          linksToDelete.push(childOperatorLink.link);
        }
        getChildrenAndLinksFromOperatorOutput(childOperatorLink.operator, inOutputs, operatorsToDelete, linksToDelete, false);
      }
    }
  });
}

function getChildrenAndLinksFromOperator(operatorId: string, operatorsToDelete: string[], linksToDelete: string[]) {
  const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;

  Object.keys(operatorOutputs).forEach((output) => {
    const childOperator = getOperatorChildByOutput(operatorId, output);
    if (childOperator !== null) {
      if (!operatorsToDelete.includes(childOperator)) {
        operatorsToDelete.push(childOperator);
      }
      Object.keys(state.scenario.data.links).forEach((l) => {
        const {
          fromOperator, toOperator,
        } = state.scenario.data.links[l];
        if ((toOperator === childOperator || fromOperator === childOperator) && !linksToDelete.includes(l)) {
          linksToDelete.push(l);
        }
      });
      getChildrenAndLinksFromOperator(childOperator, operatorsToDelete, linksToDelete);
    }
  });
}

// setOperatorData updates custom data (from edit forms) for a box on the flowchart
export function setOperatorData<T>(operator: string, datum: T): void {
  if (state.scenario === null) {
    return;
  }

  const data = {
    ...state.scenario.data,
    operators: {
      ...state.scenario.data.operators,
      [operator]: {
        ...state.scenario.data.operators[operator] ?? {},
        custom: datum,
      },
    },
  };

  // equivalent to the following but with the safety of whether or not it exists
  // state.scenario.data.operators[operator_id].custom = datum

  preprocessData(data);
}

const computePersistence = (meta: Meta, componentData: ComponentData<any>, persistenceList: PersistenceList): PersistenceList => (meta.persistent ?? [])
  .reduce((acc: PersistenceList, current: string|BoxPersistence) => {
    if (typeof current === 'string') { // Simple persistence config, active on entire box
      return {
        ...acc,
        [componentData.id]: [componentData.id],
      };
    }
    // Complex persistence config, active on only box segment
    if (Object.keys(componentData.inclusion ?? []).includes(current.segmentId) || Object.keys(componentData.exclusion ?? []).includes(current.segmentId)) {
      return {
        ...acc,
        [componentData.id]: [...(acc[componentData.id] ?? []), current.segmentId],
      };
    }
    return acc;
  }, persistenceList);

const doesPersistenceEntryHaveToBeRemoved = (key: string, value: string, id: string): boolean => (getComponentMetadataById(key)?.Meta.persistent ?? [])
  .reduce((acc: boolean, current: string|BoxPersistence) => {
    if (!acc) {
      return (typeof current === 'string') ? (current === id) : (current.segmentId === value && current.boxId === id);
    }
    return acc;
  }, false);

export const setPersistence = (operatorId: string) => {
  const componentData = state.scenario.getOperatorData<any>(operatorId) ?? null;
  const currentBoxMeta = getComponentMetadataById(componentData.id)?.Meta;
  if (currentBoxMeta) {
    // Initialize persistence with parents list
    let currentPersistanceList: PersistenceList = getOperatorParents(operatorId)
      .map((parent: string) => state.scenario.getOperatorData<any>(parent).persistanceList)
      .filter((persistenceList: PersistenceList) => persistenceList !== undefined)
      .reduce((acc: PersistenceList, current: PersistenceList) => {
        Object.keys(current).forEach((key: string) => { acc[key] = [...current[key]]; });
        return acc;
      }, {});

    if (currentBoxMeta?.persistent) { // If current box has persistent config, add it to list
      currentPersistanceList = {
        ...computePersistence(currentBoxMeta, componentData, currentPersistanceList),
      };
    }
    // Eventually remove persistence if breaking box found
    const sanitizedPersistenceList = Object.entries(currentPersistanceList)
      .map((entry: any) => {
        // eslint-disable-next-line no-param-reassign
        entry[1] = entry[1].filter((value: string) => !doesPersistenceEntryHaveToBeRemoved(entry[0], value, currentBoxMeta.id));
        return entry;
      })
      .filter((entry: any) => entry[1].length > 0)
      .reduce((acc: PersistenceList, entry: any) => ({ ...acc, [entry[0]]: entry[1] }), {});

    if (Object.keys(sanitizedPersistenceList).length > 0) {
      setOperatorData(operatorId, {
        ...componentData,
        persistanceList: { ...sanitizedPersistenceList },
      });
    } else {
      // Ensure persistenceList attribute is not set
      delete componentData.persistanceList;
      setOperatorData(operatorId, componentData);
    }

    // Refresh children persistence
    Object.keys(state.scenario.data.operators[operatorId].properties.outputs)
      .map((output) => getOperatorChildByOutput(operatorId, output))
      .forEach((childId) => childId && setPersistence(childId));
  }
};

function createNewOperator(component: string, opdata: any): string|boolean {
  if (!state.$flowchart) {
    return false;
  }
  /* Créer l'operateur */
  const metadata = getComponentMetadata(component);
  if (!metadata) {
    return false;
  }

  const properties = {
    title: metadata.Meta.label,
    class: metadata.Meta.kind,
    inputs: {
      input_1: { label: 'Input 1' },
    },
    outputs: {}, // todo
  };

  const custom = metadata.Create(opdata);
  const top = 0;
  const left = 0;
  const newOperator: AutomatedScenarioOperator = {
    top, left, properties, custom,
  };

  const newOperatorName = getNextName(
    Object.keys(state.scenario.data.operators),
    'created_operator_',
  );

  state.scenario.data.operators[newOperatorName] = newOperator;

  return newOperatorName;
}

// Create new history point for the active scenario
export async function addHistory(type: string, labelTranslationKey: string): Promise<void> {
  /*
   * Types :
   * check_point
   * add_box
   * update_box
   * replace_box
   * remove_box
   * switch_outpus
   */
  // We check if the current scenario has a history or not
  const scenarioData = state.$flowchart.flowchart('getData');

  if (state.scenario.history == null) {
    // No history
    // We create the first history point
    state.scenario.history = {
      history: JSON.stringify([{
        type,
        label: labelTranslationKey,
        history: scenarioData,
      }]),
      dateCreation: moment().format('YYYY-MM-DD HH:mm:ss'),
    } as AutomatedScenarioHistory;
  } else {
    // Has history
    const currentHistory = state.scenario.history &&
      Object.prototype.hasOwnProperty.call(state.scenario.history, 'history') &&
      state.scenario.history.history &&
      state.scenario.history.history !== ''
        ? JSON.parse(state.scenario.history.history)
        : [];

    // We push a new history point into the array
    currentHistory.push({
      type,
      label: labelTranslationKey,
      history: scenarioData,
    });

    state.scenario.history.history = JSON.stringify(currentHistory);
  }

  // Set the current history index of the history points' array
  state.scenario.currentHistoryIndex = 0;

  // Increment the number of history points
  if (state.scenario.maxHistoryIndex < (maxSavedHistoryPoints - 1)) {
    // Max {maxSavedHistoryPoints} history points, starting from 0
    state.scenario.maxHistoryIndex += 1;
  }

  // We save the current history in the database
  if (state.scenario.historyId === null) {
    // Insert a new history
    await insertCampaignHistory(
      state.scenario.type,
      UserState?.activeShop?.id ?? 0,
      state.scenario.id,
      state.scenario.history).then((result) => {
      state.scenario.historyId = result;
    }).catch((err: Error) => {
      showToastError(err.message);
    });
  } else {
    // Update the current history
    await updateCampaignHistory(
      state.scenario.type,
      UserState?.activeShop?.id ?? 0,
      state.scenario.historyId,
      state.scenario.id,
      state.scenario.history
    ).catch((err: Error) => {
      showToastError(err.message);
    });
  }
}

// addOperator handles submitting data from the component selector
export function addOperator(component: string, opdata: any) {
  const newOperatorName = createNewOperator(component, opdata);
  if (typeof newOperatorName !== 'string') {
    return;
  }
  let operatorNameToReplace = '';

  // TODO : REPLACE_CURRENT
  // If we are adding a new operator above the selected one or replacing it
  if (state.selectedOperator.operatorAddMethod && [OperatorAddType.ADD_ABOVE, OperatorAddType.REPLACE_CURRENT].includes(state.selectedOperator.operatorAddMethod)) {
    let replaceRootOperator = false;
    if (state.selectedOperator.operatorAddMethod === OperatorAddType.REPLACE_CURRENT) {
      operatorNameToReplace = state.selectedOperator.operatorId;
      replaceRootOperator = isRootOfTree(operatorNameToReplace);
    }

    if (replaceRootOperator) {
      const childrenOperators = getOperatorChildren(state.selectedOperator.operatorId);
      childrenOperators.forEach((childOperatorId) => {
        const fromConnector = 'output_1';
        const fromOperator = newOperatorName;
        const fromSubConnector = 0;
        const toConnector = 'input_1';
        const toOperator = childOperatorId;
        const toSubConnector = 0;
        const newUpLink: AutomatedScenarioLink = {
          fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
        };
        createNewLink(newUpLink);
      });
    } else {
      const parentsOperators = getOperatorParents(state.selectedOperator.operatorId);
      parentsOperators.forEach((parentOperatorId) => {
        let fromConnector = 'output_1';
        const fromOperator = parentOperatorId;
        const fromSubConnector = 0;
        const toConnector = 'input_1';
        const toOperator = newOperatorName;
        const toSubConnector = 0;
        Object.keys(state.scenario.data.links).forEach((l) => {
          if (state.scenario.data.links[l].fromOperator === parentOperatorId
            && state.scenario.data.links[l].toOperator === state.selectedOperator.operatorId) {
            fromConnector = state.scenario.data.links[l].fromConnector;
            delete state.scenario.data.links[l];
          }
        });

        const newUpLink: AutomatedScenarioLink = {
          fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
        };
        createNewLink(newUpLink);
      });
      const fromOperator = newOperatorName;
      const fromConnector = 'output_1';
      const fromSubConnector = 0;
      const toOperator = state.selectedOperator.operatorId;
      const toConnector = 'input_1';
      const toSubConnector = 0;

      const newDownLink: AutomatedScenarioLink = {
        fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
      };

      createNewLink(newDownLink);
    }
  }
  else if (state.selectedOperator.operatorAddMethod === OperatorAddType.ADD_BELOW) {
    if (state.selectedOperator.closureBranchData && state.selectedOperator.closureBranchData?.length > 1) {
      state.selectedOperator.closureBranchData.forEach((operatorToMerge) => {
        const fromOperator = operatorToMerge.operatorId;
        const fromConnector = operatorToMerge.output;
        const fromSubConnector = 0;
        const toOperator = newOperatorName;
        const toConnector = 'input_1';
        const toSubConnector = 0;

        const newLink: AutomatedScenarioLink = {
          fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
        };

        createNewLink(newLink);
        state.selectedOperator = {
          ...state.selectedOperator,
          dialog: null,
          closureBranchData: null,
          mergeBranchData: null,
        };
      });
    } else {
      const fromOperator = state.selectedOperator.operatorId;
      const fromConnector = `output_${state.selectedOperator.outputSelected ?? 1}`;
      const fromSubConnector = 0;
      const toOperator = newOperatorName;
      const toConnector = 'input_1';
      const toSubConnector = 0;

      const newLink: AutomatedScenarioLink = {
        fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
      };

      createNewLink(newLink);
    }
  }

  preprocessData(state.scenario.data);
  state.$flowchart.flowchart('setData', state.scenario.data);
  coreDrawUI();
  flowchartTick();
  selectOperator(newOperatorName);
  state.scenario.operatorIdToCenter = newOperatorName;
  const dataOp = state.scenario.data.operators[newOperatorName];

  if (state.selectedOperator.operatorAddMethod !== OperatorAddType.REPLACE_CURRENT) {
    // Si oui, on sauvegarde l'historique
    // Sinon, on sauvegarde l'historique apres la suppression de l'operateur à remplacer
    addHistory('add_box', `${dataOp.properties.title}`);
  }

  if (operatorNameToReplace !== '') {
    removeOperator(operatorNameToReplace);
  }
}

export function removeOperator(operatorId: string) {
  const parentOperators: { fromOperator: string; fromConnector: string }[] = [];
  const childOperators: { toOperator: string; toConnector: string }[] = [];
  const operatorsToDelete: string[] = [];
  const linksToDelete: string[] = [];
  const labelOpToDelete = state.scenario.data.operators[operatorId].properties.title;
  operatorsToDelete.push(operatorId);
  Object.keys(state.scenario.data.links).forEach((l) => {
    const {
      fromOperator, fromConnector, toOperator, toConnector,
    } = state.scenario.data.links[l];
    if (toOperator === operatorId) {
      parentOperators.push({
        fromOperator, fromConnector,
      });
      linksToDelete.push(l);
    } else if (fromOperator === operatorId) {
      childOperators.push({
        toOperator, toConnector,
      });
      linksToDelete.push(l);
    }
  });

  if (childOperators.length > 1) {
    const operatorOutputs = state.scenario.data.operators[operatorId].properties.outputs;
    let branchKept = true;
    // On boucle sur les branches de l'opérateur à supprimer
    Object.keys(operatorOutputs).forEach((output) => {
      const childOperator = getOperatorChildByOutput(operatorId, output);

      if (childOperator !== null) {
        if (branchKept) {
          // Lier la première branche au premier parent de la boite à supprimer
          const newLinkName = getNextName(
            Object.keys(state.scenario.data.links),
            'created_link_',
          );
          const { fromOperator, fromConnector } = parentOperators[0];
          const toOperator = childOperator;
          const toConnector = 'input_1';
          const fromSubConnector = 0;
          const toSubConnector = 0;

          state.scenario.data.links[newLinkName] = {
            fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
          };
          branchKept = false;
        } else {
          // Traiter les autres branches
          // Récupérer l'ensemble des parents de l'opérateur enfant
          const childOperatorParents = getOperatorParents(childOperator);
          // Si cette branche est liée à plus d'un parent, on la supprime pas
          if (childOperatorParents.length <= 1) {
            // supprimer toute la branche
            operatorsToDelete.push(childOperator);
            // Récupérer l'ensemble des opérateurs enfants et des liens de la branche à supprimer
            getChildrenAndLinksFromOperator(childOperator, operatorsToDelete, linksToDelete);
          }
        }
      }
    });
  } else if (childOperators.length === 1) {
    // Lié tous les parents à l'enfant de l'opérateur supprimé
    parentOperators.forEach((operatorData) => {
      const newLinkName = getNextName(
        Object.keys(state.scenario.data.links),
        'created_link_',
      );
      const { fromOperator, fromConnector } = operatorData;
      const { toConnector, toOperator } = childOperators[0];
      const fromSubConnector = 0;
      const toSubConnector = 0;

      const newLink: AutomatedScenarioLink = {
        fromOperator, toOperator, toConnector, fromConnector, fromSubConnector, toSubConnector,
      };
      state.scenario.data.links[newLinkName] = newLink;
    });
  }

  operatorsToDelete.forEach((o) => {
    delete state.scenario.data.operators[o];
  });
  linksToDelete.forEach((l) => {
    delete state.scenario.data.links[l];
  });
  if (childOperators.length > 0) {
    // Refresh child persistence
    setPersistence(childOperators[0].toOperator);
  }

  preprocessData(state.scenario.data);
  refreshFlowchart();

  if (state.selectedOperator.operatorAddMethod !== OperatorAddType.REPLACE_CURRENT) {
    addHistory('remove_box', `${labelOpToDelete}`);
  } else {
    addHistory('replace_box', `${labelOpToDelete}`);
    resetSelectedOperator();
    resetDefaultDisplayUrl();
  }

}

export function onActionRemoveOperatorBtnClicked(operatorId: string, type: ActionsDialogRemoveOperator) {
  if (type === ActionsDialogRemoveOperator.CANCEL) {
    resetSelectedOperator();
    resetDefaultDisplayUrl();
    return;
  }

  const { id } = state.scenario.getOperatorData<{ id: string }>(operatorId) ?? { id: '' };
  if (id === '') {
    return;
  }

  const metadata = getComponentMetadataById(id);
  if (!metadata) {
    return;
  }

  const meta = getComponentMetadata(metadata.Meta.component);
  if (!meta) {
    return;
  }

  if (meta.CanBeDeleted) {
    const canDeleted = meta.CanBeDeleted(operatorId);
    if (typeof canDeleted === 'string') {
      if (!selectOperator(operatorId)) {
        return;
      }
      state.selectedOperator = {
        ...state.selectedOperator,
        dialog: {
          type: BoxDialogType.INFO_RIGHT,
          error: false,
          message: canDeleted,
        },
      };
      return;
    }
  }

  if (type === ActionsDialogRemoveOperator.OPEN_DIALOG) {
    if (!selectOperator(operatorId)) {
      return;
    }
    state.selectedOperator = {
      ...state.selectedOperator,
      dialog: {
        type: BoxDialogType.ACTIONS_DELETE_OPERATOR,
        error: false,
      },
    };
  } else if (type === ActionsDialogRemoveOperator.VALIDATE) {
    removeOperator(operatorId);
    resetSelectedOperator();
    resetDefaultDisplayUrl();
  } else {
    resetSelectedOperator();
    resetDefaultDisplayUrl();
  }
}

export function decreaseNumberOfBranchesOfAnOperator(outputsToDelete: string[]) {
  const { operatorId } = state.selectedOperator;
  const operatorsToKeep: string[] = [];
  const linksToKeep: string[] = [];
  const operatorsToDelete: string[] = [];
  const linksToDelete: string[] = [];
  getOperatorsAndLinksNotInOperatorOutput(operatorId,outputsToDelete,operatorsToKeep,linksToKeep,true);
  getChildrenAndLinksFromOperatorOutput(operatorId, outputsToDelete, operatorsToDelete, linksToDelete, true);
  operatorsToDelete.forEach((o) => {
    if (!operatorsToKeep.includes(o)) {
      delete state.scenario.data.operators[o];
    }
  });
  linksToDelete.forEach((l) => {
    if (!linksToKeep.includes(l)) {
      delete state.scenario.data.links[l];
    }
  });

  preprocessData(state.scenario.data);
  // Update scenario links connector
  Object.keys(state.scenario.data.links).forEach((key) => {
    if (state.scenario.data.links[key].fromOperator === operatorId) {
      outputsToDelete.forEach((outputToDelete) => {
        const { fromConnector } = state.scenario.data.links[key];
        const fromConnectorId = asInt(fromConnector.replace('output_', ''));
        const outputToDeleteId = asInt(outputToDelete.replace('output_', ''));
        if (fromConnectorId > outputToDeleteId) {
          state.scenario.data.links[key].fromConnector = `output_${fromConnectorId - 1}`;
        }
      });
    }
  });

  state.$flowchart.flowchart('setData', state.scenario.data);
  coreDrawUI();
  flowchartTick();


}

// unselectOperator removes grayscale from all operators
export function unhighlightOperators() {
  state.operators = state.operators.map((op) => ({
    ...op,
    grayscaled: false,
  }));
}

// highlightOperator highlights a given operatorId and its ancestr by grayscaling others
export function highlightOperator(operatorId: string) {
  const parentMap = getOperatorLineage(operatorId).reduce((p, c) => ({
    ...p,
    [c]: true,
  }), {});

  state.operators = state.operators.map((op) => ({
    ...op,
    grayscaled: !Object.prototype.hasOwnProperty.call(parentMap, op.operatorId),
  }));
}

// onExchangeClicked handles clicking an exchange button
export function onExchangeClicked(operatorId: string, index: string) {
  const output_1 = `output_${index}`;
  const output_2 = `output_${index + 1}`;

  Object.keys(state.scenario.data.links).forEach((l) => {
    const {
      fromOperator, fromConnector,
    } = state.scenario.data.links[l];
    if (fromOperator === operatorId && fromConnector === output_1) {
      state.scenario.data.links[l].fromConnector = output_2;
    } else if (fromOperator === operatorId && fromConnector === output_2) {
      state.scenario.data.links[l].fromConnector = output_1;
    }
  });

  preprocessData(state.scenario.data);
  state.$flowchart.flowchart('setData', state.scenario.data);
  coreDrawUI();
  flowchartTick();
  const dataOp = state.scenario.data.operators[operatorId];
  addHistory('add_box', `${dataOp.properties.title}`);
}

export const linkOperator = (operatorId: string) => state.linkedOperators.push(operatorId);

export const unlinkOperator = (operatorId: string) => {
  const index = state.linkedOperators.indexOf(operatorId);
  if (index !== -1) {
    state.linkedOperators.splice(index, 1);
  }
};

export const resetlinkedOperators = () => {
  state.linkedOperators.length = 0;
};

// Store the active history index
export function setActiveHistory(historyIndex: number): void {
  state.scenario.currentHistoryIndex = historyIndex;

  const workflowHistory = state.scenario.history && Object.getOwnPropertyDescriptor(state.scenario.history, 'history') ? JSON.parse(state.scenario.history.history).reverse() : [];

  if (
    workflowHistory.length > 0 && historyIndex in workflowHistory
    && !!Object.getOwnPropertyDescriptor(workflowHistory[historyIndex], 'history')
    && !!Object.getOwnPropertyDescriptor(workflowHistory[historyIndex].history, 'operators')
  ) {
    preprocessData(workflowHistory[historyIndex].history);
    state.$flowchart.flowchart('setData', state.scenario.data);
    coreDrawUI();
    flowchartTick();
  }
}

export function setScenarioCurrentZoom(newZoom: number) {
  state.scenario.currentZoom = newZoom;
}

export function setGlobalSettingsData(data: GlobalSettingsData | GlobalBulkSettingsData) {
  state.scenario.settingsData = data;
}

export function setDisableOperator(operatorId: string, disable: boolean) {
  const index = state.operators.findIndex((object) => object.operatorId === operatorId);

  if (index !== -1) {
    state.operators[index].disabled = disable;
  }
}

export function setSelectableOperator(operatorId: string, selectable: boolean) {
  const index = state.operators.findIndex((object) => object.operatorId === operatorId);

  if (index !== -1) {
    state.operators[index].selectable = selectable;
  }
}

export function getLinkedOperators(operatorId: string): string[] {
  const childrenOperators: string[] = [];

  const typeBoxToVerify = ['openemail', 'filledoutform', 'clicklink'];
  const boxLinkedToThisOperator: string[] = [];

  getChildrenAndLinksFromOperator(operatorId, childrenOperators, []);
  childrenOperators.forEach((op) => {
    const { id, custom_title } = state.scenario.getOperatorData<{ id: string; custom_title: string }>(op) ?? { id: '', custom_title: '' };
    if (id === '') {
      return;
    }
    if (typeBoxToVerify.includes(id)) {
      const linkedBox = state.scenario.data.operators[op].custom.linked_box ?? false;
      let linkedBoxArray = [];
      if (linkedBox) {
        try {
          linkedBoxArray = JSON.parse(linkedBox);
        } catch (e) {
          // not a json string
          linkedBoxArray = [linkedBox];
        }

        if (linkedBoxArray.length && linkedBoxArray.includes(operatorId)) {
          boxLinkedToThisOperator.push(custom_title);
        }
      }
    }
  });
  return boxLinkedToThisOperator;
}

export function setRefreshScenariosList(refreshList: boolean) {
  state.refreshScenariosList = refreshList;
}

export function setEditingTemplateFromCampaign(data: EditingTemplate|null) {
  state.selectedOperator.editingTemplate = data;
}

export function getEditingTemplateFromCampaign(): EditingTemplate|null {
  return state.selectedOperator.editingTemplate;
}

export const setCampaignTemplateInformationFromBuilder = (data: any) => {
  const dataToSet: Record<string, any> = {};

  // We change operator settings according to data sent by template builder
  if (Object.prototype.hasOwnProperty.call(data, 'subject') && Object.keys(data.subject).length > 0) {
    dataToSet.template_message_subject = { ...data.subject };
  }

  if (Object.prototype.hasOwnProperty.call(data, 'fromEmail') && Object.keys(data.fromEmail).length > 0) {
    dataToSet.template_message_expmail = { ...data.fromEmail };
    dataToSet.template_message_replytomail = { ...data.fromEmail };
  }

  if (Object.prototype.hasOwnProperty.call(data, 'fromName') && Object.keys(data.fromName).length > 0) {
    dataToSet.template_message_expname = { ...data.fromName };
  }

  if (Object.keys(dataToSet).length > 0) {
    setDataFromTemplateBuilder(dataToSet);
  }
}

/*
 * Save the state of campaigns being edited before refreshing the page or closing the browser
 */
export const saveCampaignsInLocalStorage = () => {
  if (state.automationCampaigns.length || state.bulkCampaigns.length) {
    /* If we have an active campaign, we reduce it before performing the backup in localStorage */
    minimizeEditorCampaign();

    /* Format data by removing objects to cycle for stringify */
    const automationCampaignsData: any = deepcopy(state.automationCampaigns);
    automationCampaignsData.forEach((item: any, key: number) => {
      if (Object.keys(automationCampaignsData[key].scenario.data.operators).length) {
        Object.keys(automationCampaignsData[key].scenario.data.operators).forEach((value: string) => {
          if (typeof automationCampaignsData[key].scenario.data.operators[value].internal !== 'undefined') {
            delete automationCampaignsData[key].scenario.data.operators[value].internal;
          }
        });
      }
    });

    /* Format data by removing objects to cycle for stringify */
    const bulkCampaignsData: any = deepcopy(state.bulkCampaigns);
    bulkCampaignsData.forEach((item: any, key: number) => {
      if (Object.keys(bulkCampaignsData[key].scenario.data.operators).length) {
        Object.keys(bulkCampaignsData[key].scenario.data.operators).forEach((value: string) => {
          if (typeof bulkCampaignsData[key].scenario.data.operators[value].internal !== 'undefined') {
            delete bulkCampaignsData[key].scenario.data.operators[value].internal;
          }
        });
      }
    });

    /* We save the campaign object in localStorage */
    setLocalStorageElement('campaigns', JSON.stringify({
      automationCampaigns: automationCampaignsData,
      bulkCampaigns: bulkCampaignsData,
    }));
  } else {
    /* If we have no campaigns being edited, we delete the "campaigns" object from the localStorage */
    removeLocalStorageElement('campaigns');
  }
};

export const updateCampaignAutomationListColumn = async (campaignInput: MarketingWorkflowsUpdateInputItem[]) => {
  const {
    id, err,
  } = await Update<MarketingWorkflowsUpdateInputItem>({
    name: 'MarketingWorkflows',
    input: campaignInput,
    type: 'MarketingWorkflowsUpdateInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
}

export const updateCampaignBulk = async (campaignInput: CampaignsBulkUpdateInputItem[], useBeacon = false) => {
  const {
    id, err,
  } = await Update<CampaignsBulkUpdateInputItem>({
    name: 'CampaignsBulk',
    input: campaignInput,
    type: 'CampaignsBulkUpdateInput',
  }, useBeacon);

  if (err === '') {
    return id;
  }

  throw new Error(err);
}

export async function setAnalyticsMode(analyticsMode: boolean) {
  state.scenario.analyticsMode.show = analyticsMode;
  if (analyticsMode) {
    await updateAnalyticsDateInterval(state.scenario.analyticsMode.dateInterval ?? {
      interval: IntervalDateEnum.LAST_7_DAYS,
    });
  } else {
    state.scenario.analyticsMode.data = null;
  }
}

export function setAnalyticsWorkflowData(data: any) {
  state.scenario.analyticsMode.data = data;
}

export async function updateAnalyticsDateInterval(dateInterval: AutomatedScenarioAnalyticsDateInterval) {
  state.scenario.analyticsMode.dateInterval = dateInterval;
  await setWorkflowAnalyticsData(UserState.activeShop?.id ?? 0, state.scenario.type, state.scenario.id);

  state.operators.forEach((operator: OperatorBox) => {
    setAnalyticsDataToOperator(operator.operatorId);
  });
}

export async function setAnalyticsDataToOperator(operatorId: string) {
  const operator = state.operators.find((op) => op.operatorId === operatorId);

  if (operator) {
    operator.analyticsData = await getOperatorAnalyticsData(operatorId);
  }
}

export async function checkIfCampaignContainsVouchers() {
  const operators = Object.values(AutomatedScenarioState.scenario.data.operators);

  // eslint-disable-next-line no-restricted-syntax
  for (const operator of operators) {
    if (sendingChannelBoxes.includes(operator.custom.id)) {
      if (operator.custom.template_display_selector_id) {
        // eslint-disable-next-line no-await-in-loop
        const listOfThisTemplate = await getTemplateAndRedirect(operator.custom.template_display_selector_id);
        const thisTemplateHasVoucher = listOfThisTemplate !== null ? checkIfTemplateOrRedirectContainsVoucher(listOfThisTemplate) : false;
        if (thisTemplateHasVoucher) {
          return true
        }
      }
    }
  }

  return false;
}

export function checkIfCampaignContainsMediaBoxes(specificBox: string | null = null) {
  return Object.values(state.scenario.data.operators).some((operator) => {
    return !specificBox ? sendingChannelBoxes.includes(operator.custom.id) : operator.custom.id === specificBox;
  });
}

export function getCountOperatorsByType(typeToCount: string) {
  const filteredOperators = Object.values(state.scenario.data.operators).filter(operator => operator.custom.id === typeToCount);
  return filteredOperators.length;
}

export const onEditorUnload = async () => {
  const { id } = state.scenario;

  if (state.scenario.type === TypeCampaignEnum.BULK) {
    const bulkCampaignStatus = await Get<CampaignBulkRaw>({
      name: 'CampaignsBulk',
      id,
      keyName: 'id_campaign_bulk',
      fields: [
        'status',
      ],
    });

    if (bulkCampaignStatus.item && bulkCampaignStatus.item.status === CampaignsBulkStatusEnum.BEING_EDITED) {
      // We update the status to "draft" and the date_modificaiton in state and database
      const dateModification = moment()
        .format('YYYY-MM-DD HH:mm:ss');

      updateDateModificationOfCurrentScenario(dateModification);

      updateCampaignBulk([{
        id_campaign_bulk: id,
        id_shop: UserState.activeShop?.id ?? 0,
        status: CampaignsBulkStatusEnum.DRAFT,
        date_send: null,
        date_modification: dateModification,
      }], true);
    }
    setRefreshScenariosList(true);
  }

  saveCampaignsInLocalStorage();
};

export const getDisplayUrl = async (idShop: number, type: string, id: any) => {
  try {
    const result = await nestGet('v4', `/template/url-display/${idShop}/${type}/${id}`, {});

    if (result && result.success && result.url) {
      return result.url;
    }
  } catch (e) {
    console.error(e.message);
  }

  return '';
};

export const refreshFlowchart = () => {
  state.$flowchart.flowchart('setData', state.scenario.data);
  coreDrawUI();
  flowchartTick();
};

export const getAutomatedCampaignOperators = async (id: number, type: TypeCampaignEnum, excludeOperators: string[] = []) => {
  if (type === TypeCampaignEnum.AUTOMATION) {
    const workflow = await Get<CampaignAutomationRaw>({
      name: 'MarketingWorkflows',
      id,
      keyName: 'id_marketing_workflow',
      fields: [
        'id_marketing_workflow',
        'data_marketing_workflow',
      ],
    });
    if (workflow && workflow.item) {
      let formattedDataWorkflow = JSON.parse(workflow.item.data_marketing_workflow ?? '{}');
      if (formattedDataWorkflow.operators === 'undefined' || !Object.keys(formattedDataWorkflow.operators).length) {
        formattedDataWorkflow = {
          links: {}, operators: {}, operatorTypes: {},
        };
      }

      const operators = new Set<string>();
      Object.keys(formattedDataWorkflow.operators).forEach((operatorId) => {
        const customDataOperators = formattedDataWorkflow.operators[operatorId].custom;
        operators.add(customDataOperators.id);
      });

      let newOperators = Array.from(operators);

      if (excludeOperators.length) {
        newOperators = newOperators.filter(value => !excludeOperators.includes(value));
      }

      return newOperators;
    }
  } else {
    const bulkCampaign = await Get<CampaignBulkRaw>({
      name: 'CampaignsBulk',
      id,
      keyName: 'id_campaign_bulk',
      fields: [
        'id_campaign_bulk',
        'data_flowchart',
      ],
    });
    if (bulkCampaign && bulkCampaign.item) {
      let formattedDataFlowchart = JSON.parse(bulkCampaign.item.data_flowchart ?? '{}');
      if (formattedDataFlowchart.operators === 'undefined' || !Object.keys(formattedDataFlowchart.operators).length) {
        formattedDataFlowchart = {
          links: {}, operators: {}, operatorTypes: {},
        };
      }

      const operators = new Set<string>();
      Object.keys(formattedDataFlowchart.operators).forEach((operatorId) => {
        const customDataOperators = formattedDataFlowchart.operators[operatorId].custom;
        operators.add(customDataOperators.id);
      });

      let newOperators = Array.from(operators);

      if (excludeOperators.length) {
        newOperators = newOperators.filter(value => !excludeOperators.includes(value));
      }

      return newOperators;
    }
  }
  return [];
};

export const deleteLink = (linkId: string) => {
  // Get linked operator
  const linkedOperator = state.scenario.data.links[linkId].toOperator;

  if (boxHasMultipleParents(linkedOperator)) {
    // Delete link from state and flowchart
    delete state.scenario.data.links[linkId];
    state.$flowchart.flowchart('deleteLink', linkId);

    // Add delete link history
    addHistory('delete_link', `automatedScenarios.history.deleteLink`);

    // Draw flowchart
    coreDrawUI();
    flowchartTick();
  }
}

export const getFilterSegmentById = async (id: string) => {
  const filtreCustomerSegments = await getFiltreCustomerSegments();
  const filtrePurchaseHistorySegments = getFiltrePurchaseHistorySegments();
  const filtreCurrentOrderSegments = await getFiltreCurrentOrderSegments();
  const filtreCurrentCartSegments = getFiltreCurrentCartSegments();
  const filtreBoxNavigationTriggerSegments = getFiltreBoxNavigationTriggerSegments();

  const segments = [...filtreCustomerSegments, ...filtrePurchaseHistorySegments, ...filtreCurrentOrderSegments, ...filtreCurrentCartSegments, ...filtreBoxNavigationTriggerSegments];

  return segments.find((item) => item.id === id);
}

export const checkIfFilterLostLinkedBox = async (operatorType: string, operatorId: string) => {
  const typeBoxToVerify = ['openemail', 'filledoutform', 'clicklink'];
  if (typeof (operatorId) === 'undefined') {
    return true;
  }

  if (!typeBoxToVerify.includes(operatorType)) {
    return true;
  }

  const data = state.scenario.data.operators[operatorId].custom;
  const linkedBox = JSON.parse(data.linked_box) ?? [];
  const allExistingBoxes = Object.keys(state.scenario.data.operators);

  const newLinkedBox = linkedBox.filter((element: string) => allExistingBoxes.includes(element));

  if (newLinkedBox.length !== linkedBox.length) {
    state.scenario.data.operators[operatorId].custom.linked_box = JSON.stringify(newLinkedBox);
  }
  return true;
};
