<template>
  <Dialog
    :visible="visible"
    modal
    :header="t('templateSelector.import.importTemplateHeader')"
    :style="{ width: '45vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    @update:visible="handleHideDialog"
  >
    <div
      v-if="!templateType"
      class="grid"
    >
      <div
        v-for="type in listTypes"
        :key="type"
        class="col"
      >
        <Button
          type="button"
          class="p-button-outlined p-button-success button-dialog-type"
          @click="chooseTemplatesType(type)"
        >
          <div class="icon">
            <i
              :class="t('templateSelector.dialogTypeTemplate.types.'+type+'.icon')"
            />
          </div>
          <div class="legend">
            {{ t('templateSelector.dialogTypeTemplate.types.'+type+'.label') }}
          </div>
        </Button>
      </div>
    </div>

    <div
      v-else
      class="flex flex-column justify-content-start gap-2 mx-2"
    >
      <Message
        :closable="false"
        severity="secondary"
        v-html="t('templateSelector.import.importNotice')"
      ></Message>
      <Message
        :closable="false"
        class="text-justify"
      >
        {{ t('templateSelector.import.unsubscribeUrl') }}
      </Message>
      <ZipFileUploader
        v-model="uploadExternalImage"
        @on-file-uploaded="handleZipFileUploaded"
        @on-file-removed="handleZipFileRemoved"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="error"
        field="html"
      />
      <div
        v-if="zipTemplate"
        class="flex justify-content-center"
      >
        <Button
          :label="t('templateSelector.buttons.chooseTemplate')"
          class="p-button-sm p-button-success"
          @click="handleChooseZipTemplate"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
} from 'vue';

import Button from 'primevue/button';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';

import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ZipFileUploader from '@/components/template-builder/fields/ZipFileUploader.vue';

import { TemplateTypeEnum } from '@/composables/shop/Templates';
import {
  activate,
  createHtmlTemplateFromZipFile,
  setRefreshTemplatesList,
} from '@/composables/template-editor/TemplateEditor';
import { UserState } from '@/composables/User';

import { showToastError } from '@/helpers';

import { ZipFile } from '@/types/zip-file-uploader-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

import useVuelidate from '@vuelidate/core';
import { importedHtmlValidator } from '@/helpers/CustomValidator';

export default defineComponent({
  name: 'ImportTemplateModal',
  components: {
    Button,
    ZipFileUploader,
    Message,
    FieldErrors,
    Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible'],

  setup(props, { emit }) {
    const listTypes = [TemplateTypeEnum.EMAIL];

    const { t } = useI18n();
    const store = useStore();

    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const templateType = ref('');

    const uploadExternalImage = ref(0);

    const zipTemplate: Ref<ZipFile | null> = ref(null);

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const handleZipFileUploaded = (zipFile: ZipFile) => {
      zipTemplate.value = zipFile;
    };

    const handleZipFileRemoved = () => {
      zipTemplate.value = null;
      componentFieldErrorsKey.value = 0;
      error.value = null;
      uploadExternalImage.value = 0;
    };

    const chooseTemplatesType = (type: string) => {
      templateType.value = type;
    };

    const handleHideDialog = () => {
      templateType.value = '';
      zipTemplate.value = null;
      componentFieldErrorsKey.value = 0;
      error.value = null;
      uploadExternalImage.value = 0;
      emit('update:visible', false);
    };

    const validate = async (html: string): Promise<ErrorConfigForm> => {
      const rules = {
        html: {
          importedHtmlValidator: importedHtmlValidator(),
        },
      };

      const v$ = useVuelidate(rules, { html });
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleChooseZipTemplate = async () => {
      if (zipTemplate.value) {
        const { html } = zipTemplate.value;
        const validation = await validate(html);
        if (!validation.success) {
          error.value = validation.validate;
          componentFieldErrorsKey.value += 1;
          return;
        }
        try {
          store.commit('general/showTheSpinner');
          const { id, type, label } = await createHtmlTemplateFromZipFile(idShop.value, zipTemplate.value, templateType.value, uploadExternalImage.value);
          activate(id, label, type).then(() => {
            store.commit('general/hideTheSpinner');
            setRefreshTemplatesList(true);
            handleHideDialog();
          });
        } catch (err) {
          showToastError(t('templateBuilder.panels.fileManager.uploadError'));
          store.commit('general/hideTheSpinner');
        }
      }
    };

    return {
      t,
      listTypes,
      templateType,
      uploadExternalImage,
      zipTemplate,
      componentFieldErrorsKey,
      error,

      chooseTemplatesType,
      handleHideDialog,
      handleZipFileUploaded,
      handleZipFileRemoved,
      handleChooseZipTemplate,
    };
  },
});
</script>

<style lang="scss" scoped>
.button-dialog-type {
  width: 100%;
  height: 7rem;
  display: block;
  position: relative;
  border: solid 1px $solitude !important;
  color: $heather !important;

  &:hover {
    background: $solitude !important;
  }

  .icon {
    font-size: 2rem;
  }

  .legend {
    font-size: 1em;
    color: black;
  }
}

.p-message-secondary {
  color: #666;
  background-color: #f6f6f6;
  border: 0 #eee;
  position: relative;
  margin-bottom: 0;
  border-radius: .25rem;
  padding: .75rem 1.25rem;
  text-align: justify;

  &:deep() > a {
    color: $brand-color-primary !important;
  }
}
</style>
