import { File } from '@/types';
import { get } from 'lodash';

export function formatFileSize(bytes: number) {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 1000;
  const dm = 3;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

async function isImageMimeType(url: string): Promise<boolean> {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentType = response.headers.get('content-type');
    return contentType ? contentType.startsWith('image/') : false;
  } catch (error) {
    return false;
  }
}

export async function isImage(url: string): Promise<boolean> {
  if (url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/i)) {
    return isImageMimeType(url);
  }
  return false;
}

export function transformSourceResponse(data: any[], mapping: Record<string, string>, source: string): File[] {
  return data.map((item) => {
    const transformedItem: File = {
      id: '',
      url: '',
      previewUrl: '',
      description: '',
      source,
    };
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in mapping) {
      transformedItem[key as keyof File] = get(item, mapping[key], '');
    }
    return transformedItem;
  });
}
