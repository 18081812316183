import { createRouter, createWebHistory } from 'vue-router';

import { store } from '@/store';
import { setItemInLocalStorageWithExpiry, showToastError, showToastSuccess } from '@/helpers';
import {
  findPermission,
  getIsAuthenticated,
  HasNoPermissions,
  Logout, Me, resetPermissions, setPermissionsString,
  hasAccessToFeatures, SwitchActiveShop,
} from '@/composables/User';
import MainLayout from '@/views/layout/LayoutMain.vue';
import LayoutAuthentication from '@/views/layout/LayoutAuthentication.vue';
import ChooseShop from '@/views/auth/ChooseShop.vue';
import Dashboard from '@/views/Dashboard.vue';
import MyTemplates from '@/views/MyTemplates.vue';
import Login from '@/views/auth/Login.vue';
import AutomatedScenarioList from '@/views/automated-scenarios/AutomatedScenarioList.vue';
import ShopEdit from '@/views/shop/ShopEdit.vue';
import MyNotifications from '@/views/shop/MyNotifications.vue';
import MyDomains from '@/views/shop/MyDomains.vue';
import MyOffer from '@/views/shop/MyOffer.vue';
import GdprLogs from '@/views/shop/GdprLogs.vue';
import ListsManage from '@/views/mes-listes/ListsManage.vue';
import Profile from '@/views/user/Profile.vue';
import MyShopSyncs from '@/views/my-shop-data/MyShopSyncs.vue';
import SyncList from '@/views/my-shop-data/SyncList.vue';
import Carriers from '@/views/my-shop-data/Carriers.vue';
import Customers from '@/views/my-shop-data/Customers.vue';
import CustomerGroups from '@/views/my-shop-data/CustomerGroups.vue';
import Manufacturers from '@/views/my-shop-data/Manufacturers.vue';
import Orders from '@/views/my-shop-data/Orders.vue';
import OrderStatus from '@/views/my-shop-data/OrderStatus.vue';
import ProductCategories from '@/views/my-shop-data/ProductCategories.vue';
import Products from '@/views/my-shop-data/Products.vue';
import Vouchers from '@/views/my-shop-data/Vouchers.vue';
import ManageMyAccount from '@/views/user/ManageMyAccount.vue';
import ListsPushTokens from '@/views/mes-listes/ListsPushTokens.vue';
import ListsUnSubscribers from '@/views/mes-listes/customers/ListsUnSubscribers.vue';
import ListsBounces from '@/views/mes-listes/customers/ListsBounces.vue';
import ListsSpams from '@/views/mes-listes/customers/ListsSpams.vue';
import ListsCustomers from '@/views/mes-listes/customers/ListsCustomers.vue';
import ListsImportReports from '@/views/mes-listes/ListsImportReports.vue';
import MarketingPressure from '@/views/marketing-pressure/MarketingPressure.vue';
import AutomatedScenarioMain from '@/views/automated-scenarios/AutomatedScenarioMain.vue';
import NotFoundComponent404 from '@/views/errors/NotFoundComponent404.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import SpeedRegistration from '@/views/auth/SpeedRegistration.vue';
import BulkCampaignsList from '@/views/automated-scenarios/BulkCampaignsList.vue';
import PermissionsByShop from '@/views/user/PermissionsByShop.vue';
import SubUserList from '@/views/user/SubUserList.vue';
import StatsCustoms from '@/views/stats/StatsCustoms.vue';
import StatsList from '@/views/user/StatsList.vue';
import { i18n } from '@/i18n';
import ListImportUnsub from '@/views/mes-listes/ListImportUnsub.vue';
import MyOldNewslettersStatistics from '@/views/shop/MyOldNewslettersStatistics.vue';
import Complete from '@/views/Complete.vue';
import AccountActivation from '@/views/auth/AccountActivation.vue';
import { nestGet } from '@/composables/nestApi';
import { checkIfDbCreated } from '@/configs/complete';
import axios from 'axios';
import AdvisorNewLink from '@/views/auth/AdvisorNewLink.vue';
import UserExports from '@/views/user/UserExports.vue';
import ChangePassword from '@/views/auth/ChangePassword.vue';
import DownloadFile from '@/views/shop/DownloadFile.vue';

type Redirection = boolean | { name: string };
let translation: any;
(async () => {
  translation = await i18n;
})();

async function userGuard(onOk: Redirection, onFailure: Redirection) {
  let isAuthenticated = getIsAuthenticated();
  if (isAuthenticated) {
    return onOk;
  }
  store.commit('general/showTheSpinner');
  try {
    await Me(true);
    isAuthenticated = getIsAuthenticated();
    if (!isAuthenticated) {
      return onFailure;
    }
    if (HasNoPermissions()) {
      await Logout();
      resetPermissions();
      await showToastError(translation.global.t('user.subUser.loginError'));
      return onFailure;
    }
    setPermissionsString();
    return onOk;
  } catch (error: any) {
    return onFailure;
  } finally {
    store.commit('general/hideTheSpinner');
  }
}

async function checkAccessAndRedirect(to: any, from: any, featureKey: string, permissionKey: string, redirectRoute = 'home') {
  if (!findPermission(permissionKey)) {
    return { name: redirectRoute };
  }

  const featurePermission = hasAccessToFeatures(featureKey);
  if (featurePermission && !featurePermission.access) {
    store.commit('general/setIsFeatureUnavailableModalVisible', true);
    store.commit('general/setLastRoute', { to, from });
    store.commit('general/setFeatureUnavailableMinPlan', featurePermission.minimumPlan);
    return false;
  }

  return true;
}

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'dashboard',
        name: 'home',
        alias: '/',
        component: Dashboard,
      },
      {
        path: '/404',
        name: 'not-found',
        component: NotFoundComponent404,
      },
      {
        path: 'my-templates',
        name: 'template-manage',
        component: MyTemplates,
        beforeEnter: async () => {
          if (!findPermission('my_templates.view')) {
            return ({ name: '/' });
          }
          return true;
        },
      },
      {
        path: '/',
        component: AutomatedScenarioMain,
        children: [
          {
            path: 'automated-scenarios',
            name: 'workflow',
            component: AutomatedScenarioList,
            beforeEnter: async () => {
              if (!findPermission('my_templates.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'marketing-pressure',
            name: 'marketing-pressure',
            component: MarketingPressure,
            beforeEnter: async () => {
              if (!findPermission('automated_scenarios.marketing_pressure.view')) {
                return ({ name: '/' });
              }
              return true;
            },
          },
        ],
      },
      {
        path: 'campaigns-bulk',
        name: 'campaigns-bulk',
        component: BulkCampaignsList,
        beforeEnter: async () => {
          if (!findPermission('bulk_campaigns.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
    ],
    beforeEnter: async (to: any, from: any, next: Function) => {
      const result = await userGuard(true, false);
      if (result) {
        next();
      } else {
        // si on navigue sur http://domain.com directement ou a travers le "button" back on, on redirige vers http://domain.com/auth/login
        // eslint-disable-next-line no-lonely-if
        if (from.fullPath === '/') {
          next({ name: 'login' });
        }
      }
    },
  },

  {
    path: '/complete/',
    component: Complete,
    beforeEnter: async (to: any, from: any, next: Function) => {
      const result = await userGuard(true, false);
      if (result) {
        const dbCreated = await checkIfDbCreated();
        if (dbCreated) {
          next('/');
        } else {
          next();
        }
      } else {
        next({ name: 'login' });
      }
    },
  },

  {
    path: '/shop/',
    component: MainLayout,
    children: [
      {
        path: 'edit',
        name: 'shop.ShopEdit',
        component: ShopEdit,
        beforeEnter: async () => {
          if (!findPermission('store_params.edit')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'send-domains/:token?',
        name: 'shop.MyDomains',
        component: MyDomains,
        props: true,
        beforeEnter: async () => {
          if (!findPermission('my_domains.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'plan',
        name: 'shop.MyOffer',
        component: MyOffer,
        beforeEnter: async () => {
          if (!findPermission('my_offer.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'old-newsletters-statistics',
        name: 'shop.MyOldNewslettersStatistics',
        component: MyOldNewslettersStatistics,
        beforeEnter: async () => true,
      },
      {
        path: 'notifications',
        name: 'shop.MyNotifications',
        component: MyNotifications,
        beforeEnter: async () => {
          if (!findPermission('notifications.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'download-file/:encodedString',
        name: 'shop.DownloadFile',
        component: DownloadFile,
        props: true,
      },
      {
        path: 'logs',
        name: 'shop.gdpr-logs',
        component: GdprLogs,
        beforeEnter: async () => {
          if (!findPermission('my_bills.gdpr.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'data/',
        name: 'shop.data',
        component: SyncList,
        beforeEnter: async () => {
          if (!findPermission('my_data.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'syncs/',
        name: 'shop.syncs',
        component: MyShopSyncs,
        children: [
          {
            path: 'carriers',
            name: 'shop.syncs.carriers',
            component: Carriers,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'customers-groups',
            name: 'shop.syncs.customers_groups',
            component: CustomerGroups,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'customers',
            name: 'shop.syncs.customers',
            component: Customers,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'manufacturers',
            name: 'shop.syncs.manufacturers',
            component: Manufacturers,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'orders',
            name: 'shop.syncs.orders',
            component: Orders,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'orderStatus',
            name: 'shop.syncs.orders_status',
            component: OrderStatus,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'products',
            name: 'shop.syncs.products',
            component: Products,
            // beforeEnter: async () => {
            //   if (!findPermission('my_data.view')) {
            //     return ({ name: 'home' });
            //   }
            //   return true;
            // },
          },
          {
            path: 'product-categories',
            name: 'shop.syncs.products_categories',
            component: ProductCategories,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
          {
            path: 'vouchers',
            name: 'shop.syncs.vouchers',
            component: Vouchers,
            beforeEnter: async () => {
              if (!findPermission('my_data.view')) {
                return ({ name: 'home' });
              }
              return true;
            },
          },
        ],
      },
    ],
    beforeEnter: async () => userGuard(true, { name: 'login' }),
  },

  {
    path: '/user/',
    component: MainLayout,
    children: [
      {
        path: 'profile/:tab?',
        name: 'user.profile',
        component: Profile,
        props: true,
      },
      // {
      //   path: 'manage-account',
      //   name: 'user.manage-my-account',
      //   component: ManageMyAccount,
      // },
      {
        path: 'manage-sub-users/:userId?',
        name: 'user.manage-sub-users',
        component: PermissionsByShop,
        props: true,
        beforeEnter: async (to: any, from: any) => checkAccessAndRedirect(to, from, 'user.manage-sub-users', 'admin'),
      },
      {
        path: 'sub-user-list',
        name: 'user.sub-user-list',
        component: SubUserList,
        beforeEnter: async () => {
          if (!findPermission('admin')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'stats-list',
        name: 'users.stats',
        component: StatsList,
        beforeEnter: async (to: any, from: any) => checkAccessAndRedirect(to, from, 'users.stats', 'stats_report.view'),
      },
      {
        path: 'exports-list',
        name: 'users.exports',
        component: UserExports,
      },
    ],
    beforeEnter: async () => userGuard(true, { name: 'login' }),
  },

  {
    path: '/lists/',
    component: MainLayout,
    children: [
      {
        path: 'manage',
        name: 'lists-manage',
        component: ListsManage,
        beforeEnter: async (to: any, from: any, next: Function) => {
          if (!findPermission('lists-segments.manage_lists')) {
            next({ name: 'home' });
          } else {
            next();
          }
        },
      },
      {
        path: 'push-tokens',
        name: 'lists.push-tokens',
        component: ListsPushTokens,
        beforeEnter: async () => {
          if (!findPermission('my_push_notif.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'import-reports',
        name: 'lists.import-reports',
        component: ListsImportReports,
        beforeEnter: async () => {
          if (!findPermission('lists-segments.import-reports')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'manage-customers/:id/:type?/:idShopListImport?',
        name: 'lists.manage-customers',
        component: ListsCustomers,
        props: true,
        beforeEnter: async () => {
          if (!findPermission('lists-segments.modify_clients')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'manage-customers/unsub',
        name: 'lists.manage-customers-unsub',
        component: ListsUnSubscribers,
        beforeEnter: async () => {
          if (!findPermission('lists-segments.unsub')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'manage-customers/bounces',
        name: 'lists.manage-customers-bounces',
        component: ListsBounces,
        beforeEnter: async () => {
          if (!findPermission('my_bounces.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'manage-customers/spams',
        name: 'lists.manage-customers-spams',
        component: ListsSpams,
        beforeEnter: async () => {
          if (!findPermission('my_spams.view')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
      {
        path: 'import-unsub',
        name: 'lists.import-unsub',
        component: ListImportUnsub, // todo: create own component page
        beforeEnter: async () => {
          if (!findPermission('lists-segments.unsubscribers_import')) {
            return ({ name: 'home' });
          }
          return true;
        },
      },
    ],
    beforeEnter: async () => userGuard(true, { name: 'login' }),
  },

  {
    path: '/custom_stats/',
    component: MainLayout,
    children: [
      {
        path: 'custom_stats_:pageId([0-9]+)',
        name: 'custom_stats',
        props: (route: any) => ({ pageId: parseInt(route.params.pageId, 10) }),
        component: StatsCustoms,
      },
    ],
    beforeEnter: async () => userGuard(true, { name: 'login' }),
  },

  {
    path: '/auth',
    component: LayoutAuthentication,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        beforeEnter: async () => userGuard({ name: 'home' }, true),
      },
      {
        path: 'forgot-password',
        name: 'user.forgot-password',
        component: ForgotPassword,
        // beforeEnter: async () => userGuard({ name: 'dashboard' }, true),
      },
      {
        path: 'change-password/:token',
        name: 'user.change-password',
        component: ChangePassword,
        props: true,
        beforeEnter: async (to: any, from: any, next: any) => {
          const { token } = to.params;
          try {
            const validity = await nestGet('v4', `/user/token-reset-password-validity/${token}`, {}, '');

            if (!validity || !validity.success) {
              await showToastError('Le lien pour réinitialiser le mot de passe est invalide ou a expiré.');
              next({ name: 'login' });
            }
          } catch (e) {
            await showToastError('GENERIC_ERROR');
          }
          next();
        },
      },
      {
        path: 'registration/speed',
        name: 'user.registration-speed',
        component: SpeedRegistration,
        beforeEnter: async (to: any, from: any, next: any) => {
          const statusRegistration = await nestGet('v4', '/user/stateRegister', {}, '');
          if (statusRegistration && statusRegistration.success && statusRegistration.status === 'closed') {
            await showToastError('Les inscriptions sont temporairement suspendues. Veuillez réessayer ultérieurement.');
            next({ name: 'login' });
          } else {
            next();
          }
        },
      },
      {
        path: '/account/activation/:idUser/:token',
        name: 'user.activation',
        component: AccountActivation,
        beforeEnter: async (to: any, from: any, next: any) => {
          // Accédez aux paramètres de la route en tant que props
          const { idUser, token } = to.params;
          try {
            const resultActivation = await nestGet('v4', `/user/activation/${idUser}/${token}`, {}, '');

            if (resultActivation && resultActivation.success) {
              await showToastSuccess('Compte activé avec succès!');
            } else if (resultActivation.reason) {
              await showToastError(resultActivation.reason);
            } else {
              await showToastError('GENERIC_ERROR');
            }
          } catch (e) {
            await showToastError('GENERIC_ERROR');
          }

          next({ name: 'login' });
        },
      },
      {
        path: '/send-domains/activation/:idShop/:token',
        name: 'send-domains.activation',
        component: AccountActivation,
        beforeEnter: async (to: any, from: any, next: Function) => {
          // Accédez aux paramètres de la route en tant que props
          const { idShop, token } = to.params;
          try {
            const resultActivation = await nestGet('v4', `/send-domain/activation/${idShop}/${token}`, {}, '');

            if (resultActivation && resultActivation.success) {
              await showToastSuccess('Domaine vérifié avec succès!');
            } else {
              await showToastError('GENERIC_ERROR');
            }
          } catch (e) {
            await showToastError('GENERIC_ERROR');
          }

          next({ name: 'shop.MyDomains' });
        },
      },

    ],
  },

  {
    path: '/shopify',
    component: AccountActivation,
    children: [
      {
        path: 'auth/:dataBase64/:hmac',
        name: 'shopify.auto.auth',
        component: AccountActivation,
        beforeEnter: async (to: any, from: any, next: any) => {
          store.commit('general/showTheSpinner');
          await Logout();
          const { dataBase64, hmac } = to.params;
          try {
            if (dataBase64 && hmac) {
              const res = await axios.create({ baseURL: '/api' }).post('/token/alt-login', { data: dataBase64, token: hmac });
              if (res && res.data && res.data.success) {
                const { idShop } = res.data;
                await Me(true, Number(idShop));
                await showToastSuccess(translation.global.t('login_success'));
                next({ name: 'home' });
              } else {
                await showToastError('GENERIC_ERROR');
              }
            }
          } catch (e) {
            await showToastError('GENERIC_ERROR');
          }
          store.commit('general/hideTheSpinner');

          next({ name: 'login' });
        },
      },
    ],
  },

  {
    path: '/choose-shop',
    name: 'choose-shop',
    component: ChooseShop,
  },

  {
    path: '/advisor/new-link/:token',
    name: 'advisor.newlink',
    component: AdvisorNewLink,
    beforeEnter: async (to: any, from: any, next: any) => {
      // Accédez aux paramètres de la route en tant que props
      const { token } = to.params;
      try {
        const { data }: Record<string, any> = await axios.create({ baseURL: '/api' }).get(`/advisor/new-link?token=${token}`);

        if (data && data.success) {
          await showToastSuccess('success');
        } else if (data.error) {
          await showToastError(data.error);
        } else {
          await showToastError('GENERIC_ERROR');
        }
      } catch (e) {
        await showToastError('GENERIC_ERROR');
      }

      next({ name: 'login' });
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { pi, pireferer } = to.query;
  // Si un code promo existe dans l'URL, on le sauvegarde dans le localStorage
  if (pi) {
    setItemInLocalStorageWithExpiry('promoCodePartner', pi as string, 2);
  }
  if (pireferer) {
    window.location.href = pireferer as string;
  } else {
    next();
  }
});

export default router;
