<template>
  <Dialog
    :visible="visible"
    modal
    :header="t('templateSelector.import.importTemplateHeader')"
    :style="{ width: '45vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div
      class="grid"
    >
      <div
        v-for="importMethod in importMethodList"
        :key="importMethod"
        class="col"
      >
        <Button
          type="button"
          class="p-button-outlined p-button-success button-dialog-type"
          @click="chooseMethod(importMethod)"
        >
          <div class="icon">
            <i
              :class="t('templateMethodSelector.methods.' + importMethod + '.icon')"
            />
          </div>
          <div class="legend">
            {{ t('templateMethodSelector.methods.' + importMethod + '.label') }}
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
} from 'vue';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import { useI18n } from 'vue-i18n';

import { TEMPLATE_IMPORT_METHODS_LIST } from '@/configs/templates';

export default defineComponent({
  name: 'TemplateImportMethodModal',
  components: {
    Button,
    Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible', 'selected-import-method'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const importMethodList = reactive(TEMPLATE_IMPORT_METHODS_LIST);

    const chooseMethod = (importMethod: string) => {
      emit('selected-import-method', importMethod);
    };

    return {
      t,
      importMethodList,
      chooseMethod,
    };
  },
});
</script>

<style lang="scss" scoped>
.button-dialog-type {
  width: 100%;
  height: 7rem;
  display: block;
  position: relative;
  border: solid 1px $solitude !important;
  color: $heather !important;

  &:hover {
    background: $solitude !important;
  }

  .icon {
    font-size: 2rem;
  }

  .legend {
    font-size: 1em;
    color: black;
  }
}
</style>
