
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
} from 'vue';

import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import SelectButton from 'primevue/selectbutton';
import FileUpload, { FileUploadUploaderEvent } from 'primevue/fileupload';
import Toolbar from 'primevue/toolbar';

import FileItem from '@/components/file-manager/FileItem.vue';
import FolderItem from '@/components/file-manager/FolderItem.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import InputTextModal from '@/components/modals/InputTextModal.vue';

import { useToast } from 'primevue/usetoast';
import ProgressSpinner from 'primevue/progressspinner';
import { MenuItem } from 'primevue/menuitem';

import { UserState } from '@/composables/User';
import {
  getFilesFromPath,
  createFolderInMedia,
  createNewFileInMedia,
} from '@/composables/configs/configuration';

import { extensions as extensionsList } from '@/configs/file-manager';

import { File, Folder } from '@/types';

import { showToastError } from '@/helpers';

import { useI18n } from 'vue-i18n';
import { folderNameValidator } from '@/helpers/CustomValidator';

export default defineComponent({
  name: 'FilesList',

  components: {
    Breadcrumb,
    Button,
    Divider,
    FileUpload,
    ProgressSpinner,
    FileItem,
    FolderItem,
    Toolbar,
    SpmOverlayPanel,
    SpmPanelMenu,
    InputTextModal,
    SelectButton,
  },

  props: {
    extensions: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-file': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const files: Ref<File[]> = ref([]);
    const folders: Ref<Folder[]> = ref([]);
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
    const data = ref('');
    const toast = useToast();

    const loading = ref(false);
    const loadingNextPage = ref(false);

    const displaySpinner = ref(false);

    const chooseFileBtnRef = ref();

    const newFolderName: Ref<string> = ref('');
    const displayNewFolderModal = ref(false);
    const currentPath = ref('');

    const layout = ref('grid');
    const layoutOptions = ref(['list', 'grid']);

    const handleChooseFile = (params: any) => {
      context.emit('on-choose-file', params);
      if (params.action === 'download') {
        context.emit('on-close-dialog');
      }
    };

    const handleDeleteItem = async (fileId: string) => {
      files.value = files.value.filter((file: File) => file.id !== fileId);
    };

    const handleMoveItem = async (sameFile: boolean, fileId: string) => {
      if (!sameFile) {
        files.value = files.value.filter((file: File) => file.id !== fileId);
      }
    };

    const convertImageToBase64 = async (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const base64Data = e.target.result;
        data.value = base64Data;
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    });

    const handleUpload = async (event: FileUploadUploaderEvent) => {
      if (event.files && Array.isArray(event.files)) {
        // eslint-disable-next-line no-restricted-syntax
        try {
          const dataFiles: string[] = [];
          displaySpinner.value = true;

          for (const file of event.files) {
            // eslint-disable-next-line no-await-in-loop
            await convertImageToBase64(file);
            // eslint-disable-next-line no-await-in-loop
            dataFiles.push(data.value);
          }

          const uploadResult = await createNewFileInMedia(dataFiles, idShop, currentPath.value);

          uploadResult.data.forEach((element: any) => {
            const { id, path, type } = element;

            const tempFile: File = {
              id,
              url: `https://media.shopimind.io/clients/${path}`,
              base_url: path,
              type,
            };
            files.value.unshift(tempFile);
          });
        } catch (error) {
          toast.add({
            severity: 'error', summary: t('error'), detail: t('templateBuilder.panels.fileManager.updateError'), life: 3000,
          });
        } finally {
          displaySpinner.value = false;
        }
      }
    };

    const chooseFileToUpload = () => {
      if (chooseFileBtnRef.value) {
        chooseFileBtnRef.value.$el.click();
      }
    };

    const toolbarItems: Ref<MenuItem[]> = ref([
      {
        label: t('fileManager.filesList.toolbar.newBtn.actions.newFolder'),
        icon: 'fal fa-folder',
        command: () => { displayNewFolderModal.value = true; },
      },
    ]);

    const lastFile = ref('');
    const checkNextPage = ref(true);

    const fetchData = async (path = '', nextPage = false) => {
      if (!nextPage) {
        files.value = [];
        folders.value = [];
        loading.value = true;
      } else {
        loadingNextPage.value = true;
      }

      const result = await getFilesFromPath(path, idShop, checkNextPage.value ? lastFile.value : '');
      let acceptedExtensions = props.extensions.split(',').map(ext => ext.trim());
      acceptedExtensions = acceptedExtensions.flatMap(ext => ext === 'image/*' ? extensionsList['image/*'].split(',') : ext);

      if (result.data) {
        if (result.data.files) {
          Object.entries(result.data.files)
            .forEach((row: any, name: any) => {
              if (!row[1].is_dir) {
                const index = row[0].split('.')[0];
                const url = `https://media.shopimind.io/clients/${row[1].src}`;

                if (acceptedExtensions.some((ext) => row[1].name.toLowerCase().endsWith(ext.toLowerCase()))) {
                  files.value.push({
                    id: index,
                    url,
                    base_url: row[1].src,
                    type: row[1].type
                  });
                }
              } else if (row[1].is_dir && !nextPage) {
                folders.value.push({
                  id: row[1].name,
                  name: row[1].name,
                  path: row[1].path,
                });
              }
            });
        }
        lastFile.value = result.data.lastFile;
        checkNextPage.value = result.data.moreFiles;
      }
      loading.value = false;
      loadingNextPage.value = false;
    };

    // Breadcrumb
    const breadcrumbItems: Ref<MenuItem[]> = ref([]);

    const toHome = async () => {
      breadcrumbItems.value = [];
      currentPath.value = '';
      // Reinit checkNextPage and lastFile
      lastFile.value = '';
      checkNextPage.value = true;
      await fetchData();
    }

    const homeBreadcrumb: Ref<MenuItem> = ref({
      icon: 'fal fa-house',
      command: async () => {
        await toHome();
      },
    });

    const onOpenFolder = async (folder: Folder) => {
      const toFolder = async () => {
        const path = breadcrumbItems.value.map((item) => item.label).join('/');
        if (currentPath.value !== path) {
          currentPath.value = path;
          // Reinit checkNextPage and lastFile
          lastFile.value = '';
          checkNextPage.value = true;
          await fetchData(path);
        }
      }

      if (!breadcrumbItems.value.find((item) => item.key === folder.id)) {
        breadcrumbItems.value.push({
          label: folder.name,
          key: folder.id,
          command: () => {
            onOpenFolder(folder);
          }
        });
        await toFolder();
      } else {
        const targetIndex = breadcrumbItems.value.findIndex(item => item.key === folder.id);
        breadcrumbItems.value = breadcrumbItems.value.splice(0, targetIndex + 1);
        await toFolder();
      }
    };

    const folderNameValidation = ref({
      folderNameValidator: folderNameValidator(),
    });

    const createFolder = async (folderName: string, callback: Function) => {
      try {
        const newPath = currentPath.value ? `${currentPath.value}/${folderName}` : folderName;
        const { data: newData } = await createFolderInMedia(newPath, idShop);
        if (!folders.value.find((folder) => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim())) {
          folders.value.push({
            id: folderName,
            name: folderName,
            path: newData.path,
          });
        }
      } catch (err) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        callback();
      }
    };

    const handleScroll = async () => {
      if (!loadingNextPage.value && checkNextPage.value) {
        let path = '';
        if (breadcrumbItems.value.length) {
          path = breadcrumbItems.value.map((item) => item.label).join('/');
        }
        await fetchData(path, true);
      }
    };

    const handleReturn = async () => {
      if (breadcrumbItems.value.length - 2 < 0) {
        await toHome();
      } else {
        const folderToReturnTo: Folder = {
          id: breadcrumbItems.value[breadcrumbItems.value.length - 2].key as string,
          name: breadcrumbItems.value[breadcrumbItems.value.length - 2].label as string,
          path: '',
        };
        await onOpenFolder(folderToReturnTo);
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      t,
      files,
      folders,
      displaySpinner,
      chooseFileBtnRef,
      toolbarItems,
      homeBreadcrumb,
      breadcrumbItems,
      loading,
      newFolderName,
      displayNewFolderModal,
      layout,
      layoutOptions,
      loadingNextPage,
      folderNameValidation,

      handleChooseFile,
      handleUpload,
      handleDeleteItem,
      chooseFileToUpload,
      onOpenFolder,
      createFolder,
      handleScroll,
      handleReturn,
      handleMoveItem,
    };
  },
});
