<template>
  <div
    class="configuration-wrapper"
  >
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.widgets.header') }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          v-if="isClosable"
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <EmbedPosition
        v-if="isEmbed"
      />

      <div class="field-group">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <h4 class="py-3">
                {{ t('templateBuilder.panels.widgets.widgetsHeader') }}
              </h4>
              <div class="p-fluid mb-1">
                <div
                  id="widgets-panel-1"
                  class="grid field-wrapper"
                >
                  <div
                    v-for="button in availableWidgetsButtonsMap"
                    :key="button.type"
                    class="col-4 widget-btn-container"
                    :class="`sortable-group-${button.group}`"
                    :data-widget-type="button.type"
                  >
                    <WidgetsPanelButton
                      :button="button"
                      :translation="t"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showStructureWidgets"
        class="field-group"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <h4 class="py-3">
                {{ t('templateBuilder.panels.widgets.structureHeader') }}
              </h4>
              <div class="p-fluid mb-1">
                <div
                  id="widgets-panel-2"
                  class="grid field-wrapper"
                >
                  <div
                    v-for="button in availableStructureWidgetsButtonsMap"
                    :id="button.group"
                    :key="button.type"
                    class="col-4 widget-btn-container"
                  >
                    <div
                      class="sortable-placeholder"
                      :class="`sortable-group-${button.group}`"
                      :data-widget-type="button.type"
                    >
                      <div
                        data-test-id="widgets-buttons"
                        class="widget-btn p-button inline-flex flex-column justify-content-around align-items-center"
                      >
                        <em :class="button.iconClass" />
                        <span>{{ button.label }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  Ref,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  formExistsInTemplate, hideLeftToolbar,
  resetActiveSection,
  setActiveSection, setPerfectSizeForImage,
  showColumnWidgetModal,
  showSectionWidgetModal,
  TemplateEditorState as state, updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { HistoryType, TemplateStructureEnum, WidgetTypeEnum } from '@/types';
import {
  getTemplateIframeDocument,
  getTemplateParentTypeByType,
  isDisplayTemplate,
  isEmailTemplate,
  isFacebookTemplate,
} from '@/components/template-builder/utils/helpers';
import { getWidgetStructures } from '@/components/template-builder/utils/raw-html-templates';
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { resetListeners } from '@/components/template-builder/utils/listeners';
import Sortable from 'sortablejs';
import {
  ANIMATION_SPEED,
  BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS,
  COLUMN_WITH_PLACEHOLDER_CLASS,
  WIDGET_DROPPABLE_CLASS,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
  WIDGET_IMPORT_ZIP_PLACEHOLDER_IDENTIFIER,
} from '@/components/template-builder/utils/constants';
import { addOrUpdateDynamicStyle, computeActiveSection } from '@/components/template-builder/utils/parser';
import { getParents } from '@/helpers';
import EmbedPosition from '@/components/template-builder/fields/EmbedPosition.vue';
import { TemplateTypeEnum } from '@/composables/shop/Templates';
import Panel from 'primevue/panel';
import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
import {
  UserState,
  hasAccessToFeatures,
} from '@/composables/User';
import { unserialize } from 'php-serialize';
import { useStore } from '@/store';

import {
  generateTranslations,
} from '@/components/template-builder/utils/translate';

import WidgetsPanelButton from '@/components/template-builder/panels/WidgetsPanelButton.vue';

interface WidgetButton {
  type: WidgetTypeEnum;
  iconClass: string;
  label: string;
  group: TemplateStructureEnum;
  hide?: Function;
}

export default defineComponent({
  name: 'WidgetsPanel',

  components: {
    EmbedPosition,
    Panel,
    WidgetsPanelButton,
  },

  props: {
    isClosable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const allWidgetsMap: Array<WidgetButton> = [
      {
        type: WidgetTypeEnum.TEXT,
        iconClass: 'fal fa-heading',
        label: isFacebookTemplate(state.template?.type) ? t('templateBuilder.widgets.fbTextButtons.widgetName') : t('templateBuilder.widgets.text'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.IMAGE,
        iconClass: 'fal fa-images',
        label: isFacebookTemplate(state.template?.type) ? t('templateBuilder.widgets.fbImageButtons') : t('templateBuilder.widgets.image'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.SOCIAL_MEDIA,
        iconClass: 'fab fa-twitter', // alternative icon
        label: t('templateBuilder.widgets.socialMedia'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.SHARE_SOCIAL_MEDIA,
        iconClass: 'fal fa-share-alt',
        label: t('templateBuilder.widgets.shareSocialMedia'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.CODE,
        iconClass: 'fal fa-code',
        label: t('templateBuilder.widgets.code'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.BUTTON,
        iconClass: 'fal fa-rectangle-wide', // alternative icon
        label: t('templateBuilder.widgets.button'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.SEPARATOR,
        iconClass: 'fal fa-horizontal-rule',
        label: t('templateBuilder.widgets.separator'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.SMART_PRODUCT_LIST,
        iconClass: 'fal fa-cart-plus',
        label: t('templateBuilder.widgets.smartProductList'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.VOUCHER,
        iconClass: 'fal fa-tags',
        label: t('templateBuilder.widgets.voucher'),
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.CREATE_ACCOUNT,
        iconClass: 'fal fa-user-circle',
        label: t('templateBuilder.widgets.createCustomerAccount'),
        hide: formExistsInTemplate,
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.NEWSLETTER_SUBSCRIPTION,
        iconClass: 'fal fa-user-plus',
        label: t('templateBuilder.widgets.newsletterSubscription'),
        hide: formExistsInTemplate,
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.NEWSLETTER_UNSUBSCRIPTION,
        iconClass: 'fal fa-user-times',
        label: t('templateBuilder.widgets.newsletterUnsubscription'),
        hide: formExistsInTemplate,
        group: TemplateStructureEnum.WIDGET,
      },
      {
        type: WidgetTypeEnum.IMPORT_ZIP,
        iconClass: 'fal fa-file-arrow-up',
        label: t('templateBuilder.widgets.importZip'),
        group: TemplateStructureEnum.WIDGET,
      },
    ];
    const allStructureWidgetsMap: Array<WidgetButton> = [
      {
        type: WidgetTypeEnum.COLUMN,
        iconClass: 'fal fa-columns',
        label: t('templateBuilder.widgets.column'),
        group: TemplateStructureEnum.LINE,
      },
      {
        type: WidgetTypeEnum.SECTION,
        iconClass: 'fal fa-pager',
        label: t('templateBuilder.widgets.section'),
        hide: () => isDisplayTemplate(state.template?.type),
        group: TemplateStructureEnum.SECTION,
      },
    ];

    const templateType = ref(state.template?.type);
    const templateParentType: ComputedRef<string> = computed(() => getTemplateParentTypeByType(templateType.value));
    const isEmbed = computed(() => templateType.value === TemplateTypeEnum.EMBED);
    const showStructureWidgets = computed(() => isDisplayTemplate(templateType.value) || isEmailTemplate(templateType.value));

    const additionalWidgetsConfiguration: Ref<Record<string, any>> = ref({});
    const widgetStructureMap = computed(() => getWidgetStructures(t, additionalWidgetsConfiguration.value)[templateParentType.value]);

    const availableWidgetsButtonsMap: ComputedRef<Array<WidgetButton>> = computed(() => allWidgetsMap.filter(
      (button) => Object.keys(widgetStructureMap.value).indexOf(button.type) >= 0 && (!Object.getOwnPropertyDescriptor(button, 'hide') || (button.hide && !button.hide())),
    ));
    const availableStructureWidgetsButtonsMap: ComputedRef<Array<WidgetButton>> = computed(() => allStructureWidgetsMap.filter(
      (button) => Object.keys(widgetStructureMap.value).indexOf(button.type) >= 0 && (!Object.getOwnPropertyDescriptor(button, 'hide') || (button.hide && !button.hide())),
    ));

    onBeforeMount(async () => {
      // Get active social networks to build widgets HTML
      const activeNetworks = await GetShopsConfigurationList({
        shopId: UserState.activeShop?.id ?? 0,
        key: 'shopRequiredDataSocialNetworks_social_network',
        fields: 'value',
        limit: 0,
        offset: 0,
        lang: '',
      });

      if (activeNetworks && activeNetworks.length > 0) {
        const [networks] = activeNetworks;
        const value = unserialize(networks.value);

        if (value.length > 0 && value.indexOf('contactPage') !== -1) {
          // Replace contactPage by email
          value.push('email');
          value.splice(value.indexOf('contactPage'), 1);
        }

        additionalWidgetsConfiguration.value.socialNetworks = value;
      }
    });

    onMounted(() => {
      const panel = document.querySelector('#widgets-panel-1') as HTMLElement;

      if (panel) {
        Sortable.create(panel, {
          group: { name: TemplateStructureEnum.WIDGET, pull: 'clone', put: false },
          sort: false,
          animation: ANIMATION_SPEED,
          onChoose(evt) {
            const featurePermission = hasAccessToFeatures(evt.item.getAttribute('data-widget-type'));
            if (featurePermission && !featurePermission.access) {
              store.commit('general/setIsFeatureUnavailableModalVisible', true);
              store.commit('general/setFeatureUnavailableMinPlan', featurePermission.minimumPlan);
              return false;
            }
            return true;
          },
          onMove(evt) {
            const template = getTemplateIframeDocument();
            Array.from(template.querySelectorAll(`.${BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS}`) ?? [])
              .forEach((element) => element.classList.remove(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS));

            const target = evt.related;
            if (target.classList.contains(WIDGETS_PLACEHOLDER_IDENTIFIER)) {
              const parent = target.parentElement;
              if (parent) {
                parent.classList.add(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS);
              }
            }
          },
          onUnchoose() {
            const template = getTemplateIframeDocument();
            Array.from(template.querySelectorAll(`.${BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS}`) ?? [])
              .forEach((element) => element.classList.remove(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS));
          },
          onEnd(evt) {
            const template = getTemplateIframeDocument();

            if (evt.from !== evt.to) {
              checkIfSavePointNeeded().then(() => {
                // Lock click on widgets and sections before listeners are applied
                // eslint-disable-next-line no-unused-expressions
                template.querySelector('body')?.classList.add('iframe-locked');

                // Remove hover class
                Array.from(template.querySelectorAll(`.${BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS}`) ?? [])
                  .forEach((element) => element.classList.remove(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS));

                const destinationColumn = evt.to;

                // If destination column contains placeholder class, we remove it
                if (destinationColumn.classList.contains(COLUMN_WITH_PLACEHOLDER_CLASS)) {
                  // Remove class and placeholder
                  destinationColumn.classList.remove(COLUMN_WITH_PLACEHOLDER_CLASS);

                  // If destination column contains a placeholder for widget, we remove it
                  if (destinationColumn.querySelector(`.${WIDGETS_PLACEHOLDER_IDENTIFIER}`)) {
                    // eslint-disable-next-line no-unused-expressions
                    destinationColumn.querySelector(`.${WIDGETS_PLACEHOLDER_IDENTIFIER}`)?.remove();
                  }
                }

                // We get the HTML code of the selected widget
                const widgetType = evt.item.getAttribute('data-widget-type');

                if (widgetType) {
                  // Mark section of parent column as active
                  const column = getParents(evt.item, WIDGET_DROPPABLE_CLASS);

                  if (column) {
                    setActiveSection(computeActiveSection(TemplateStructureEnum.COLUMN, column[0].id));

                    const elementHtml = widgetStructureMap.value[widgetType]();

                    let translateVariables = true;

                    if (widgetType === WidgetTypeEnum.SMART_PRODUCT_LIST) {
                      translateVariables = false;
                    }

                    if (widgetType === WidgetTypeEnum.IMPORT_ZIP) {
                      const elementId = new RegExp('id="(spm_widget_[0-9a-zA-Z]+)"').exec(elementHtml);
                      if (elementId && elementId.length > 1) {
                        store.commit('liveEditor/setIsChooseZipFileModalVisible', true);
                        store.commit('liveEditor/setSelectedWidgetId', elementId[1]);
                        store.commit('liveEditor/setSelectedWidget', (html: string) => {
                          // eslint-disable-next-line no-param-reassign
                          evt.item.outerHTML = elementHtml.replace(WIDGET_IMPORT_ZIP_PLACEHOLDER_IDENTIFIER, html);
                        });
                      }
                    } else {
                      // Create translations for new widget
                      const { newHtml } = generateTranslations(
                        elementHtml,
                        {
                          newElement: true,
                          translateVariables,
                        },
                      );

                      // eslint-disable-next-line no-param-reassign
                      evt.item.outerHTML = newHtml as string;

                      // Unlock iframe after a few seconds in case of something went wrong
                      setTimeout(() => {
                        // eslint-disable-next-line no-unused-expressions
                        template.querySelector('body')?.classList.remove('iframe-locked');
                      }, 2000);

                      if (widgetType === WidgetTypeEnum.IMAGE) {
                        const elementId = new RegExp('id="(spm_widget_[0-9a-zA-Z]+)"').exec(elementHtml);

                        if (elementId && elementId.length > 0) {
                          // For widget image, we need to define the max-width property according to image size and parent size
                          const element = template.getElementById(elementId[1])?.querySelector('img');
                          if (element) {
                            // Set no src to remove image and calculate parent's width
                            const src = element.getAttribute('src');

                            if (src) {
                              element.setAttribute('src', '');

                              const selectorParent = isDisplayTemplate(templateType.value) ? '.spm_widget_display_image' : '.spm_widget_image';
                              const parent = getParents(element, selectorParent);

                              if (parent && parent.length) {
                                // We set the correct src of the image
                                element.setAttribute('src', src);
                                // eslint-disable-next-line no-param-reassign
                                element.onload = () => {
                                  // When image is loaded, we calculate its width
                                  const image = element as HTMLImageElement;
                                  if (image) {
                                    setPerfectSizeForImage(image, '100%');
                                  }

                                  // Update state
                                  updateSectionsInState();

                                  // Create history entry
                                  createHistory(HistoryType.ADD_WIDGET);

                                  // Reset active section
                                  resetActiveSection();

                                  // Reset all listeners on template elements
                                  resetListeners();
                                  // Cancel onload event (The onload is called again when the fixImagesWidthBeforeSave method is called)
                                  element.onload = null;
                                };
                              }
                            }
                          }
                        }
                      } else {
                        // Update state
                        updateSectionsInState();

                        // Create history entry
                        createHistory(HistoryType.ADD_WIDGET);

                        // Reset active section
                        resetActiveSection();

                        // Reset all listeners on template elements
                        resetListeners();

                        // Refresh smart list if needed
                        if (widgetType === WidgetTypeEnum.SMART_PRODUCT_LIST) {
                          refreshSmartProductList();
                        }
                      }
                    }
                  }
                }
              });
            } else {
              // eslint-disable-next-line no-unused-expressions
              template.querySelector('body')?.classList.remove('iframe-locked');
            }
          },
        });
      }

      const panel2 = document.querySelector('#line') as HTMLElement;

      if (panel2) {
        Sortable.create(panel2, {
          group: { name: TemplateStructureEnum.LINE, pull: 'clone', put: false },
          sort: false,
          animation: ANIMATION_SPEED,
          filter: `.sortable-group-${TemplateStructureEnum.SECTION}`,
          onEnd(evt) {
            if (evt.from !== evt.to) {
              showColumnWidgetModal();
            }
          },
        });
      }

      const panel3 = document.querySelector('#section') as HTMLElement;

      if (panel3) {
        Sortable.create(panel3, {
          group: { name: TemplateStructureEnum.SECTION, pull: 'clone', put: false },
          sort: false,
          animation: ANIMATION_SPEED,
          filter: `.sortable-group-${TemplateStructureEnum.LINE}`,
          onEnd(evt) {
            if (evt.from !== evt.to) {
              showSectionWidgetModal();
            }
          },
        });
      }
    });

    return {
      t,
      availableWidgetsButtonsMap,
      availableStructureWidgetsButtonsMap,
      isEmbed,
      showStructureWidgets,
      hideLeftToolbar,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}
</style>

<style lang="scss">
.widget-btn-container {
  height: 125px;
  display: inline-block;
  padding: 0.5rem;

  & > .sortable-placeholder {
    width: 100%;
    height: 100%;
  }

  & .widget-btn {
    background-color: $white;
    border: 1px solid $solitude;
    border-radius: 10px;
    color: $mid-grey;
    width: 100%;
    height: 100%;
    padding: 0.5rem;

    &:hover {
      background-color: $solitude;
      border-color: $solitude;
    }

    & > em {
      font-size: 3rem;
    }

    & > span {
      font-size: 12px;
    }
  }
}
</style>
