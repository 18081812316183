<template>
  <Dialog
    v-model:visible="dialogVisible"
    :header="header"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '70rem' }"
    :content-style="{ textAlign: 'left' }"
    :modal="true"
    @hide="updateDialogVisibility"
  >
    <Loader
      v-if="loadingImportData"
      style="z-index:1102"
    />
    <div v-if="!isDataTableVisible">
      <FileUpload
        accept=".zip"
        :max-file-size="70000000"
        :auto="true"
        :multiple="false"
        :custom-upload="true"
        @select="(event) => onSelectedFiles(event)"
        @uploader="(event) => onTemplatedUpload(event)"
      >
        <template #header="{ chooseCallback }">
          <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
            <div class="flex gap-2">
              <SpmButton
                :label="t('myLists.importContacts.files.uploadFile')"
                class="p-button-secondary"
                icon="fad fa-cloud-upload"
                @click="chooseCallback"
              />
              <SpmButton
                v-if="files"
                :label="t('myLists.importContacts.files.deleteFile')"
                class="p-button-danger"
                icon="fad fa-times"
                @click="onRemoveTemplatingFile"
              />
            </div>
          </div>
        </template>
        <template #content>
          <div
            v-if="files"
            class="flex align-items-center justify-content-center flex-column"
          >
            <div
              class="flex flex-wrap"
              style="width: 100%;"
            >
              <div
                class="flex flex-column field-wrapper"
                style="width: 100%;"
              >
                <div class="block mb-2">
                  <span class="font-semibold">File name : {{ files.name }} </span>
                  <div>
                    {{ formatFileSize(files.size) }} <Badge
                      value="Completed"
                      class="mt-3"
                      severity="success"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex align-items-center justify-content-center flex-column">
              <i class="fad fa-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
              <p class="mt-4 mb-0">
                {{ t('myLists.importContacts.files.emptyFile') }}
              </p>
            </div>
          </div>
        </template>
      </FileUpload>
    </div>
    <div v-else>
      <SpmTable
        ref="componentRef"
        v-model="tableData"
        :id-shop="idShop"
        :table-columns="importType === 'template'? templatesOnlyColumns: columns"
        :paginator="false"
        :custom-selector="true"
        :reload-data-table="false"
        index="uid"
        name="import-data-list"
        grouped-actions-key="uid"
        @row-selection-complete="setRowsSelected"
      />
    </div>
    <template #footer>
      <div class="flex justify-content-between">
        <div class="flex flex-row">
          <SpmButton
            v-if="isDataTableVisible"
            class="p-button p-button-secondary"
            :label="t('previous')"
            icon="fa fa-arrow-left"
            @click="updateDataTableVisibility(false)"
          />
        </div>
        <div class="flex flex-row-reverse flex-wrap">
          <SpmButton
            v-if="files && !isDataTableVisible"
            :label="t('continue')"
            icon="far fa-lock"
            type="button"
            :loading="loadingViewData"
            @click="proceedToViewData"
          />
          <SpmButton
            v-if="isDataTableVisible"
            :label="t('import.confirm')"
            icon="fa-solid fa-upload"
            type="button"
            :loading="loadingImportData"
            @click="processImport(importType)"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, Ref, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Dialog from 'primevue/dialog';
import SpmTable from '@/components/table/SpmTable.vue';
import FileUpload from 'primevue/fileupload';
import Badge from 'primevue/badge';
import { useToast } from 'primevue/usetoast';
import { SpmTableColumns, SpmTableState } from '@/types';
import {
  ExportResult,
  isTemplateDefinition,
  isUsersPagesConfigurationDefinition,
} from '@/types/product-import-types';
import Loader from '@/components/layout/Loader.vue';
// import { UserState } from '@/composables/User';
import { nestPost } from '@/composables/nestApi';
import { formatFileSize } from '@/helpers/Files';

export default defineComponent({
  name: 'ImportModal',

  components: {
    SpmButton,
    SpmTable,
    FileUpload,
    Dialog,
    Badge,
    Loader,
  },

  props: {
    header: {
      type: String,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },

    importType: {
      type: String,
      required: true,
    },

    idUser: {
      type: Number,
      required: false,
    },

    userType: {
      type: String,
      required: false,
    },
  },

  emits: [
    'close-action-modal',
  ],

  setup(props, context: SetupContext) {
    const { t, locale } = useI18n();
    const dialogVisible = ref<boolean>(props.showModal);
    const toast = useToast();
    const files: Ref<File | undefined> = ref();
    const fileStr = ref('');
    const tableData = ref<SpmTableState>();
    const isDataTableVisible = ref(false);
    const componentRef = ref();
    const selectedRowIds = ref();
    const loadingViewData = ref(false);
    const loadingImportData = ref(false);
    // const userId = UserState.user.id;
    // const { userType } = UserState.user;

    const templatesOnlyColumns: SpmTableColumns[] = [
      {
        field: 'id',
        header: '',
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        hidden: true,
      },
      {
        field: 'name',
        header: t('templates.import.columns.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:50%',
        type: 'text',
      },
      {
        field: 'type',
        header: t('templates.import.columns.type'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:20%',
        type: 'text',
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'uid',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'display: none',
        type: 'text',
      },
      {
        field: 'name',
        header: props.importType === 'campaign' ? t('campaigns.bulk.list.columns.name') : t('automatedScenarios.nom_scenario'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:90%',
        type: 'text',
      },
    ];

    const updateDialogVisibility = (needReload = false) => {
      dialogVisible.value = false;
      context.emit('close-action-modal', needReload);
    };

    const updateDataTableVisibility = async (visible: boolean) => {
      isDataTableVisible.value = visible;
    };

    const onSelectedFiles = (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: t('success'),
          detail: t('myLists.importContacts.errors.maxSizeError', ['700000000']),
          life: 3000,
        });
      }
    };

    const onTemplatedUpload = async (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: 'Upload error',
          life: 3000,
        });
      }

      // eslint-disable-next-line prefer-destructuring
      files.value = event.files[0];
    };

    const lookupExportFile = async () => {
      loadingViewData.value = true;
      const lookupExportState: { result: null | ExportResult; error: any } = {
        result: null,
        error: 'Aucun fichier renseigné.',
      };
      if (files.value) {
        const path = `/data/${props.importType}/lookup-export-file`;
        const formData = new FormData();
        formData.append('exportFile', files.value);
        try {
          const { result, error } = await nestPost(
            'data',
            path,
            {
              'Content-Type': 'multipart/form-data',
            },
            formData,
          );
          lookupExportState.result = result;
          lookupExportState.error = error;
        } catch (error: any) {
          lookupExportState.error = error;
        } finally {
          loadingViewData.value = false;
        }
      }
      return lookupExportState;
    };

    const proceedToViewData = async () => {
      const { result, error } = await lookupExportFile();

      if (error) {
        toast.add({
          severity: 'error',
          summary: t('error'),
          detail: t(`import.${error.message}`),
          life: 3000,
        });
      } else if (result && !error) {
        const items = result.elements.map(
          (element) => {
            const { definition } = element;
            if (isTemplateDefinition(definition)) {
              definition.name = definition.label;
            }
            if (isUsersPagesConfigurationDefinition(definition)) {
              try {
                const pageName = JSON.parse(definition.page_name);
                const pageNameValues = Object.values(pageName);
                const pageNameInLocale = pageName[locale.value];

                definition.name = pageNameInLocale || pageNameValues[0] || '';
              } catch (_: any) {
                definition.name = definition.uid;
              }
            }
            return definition;
          },
        );
        tableData.value = {
          items,
          total: items.length,
          error: '',
          selectedItems: items.map(({ uid }) => uid),
        };

        isDataTableVisible.value = true;
      }
    };

    const onRemoveTemplatingFile = () => {
      files.value = undefined;
      fileStr.value = '';
    };

    const processImport = async () => {
      loadingImportData.value = true;
      componentRef.value.sendSelectedRows();
      if (selectedRowIds.value && Array.isArray(selectedRowIds.value) && selectedRowIds.value.length !== 0 && files.value) {
        const path = `/data/${props.idShop}/${props.importType}/import`;
        const formData = new FormData();
        formData.append('exportFile', files.value);
        formData.set('uids', JSON.stringify(selectedRowIds.value));
        if (props.idUser) {
          formData.set('idUser', props.idUser.toString());
        }
        if (props.userType) {
          formData.set('userType', props.userType);
        }
        const { result, error } = await nestPost(
          'data',
          path,
          {
            'Content-Type': 'multipart/form-data',
          },
          formData,
        );
        if (result && !error) {
          toast.add({
            severity: 'success',
            summary: t('success'),
            detail: t('import.success'),
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: t('error'),
            detail: t('import.error'),
            life: 3000,
          });
        }

        updateDialogVisibility(true);
      } else {
        toast.add({
          severity: 'warn',
          summary: t('error'),
          detail: t('import.data.noSelection'),
          life: 3000,
        });
      }
      loadingImportData.value = false;
    };

    const setRowsSelected = (selectedIds: number[]) => {
      selectedRowIds.value = selectedIds;
    };

    return {
      t,
      dialogVisible,
      updateDialogVisibility,
      onSelectedFiles,
      onRemoveTemplatingFile,
      onTemplatedUpload,
      formatFileSize,
      files,
      tableData,
      columns,
      updateDataTableVisibility,
      isDataTableVisible,
      processImport,
      componentRef,
      setRowsSelected,
      templatesOnlyColumns,
      proceedToViewData,
      loadingViewData,
      loadingImportData,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
