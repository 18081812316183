import { nestPost } from '@/composables/nestApi';

/* eslint-disable import/prefer-default-export */
export const registerExportRequest = async (
  idsTobeExported: number[],
  idShop: number,
  idUser: number,
  userType: string,
  type: string,
) => nestPost('v4', '/data/export-request', {}, {
  idsTobeExported,
  idShop,
  type,
  idUser,
  userType,
});
